import { library } from "@fortawesome/fontawesome-svg-core"
import {
    faArrowDown,
    faBars,
    faBell,
    faBook,
    faBookOpen,
    faBorderAll,
    faBullseye,
    faCaretDown,
    faCaretUp,
    faChartLine,
    faChartPie,
    faCheck,
    faChevronCircleRight,
    faChevronDown,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faCropAlt,
    faCrow,
    faDotCircle as faDotCircleSolid,
    faDrawPolygon,
    faEdit,
    faEject,
    faEllipsisH,
    faEnvelope,
    faEraser,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFileDownload,
    faFileUpload,
    faFilter,
    faFish,
    faFlask,
    faFont,
    faGlobe,
    faGlobeAmericas,
    faHardHat,
    faHeading,
    faHome,
    faImage,
    faInfo,
    faInfoCircle,
    faItalic,
    faLink,
    faLock,
    faMapMarkedAlt,
    faMicrochip,
    faMinus,
    faPager,
    faParagraph,
    faPencilAlt,
    faPlay,
    faPlayCircle,
    faPlusCircle,
    faPlusSquare,
    faQuestionCircle as faQuestionCircleSolid,
    faRandom,
    faSave,
    faSearch,
    faSearchLocation,
    faSignal,
    faSignOutAlt,
    faSlash,
    faSlidersH,
    faSort,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSortNumericDown,
    faSortNumericDownAlt,
    faStar,
    faSync,
    faTable,
    faTimes,
    faTint,
    faTools,
    faTrash,
    faUpload,
    faUser,
    faUserEdit,
    faUserSlash,
    faWrench,
    faVial,
} from "@fortawesome/free-solid-svg-icons"

import {
    faCircle,
    faCopy,
    faDotCircle,
    faQuestionCircle,
    faWindowRestore,
} from "@fortawesome/free-regular-svg-icons"

library.add(
    faArrowDown,
    faBars,
    faBell,
    faBorderAll,
    faBook,
    faBullseye,
    faCaretDown,
    faCaretUp,
    faChartLine,
    faChartPie,
    faCheck,
    faChevronCircleRight,
    faChevronDown,
    faCircle,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faCopy,
    faCrow,
    faDotCircleSolid,
    faDotCircle,
    faDrawPolygon,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faEraser,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFileDownload,
    faFileUpload,
    faFish,
    faFilter,
    faFont,
    faGlobe,
    faGlobeAmericas,
    faHeading,
    faHome,
    faImage,
    faInfo,
    faInfoCircle,
    faItalic,
    faLink,
    faLock,
    faMapMarkedAlt,
    faMinus,
    faParagraph,
    faPlay,
    faPlayCircle,
    faPlusCircle,
    faPlusSquare,
    faSave,
    faSignOutAlt,
    faSlash,
    faSlidersH,
    faSync,
    faSignal,
    faSort,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSortNumericDown,
    faSortNumericDownAlt,
    faStar,
    faTimes,
    faTint,
    faTools,
    faHardHat,
    faUpload,
    faPencilAlt,
    faBookOpen,
    faFlask,
    faWrench,
    faQuestionCircle,
    faQuestionCircleSolid,
    faRandom,
    faExclamationTriangle,
    faEject,
    faSearchLocation,
    faGlobeAmericas,
    faSearch,
    faCropAlt,
    faMicrochip,
    faPager,
    faWindowRestore,
    faTable,
    faTrash,
    faUser,
    faUserEdit,
    faUserSlash,
    faVial
)
