// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import ClearIcon from "@material-ui/icons/Clear"
import Fab from "@material-ui/core/Fab"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Switch from "@material-ui/core/Switch"
import { SingleSelect } from "react-select-material-ui"
import VectorSource from "ol/source/Vector"
import VectorLayer from "ol/layer/Vector"
import OlMap from "ol/Map"
import Draw from "ol/interaction/Draw"
import OlGeoJSON from "ol/format/GeoJSON"
import buffer from "@turf/buffer"
import Theme from "@owsi/catena/er2_styles"
import ErrorBoundary from "@owsi/catena/er2_map_userlayers/js/components/error_boundary"
import SymbolProperties from "@owsi/catena/er2_map_userlayers/js/components/symbol_properties"
import {
    getLayerIcon,
    getLayerType,
    getVectorLayers,
    nullFunc,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import type { ActionsType } from "@owsi/catena/er2_map_userlayers/js/reducers"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import type { MapType } from "@owsi/catena/er2_map_userlayers/js/reducers/map"
import type { TokenType } from "../../../js/reducers/token"

const ATTRIBUTE = "ATTRIBUTE"
const CONJUNCTION = "CONJUNCTION"
const OPERATOR = "OPERATOR"
const VALUE = "VALUE"

type PropsType = {
    actions: ActionsType,
    map: MapType,
    mapsources: MapsourcesType,
    olMap: OlMap,
    theme: Theme,
    token: TokenType,
}

const defaultSelectionSymbol = {
    color: "#ffff00",
    expression: null,
    label: "SELECTION",
    patternShape: "Point",
    size: 4,
    symbolType: "Solid",
    symbol: "Solid.png",
}

const initialQuery = {
    expression: [],
    symbol: defaultSelectionSymbol,
    visible: true,
}

function QueryBuilder(props: PropsType) {
    const [attributeSelect, setAttributeSelect] = React.useState("")
    const [bufferRadius, setBufferRadius] = React.useState(0)
    const [bufferType, setBufferType] = React.useState("")
    const [fields, setFields] = React.useState([])
    const [layerName, setLayerName] = React.useState("")
    const [layerDistance, setLayerDistance] = React.useState("")
    const [olFeature, setOlFeature] = React.useState()
    const [query, setQuery] = React.useState(initialQuery)
    const [queryTab, setQueryTab] = React.useState(0)
    const [relationshipLayer, setRelationshipLayer] = React.useState("")
    const [relationshipType, setRelationshipType] = React.useState("")
    const [rows, setRows] = React.useState()
    const [sampleValues, setSampleValues] = React.useState([])
    const [schema, setSchema] = React.useState()
    const [showSymbolProperties, setShowSymbolProperties] = React.useState(
        false,
    )
    const [spatialLocationType, setSpatialLocationType] = React.useState("")
    const [valueSelect, setValueSelect] = React.useState("")

    const containerRef = React.useRef()

    const layers = getVectorLayers(props.mapsources)
    const layer = layers.find((l) => l.name === layerName)

    // Get attribute associated with current clause
    function getAttribute(newQuery) {
        const qu = newQuery || query
        // Look backwards from end for the step of type attribute
        const step = qu.expression
            .slice()
            .reverse()
            .find((q) => q.type === ATTRIBUTE)
        if (step) {
            return step.value
        }
        return ""
    }

    function getExpected() {
        let expected = ATTRIBUTE
        const { expression } = query
        const lastStep = expression[expression.length - 1] || {}
        const current = lastStep.type
        if (current === ATTRIBUTE) {
            expected = OPERATOR
        } else if (current === OPERATOR) {
            expected = VALUE
        } else if (current === VALUE) {
            expected = CONJUNCTION
        } else if (current === CONJUNCTION) {
            expected = ATTRIBUTE
        }
        return expected
    }

    function getQueryString() {
        let ret = ""
        query.expression.forEach((q) => (ret += `${q.value} `))
        return ret
    }

    const handleAttributeSelect = (attribute) => {
        const expected = getExpected()
        if (expected === ATTRIBUTE) {
            const newStep = {
                type: ATTRIBUTE,
                value: attribute,
            }
            setQuery({ ...query, expression: [...query.expression, newStep] })
        }
        setAttributeSelect(attribute)
    }

    const handleAddAttribute = () => {
        handleAttributeSelect(attributeSelect)
    }

    const handleAddOperator = (operator) => () => {
        const expected = getExpected()
        if (expected === OPERATOR) {
            const newStep = {
                type: OPERATOR,
                value: operator,
            }
            setQuery({ ...query, expression: [...query.expression, newStep] })
        }
    }

    const handleAddConjunction = (conjunction) => () => {
        const expected = getExpected()
        if (expected === CONJUNCTION) {
            const newStep = {
                type: CONJUNCTION,
                value: conjunction,
            }
            setQuery({ ...query, expression: [...query.expression, newStep] })
        }
    }

    const handleAddValue = (v) => {
        let addValue = v || valueSelect
        setValueSelect(addValue)

        const expected = getExpected()
        const attribute = getAttribute()

        if (expected === VALUE) {
            if (schema[attribute] === "str") {
                addValue = `"${addValue}"`
            }

            const newStep = {
                type: VALUE,
                value: addValue,
            }
            setQuery({ ...query, expression: [...query.expression, newStep] })
        }
    }

    const handleBufferType = (e) => {
        setBufferType(e.target.value)
    }

    const handleEditSelectionSymbol = () => {
        setShowSymbolProperties(!showSymbolProperties)
    }

    const handleUpdateSelectionSymbol = (sym) => {
        // Only the updated members are returned, so it needs to be merged with the current symbol.
        const updatedQuery = { ...query, symbol: { ...query.symbol, ...sym } }
        setQuery(updatedQuery)
    }

    const handleCloseSelectionSymbol = () => {
        setShowSymbolProperties(false)
    }

    const handleChangeVisibility = () => {
        setQuery({ ...query, visible: !query.visible })
    }

    const handleChangeQueryMode = () => {
        setQuery({ ...query, masked: !query.masked })
    }

    const handleChangeBufferRadius = (e) => {
        setBufferRadius(e.target.value)
    }

    const handleSpatialLocationType = (e) => {
        const spType = e.target.value
        setSpatialLocationType(spType)
        setQuery({ ...query, spatialLocationType: spType })
    }

    const handleChangeRelationshipType = (e) => {
        const relType = e.target.value
        setRelationshipType(relType)
        setQuery({ ...query, relationshipType: relType })
    }

    const handleChangeRelationshipLayer = (e) => {
        const relLayer = e.target.value
        setRelationshipLayer(relLayer)
        setQuery({ ...query, relationshipLayer: relLayer })
    }

    const handleChangeLayerDistance = (e) => {
        const dist = e.target.value
        setLayerDistance(dist)
        setQuery({ ...query, relationshipLayerDistance: dist })
    }

    const rollbackQuery = () => {
        setQuery({
            ...query,
            expression: [
                ...query.expression.slice(0, query.expression.length - 1),
            ],
        })
    }

    const getLayerList = () =>
        layers.map((l) => (
            <MenuItem key={l.name} value={l.name}>
                {getLayerIcon(l)}&nbsp;{l.name}
            </MenuItem>
        ))

    const runQuery = () => {
        props.actions.userLayerSelectFeatures(layer, query)
    }

    const zoomToLayer = () => {
        props.actions.onUserLayersZoom([layer])
    }

    function renderSelectionSymbol() {
        if (layer && containerRef.current) {
            const sym = query.symbol
            const bbox = containerRef.current.getBoundingClientRect()
            // Decide whether to pop the symbol table open to the left or right.
            let pos = {
                left: bbox.x + bbox.width,
            }
            const openLeft = bbox.left > window.innerWidth / 2
            if (openLeft) {
                pos = {
                    right: bbox.width + 12,
                }
            }

            const { color, symbol, symbolType, transparent } = sym
            const patternShape = getLayerType(layer.layer_type).toLowerCase()
            const url = `${props.map.icons.url}${patternShape}/${
                symbolType || "Solid"
            }/${symbol || "Solid.png"}`
            const colorStyle = {}
            // If the symbol is not a point, then it will not have an icon.
            if (
                !symbolType ||
                symbolType === "Solid" ||
                patternShape !== "point"
            ) {
                colorStyle.backgroundColor =
                    transparent === true ? "initial" : color
            }
            return (
                <ErrorBoundary>
                    <Button
                        onClick={handleEditSelectionSymbol}
                        size={"small"}
                        variant={"outlined"}
                        style={{
                            visibility: query.masked ? "hidden" : "visible",
                        }}
                    >
                        <img src={url} alt="sym" style={colorStyle} />
                    </Button>
                    {showSymbolProperties && (
                        <SymbolProperties
                            layer_type={layer.layer_type}
                            onUpdate={handleUpdateSelectionSymbol}
                            onClose={handleCloseSelectionSymbol}
                            pos={pos}
                            symbol={sym}
                            theme={props.theme}
                        />
                    )}
                </ErrorBoundary>
            )
        }
        return null
    }

    function renderQueryTabs() {
        const changeViewTab = (event) => {
            setQueryTab(event.target.textContent === "Spatial" ? 1 : 0)
        }
        return (
            <div
                className={props.theme.toolRow}
                style={{ textAlign: "center" }}
            >
                <ButtonGroup color="primary" size="small">
                    <Button
                        onClick={changeViewTab}
                        variant={queryTab === 0 ? "contained" : "outlined"}
                    >
                        Layer Attributes
                    </Button>
                    <Button
                        onClick={changeViewTab}
                        variant={queryTab === 1 ? "contained" : "outlined"}
                    >
                        Spatial
                    </Button>
                </ButtonGroup>
            </div>
        )
    }

    const relationshipTypes = [
        "Intersects",
        "Within a Distance of",
        "Contains",
        "Completely Contains",
        "Within",
        "Completely Within",
        "Identical to",
        "Touches the Boundary of",
        "Shares a Line Segment With",
        "Crossed By",
    ]

    const operators = [
        { iconButton: true, op: "=" },
        { iconButton: true, op: "<>" },
        { iconButton: true, op: ">" },
        { iconButton: true, op: "<" },
        { iconButton: true, op: "=>" },
        { iconButton: true, op: "<=" },
        { iconButton: false, op: "IS NULL" },
        { iconButton: false, op: "IS NOT NULL" },
        { iconButton: false, op: "LIKE" },
    ]

    const relationshipTypeList = relationshipTypes.map((t) => (
        <MenuItem key={t.toLowerCase()} value={t.toLowerCase()}>
            {t}
        </MenuItem>
    ))

    React.useEffect(() => {
        if (layer) {
            let hasError = false
            fetch(`/er2_map/get_layer_attributes/?token=${props.token.value}`, {
                body: JSON.stringify({ layer: layer.name }),
                headers: new Headers({'content-type': 'application/json'}),
                method: "POST",
            })
                .then((res) => {
                    hasError = res.status !== 200
                    if (hasError) return res.text()
                    return res.json()
                })
                .then((json) => {
                    if (hasError) {
                        props.actions.appendToLogger([
                            `Got failure to create layer: ${json}`,
                        ])
                    } else {
                        const attrRows = json.attributes.map(
                            (a) => a.properties,
                        )
                        setRows(attrRows)
                        if (attrRows.length) {
                            setFields(Object.keys(attrRows[0]))
                        }
                        setSchema(json.schema.properties)
                        if (!Array.isArray(layer.query)) {
                            // Add missing attributes due to testing. # TODO: remove when API is established.
                            const updatedQuery = {
                                ...initialQuery,
                                ...layer.query,
                            }
                            updatedQuery.symbol = {
                                ...defaultSelectionSymbol,
                                ...layer.query.symbol,
                            }
                            setQuery(updatedQuery)
                            setAttributeSelect(getAttribute(updatedQuery))
                            setSpatialLocationType(
                                updatedQuery.spatialLocationType,
                            )
                            setRelationshipType(updatedQuery.relationshipType)
                            setRelationshipLayer(updatedQuery.relationshipLayer)
                        }
                    }
                })
        }
    }, [layer])

    React.useEffect(() => {
        let valList = []
        if (rows && rows.length) {
            valList = rows
                ? rows
                      .map((row) => row[attributeSelect])
                      .filter(
                          (val, i, array) => val && array.indexOf(val) === i,
                      )
                : []
        }
        setSampleValues(valList.sort().map((v) => ({ label: v, value: v })))
    }, [rows, attributeSelect])

    React.useEffect(() => {
        if (spatialLocationType === "selection") {
            const vs = new VectorSource()
            if (olFeature) {
                vs.addFeature(olFeature)
            }
            const vl = new VectorLayer({
                source: vs,
                zIndex: 100,
            })
            props.olMap.addLayer(vl)
            let draw
            if (bufferType) {
                const opts = {
                    source: vs,
                    type: bufferType,
                }
                draw = new Draw(opts)
                draw.on("drawend", (e) => {
                    let feat = e.feature
                    const geojson = new OlGeoJSON()
                    let spatialFeature = geojson.writeFeatureObject(feat, {
                        featureProjection: "EPSG:3857",
                        dataProjection: "EPSG:4326",
                    })

                    const hasBuffer = ["Point", "LineString"]
                    if (hasBuffer.includes(bufferType) && bufferRadius) {
                        const radius = parseFloat(bufferRadius)
                        if (radius) {
                            spatialFeature = buffer(
                                spatialFeature,
                                bufferRadius,
                                { units: "miles" },
                            )
                            feat = geojson.readFeatureFromObject(
                                spatialFeature,
                                {
                                    featureProjection: "EPSG:3857",
                                    dataProjection: "EPSG:4326",
                                },
                            )
                            // Store the last buffer value in the feature itself so we know if it needs to be updated
                            feat.buffer = bufferRadius
                        }
                    }
                    setOlFeature(feat)
                    setQuery({ ...query, spatialFeature })
                })
                props.olMap.addInteraction(draw)
            }
            return () => {
                if (draw) {
                    props.olMap.removeInteraction(draw)
                }
                props.olMap.removeLayer(vl)
            }
        }
        return nullFunc
    }, [
        bufferRadius,
        bufferType,
        olFeature,
        props.olMap,
        query,
        spatialLocationType,
    ])

    React.useEffect(() => {
        if (spatialLocationType === "selection" && bufferRadius && olFeature) {
            if (olFeature.buffer !== bufferRadius && bufferRadius !== 0) {
                const radius = parseFloat(bufferRadius)
                if (radius) {
                    const newBuffer = bufferRadius - olFeature.buffer
                    const geojson = new OlGeoJSON()
                    let spatialFeature = geojson.writeFeatureObject(olFeature, {
                        featureProjection: "EPSG:3857",
                        dataProjection: "EPSG:4326",
                    })

                    spatialFeature = buffer(spatialFeature, newBuffer, {
                        units: "miles",
                    })
                    const feat = geojson.readFeatureFromObject(spatialFeature, {
                        featureProjection: "EPSG:3857",
                        dataProjection: "EPSG:4326",
                    })
                    feat.buffer = bufferRadius
                    setOlFeature(feat)
                    setQuery({ ...query, spatialFeature })
                }
            }
        }
    }, [bufferRadius, olFeature, query, spatialLocationType])

    const expected = getExpected()

    return (
        <div ref={containerRef}>
            <div className={props.theme.toolRow}>
                <FormControl className={props.theme.toolSelectWithButton}>
                    <InputLabel htmlFor="layer">Layer</InputLabel>
                    <Select
                        onChange={(e) => setLayerName(e.target.value)}
                        inputProps={{
                            id: "layer",
                        }}
                        value={layerName}
                    >
                        {getLayerList()}
                    </Select>
                </FormControl>
                <Fab disabled={!layer} onClick={zoomToLayer} size={"small"}>
                    <i className={"ms ms-zoom-to-extent"} />
                </Fab>
            </div>
            {renderQueryTabs()}
            {queryTab === 0 && (
                <ErrorBoundary>
                    <div
                        className={`${props.theme.toolRow} ${
                            expected === ATTRIBUTE
                                ? props.theme.available
                                : props.theme.notAvailable
                        }`}
                    >
                        <FormControl
                            className={props.theme.toolSelectWithButton}
                            required={expected === ATTRIBUTE}
                        >
                            <InputLabel htmlFor="attribute-select">
                                Query attribute
                            </InputLabel>
                            <Select
                                autoWidth
                                disabled={!layerName || expected !== ATTRIBUTE}
                                value={attributeSelect}
                                onChange={(e) =>
                                    handleAttributeSelect(e.target.value)
                                }
                                inputProps={{
                                    id: "attribute-select",
                                }}
                            >
                                {fields.map((a) => (
                                    <MenuItem key={a} value={a}>
                                        {a}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Fab
                            disabled={
                                attributeSelect === "" || expected !== ATTRIBUTE
                            }
                            onClick={handleAddAttribute}
                            size={"small"}
                        >
                            <i className={"fas fa-plus"} />
                        </Fab>
                    </div>
                    <div
                        className={`${props.theme.toolRow} ${
                            expected === OPERATOR
                                ? props.theme.available
                                : props.theme.notAvailable
                        }`}
                    >
                        <div>
                            Operators:
                            <div>
                                {operators.map((o) =>
                                    o.iconButton ? (
                                        <Fab
                                            className={props.theme.toolButton}
                                            disabled={expected !== OPERATOR}
                                            key={o.op}
                                            onClick={handleAddOperator(o.op)}
                                            size={"small"}
                                            value={o.op}
                                        >
                                            {o.op}
                                        </Fab>
                                    ) : (
                                        <Button
                                            className={props.theme.toolButton}
                                            disabled={expected !== OPERATOR}
                                            key={o.op}
                                            onClick={handleAddOperator(o.op)}
                                            size={"small"}
                                            value={o.op}
                                            variant={"contained"}
                                        >
                                            {o.op}
                                        </Button>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${props.theme.toolRow} ${
                            expected === VALUE
                                ? props.theme.available
                                : props.theme.notAvailable
                        }`}
                    >
                        <FormControl
                            className={props.theme.toolSelectWithButton}
                            required={expected === VALUE}
                        >
                            {valueSelect.length === 0 && (
                                <InputLabel htmlFor="value-select">
                                    Select Value
                                </InputLabel>
                            )}
                            <SingleSelect
                                className={props.theme.toolSelectWithButton}
                                disabled={expected !== VALUE}
                                placeholder={false}
                                options={sampleValues}
                                InputLabelProps={{ id: "value-select" }}
                                SelectProps={{
                                    isCreatable: true,
                                    msgNoOptionsAvailable: "",
                                }}
                                value={valueSelect}
                                onChange={handleAddValue}
                            />
                        </FormControl>
                        <Fab
                            disabled={expected !== VALUE}
                            onClick={() => handleAddValue()}
                            size={"small"}
                        >
                            <i className={"fas fa-plus"} />
                        </Fab>
                    </div>
                    <div
                        className={`${props.theme.toolRow} ${
                            expected === CONJUNCTION
                                ? props.theme.available
                                : props.theme.notAvailable
                        }`}
                    >
                        <div>
                            Conjunctions:
                            <div>
                                <Fab
                                    className={props.theme.toolButton}
                                    disabled={expected !== CONJUNCTION}
                                    onClick={handleAddConjunction("And")}
                                    size={"small"}
                                >
                                    And
                                </Fab>
                                <Fab
                                    className={props.theme.toolButton}
                                    disabled={expected !== CONJUNCTION}
                                    onClick={handleAddConjunction("Or")}
                                    size={"small"}
                                >
                                    Or
                                </Fab>
                            </div>
                        </div>
                    </div>
                    <div className={props.theme.toolRow}>
                        <TextField
                            fullWidth
                            label="Query"
                            multiline
                            rowsMax="3"
                            value={getQueryString()}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="clear"
                                            onClick={rollbackQuery}
                                            size={"small"}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </div>
                </ErrorBoundary>
            )}
            {queryTab === 1 && (
                <ErrorBoundary>
                    <div className={props.theme.toolRow}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="sample-values">
                                Spatial Location Type
                            </InputLabel>
                            <Select
                                value={spatialLocationType}
                                onChange={handleSpatialLocationType}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="selection">
                                    By Selection
                                </MenuItem>
                                <MenuItem value="relationship">
                                    By Relationship
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {spatialLocationType === "selection" && (
                        <ErrorBoundary>
                            <div className={props.theme.toolRow}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="BufferType"
                                        name="bufferType"
                                        value={bufferType}
                                        onChange={handleBufferType}
                                    >
                                        <FormControlLabel
                                            value="Point"
                                            control={<Radio />}
                                            label="Point Buffer  "
                                        />
                                        <FormControlLabel
                                            value="LineString"
                                            control={<Radio />}
                                            label="Line Buffer  "
                                        />
                                        <FormControlLabel
                                            value="Polygon"
                                            control={<Radio />}
                                            label="Polygon  "
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {bufferType !== "Polygon" && (
                                <div className={props.theme.toolRow}>
                                    <TextField
                                        autoFocus
                                        label="Buffer Radius"
                                        value={bufferRadius}
                                        onChange={handleChangeBufferRadius}
                                        onFocus={(e) => e.target.select()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mi
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        </ErrorBoundary>
                    )}
                    {spatialLocationType === "relationship" && (
                        <ErrorBoundary>
                            <div className={props.theme.toolRow}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="relationship-types">
                                        Relationship Type
                                    </InputLabel>
                                    <Select
                                        id="relationship-types"
                                        value={relationshipType}
                                        onChange={handleChangeRelationshipType}
                                    >
                                        {relationshipTypeList}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={props.theme.toolRow}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="layers">
                                        Layer
                                    </InputLabel>
                                    <Select
                                        id="layers"
                                        value={relationshipLayer}
                                        onChange={handleChangeRelationshipLayer}
                                    >
                                        {getLayerList()}
                                    </Select>
                                </FormControl>
                            </div>
                            {relationshipType === "within a distance of" && (
                                <div className={props.theme.toolRow}>
                                    <TextField
                                        label="Distance"
                                        value={layerDistance}
                                        onChange={handleChangeLayerDistance}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    mi
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        </ErrorBoundary>
                    )}
                </ErrorBoundary>
            )}
            <div className={props.theme.toolRow}>{renderSelectionSymbol()}</div>
            <div className={props.theme.toolRow}>
                <span>
                    {query.masked
                        ? "Features outside of selection are masked"
                        : "Features show as selected"}
                </span>
                <Switch
                    checked={query.masked === true}
                    color="primary"
                    onChange={handleChangeQueryMode}
                    size={"small"}
                    title={
                        query.masked
                            ? "Click off to show features as selected"
                            : "Click on mask unselected features"
                    }
                />
            </div>
            <div className={props.theme.toolRow}>
                <span>
                    {query.visible ? "Query applied" : "Query is inactive"}
                </span>
                <Switch
                    checked={query.visible === true}
                    color="primary"
                    onChange={handleChangeVisibility}
                    size={"small"}
                    title={
                        query.visible
                            ? "Click on to apply selection"
                            : "Click off to remove selection"
                    }
                />
            </div>
            <div className={props.theme.toolRow}>
                <Button onClick={runQuery} variant={"contained"}>
                    Select
                </Button>
            </div>
        </div>
    )
}

export default QueryBuilder
