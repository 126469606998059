// @flow
import * as actionTypes from "../constants/action_types"

export type LogEntry = {
    date: string,
    log: string[],
}

export type LoggerType = {
    // A log is a list of strings.
    logs: LogEntry[],
}

type ActionType = {
    log?: string[],
    type: string,
}

const initialState = {
    logs: [],
    visible: false,
}

const createLogEntry = (action) => {
    let log = action.log
    if (!Array.isArray(log)) {
        log = [log]
    }
    return {
        date: new Date(),
        log,
    }
}

export default function logger(
    state: LoggerType = initialState,
    action: ActionType,
) {
    let ret = state
    if (action.type === actionTypes.LOG_SET) {
        ret = { ...state, logs: [createLogEntry(action)] }
    } else if (action.type === actionTypes.LOG_APPEND) {
        ret = {
            ...state,
            logs: [...state.logs, createLogEntry(action)],
            visible: true,
        }
    }

    return ret
}
