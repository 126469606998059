// @flow
export const rawStyles = {
    mapToolbarPanel: {
        alignItems: "stretch",
        backgroundColor: (props) => props.white,
        borderLeft: "1px solid grey",
        display: "flex",
        flexDirection: "column",
        gridColumnStart: "toolbarPanel",
        gridColumnEnd: "toolbar",
        gridRowStart: "subHeader",
        gridRowEnd: "bottom",
        // overflowY: "auto",  // Causing problems for MRRP; Hopefully not necessary elsewhere.  Uncomment if overrides on er2_mrrp/static/src/styles/index can be fixed. -Chris Y
        zIndex: 2,
    },
    mapToolbarPanelScroll: {
        height: "calc(100% - 44px)",
        overflowY: "auto",
    },
}

export default rawStyles
