// @flow
import React from "react"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import Chip from "@material-ui/core/Chip"
import MenuItem from "@material-ui/core/MenuItem"
import { getActiveTool, matchesSelectedGeometryType } from "../reducers/geo_bar"
import type { UserLayerType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"

type Props = {
    actions: {
        onGeoBarSelectLayer: Function,
    },
    inputLabel: string,
    layers: UserLayerType[],
    mapsources: object,
    theme: {},
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

function MultiLayerSelect(props: Props) {
    const tool = getActiveTool(props)

    const onChangeLayer = (e) => {
        e.target.value.forEach((layerName) =>
            props.actions.onGeoBarSelectLayer(true, layerName, tool),
        )
    }

    const onRemoveLayer = (layerName) => () => {
        props.actions.onGeoBarSelectLayer(false, layerName, tool)
    }

    function getStyleForLayer(layer, layers, allLayers) {
        return {
            fontWeight:
                allLayers.indexOf(layer.name) === -1 ? "bold" : "default",
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="select-multiple-layers">
                {props.inputLabel}
            </InputLabel>
            <Select
                multiple
                value={tool.layers}
                onChange={onChangeLayer}
                input={<Input id="select-multiple-layers" />}
                renderValue={(selected) => (
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        {selected.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                onDelete={onRemoveLayer(value)}
                            />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {props.layers.map((layer) => (
                    <MenuItem
                        disabled={
                            !matchesSelectedGeometryType(
                                layer,
                                tool.layers,
                                props.layers,
                            )
                        }
                        key={layer.name}
                        style={getStyleForLayer(
                            layer,
                            tool.layers,
                            props.layers,
                        )}
                        value={layer.name}
                    >
                        {layer.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MultiLayerSelect
