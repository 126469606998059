// @flow
import React from "react"
import AutoSuggest from "react-autosuggest"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"

import rawStyles from "../styles/geo_bar_search"
import type { GeoBarType } from "../reducers/geo_bar"

function renderInput(inputProps) {
    const { inputTheme, theme, ref, ...other } = inputProps

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: ref,
                classes: {
                    input: theme.input,
                },
                ...other,
            }}
        />
    )
}

function renderSuggestion(suggestion, { query, isHighlighted }, theme) {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                <div className={theme.suggestionTitle}>
                    {suggestion.icon && (
                        <i
                            className={`${theme.suggestionIcon} ${suggestion.icon}`}
                        />
                    )}
                    {!suggestion.icon && (
                        <i className={`${theme.suggestionIcon} ms ms-tool`} />
                    )}
                    <span className={theme.suggestionTitleText}>
                        {suggestion.label}
                    </span>
                </div>
                {suggestion.description && (
                    <div className={theme.suggestionDescription}>
                        {suggestion.description}
                    </div>
                )}
            </div>
        </MenuItem>
    )
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    )
}

export function getSuggestionValue(suggestion) {
    return suggestion.label
}

type Props = {
    geoBar: GeoBarType,
    inputTheme?: object,
    onChange: Function,
    onSelect: Function,
    onSuggestionsClearRequested: Function,
    onSuggestionsFetchRequested: Function,
    theme?: {
        container: string,
        suggestionsContainerOpen: string,
        suggestion: string,
        suggestionsList: string,
    },
}

/* eslint-disable react/prefer-stateless-function */
class GeoBarSearch extends React.Component<Props> {
    render() {
        const inputTheme = this.props.inputTheme || this.props.theme
        const theme = this.props.theme
        return (
            <AutoSuggest
                theme={{
                    container: rawStyles.container,
                    suggestionsContainerOpen:
                        rawStyles.suggestionsContainerOpen,
                    suggestionsList: rawStyles.suggestionsList,
                    suggestion: rawStyles.suggestion,
                }}
                alwaysRenderSuggestions
                renderInputComponent={renderInput}
                suggestions={this.props.geoBar.search.suggestions}
                onSuggestionsFetchRequested={
                    this.props.onSuggestionsFetchRequested
                }
                onSuggestionsClearRequested={
                    this.props.onSuggestionsClearRequested
                }
                onSuggestionSelected={(evt, { suggestion }) =>
                    this.props.onSelect(suggestion)
                }
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={(suggestion) =>
                    getSuggestionValue(suggestion)
                }
                renderSuggestion={(sugg, opts) =>
                    renderSuggestion(sugg, opts, theme)
                }
                inputProps={{
                    inputTheme,
                    theme: rawStyles,
                    placeholder: "Enter a keyword...",
                    value: this.props.geoBar.search.value,
                    onChange: (evt) =>
                        this.props.onChange(evt.currentTarget.value, evt),
                }}
            />
        )
    }
}

export default GeoBarSearch
