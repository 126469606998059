// @flow
import { onUpdateUserLayers } from "@owsi/catena/er2_map_userlayers/js/actions/map"
import { appendToLogger } from "@owsi/catena/er2_map_userlayers/js/actions/logger"
import type { UserLayerType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import * as types from "../constants/action_types"
import * as ids from "../constants/ids"
import { setMapMessage } from "../../../er2_map_userlayers/js/actions"

export const lampsEnd = (state) => ({ type: types.LAMPS_END, state })
export const lampsStart = () => ({ type: types.LAMPS_START })
export const showLampsResults = (layer: UserLayerType) => ({
    type: types.SHOW_SERVICE_RESULTS,
    id: ids.DATA_LAMPS,
    layer,
})
export const closeServiceResults = (id) => ({
    type: types.CLOSE_SERVICE_RESULTS,
    id,
})

export const extractDEM = (geojson, outputName, onFinished) => (
    dispatch,
    getState,
) => {
    let fetchError = false
    fetch(`/er2_map/api/v1/services/dem/?token=${getState().token.value}`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({ geojson, outputName }),
    })
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                dispatch(appendToLogger([{ exception: json.exception }]))
                dispatch(setMapMessage(json.exception.message))
            } else {
                dispatch(onUpdateUserLayers())
            }
            if (onFinished) onFinished(json)
        })
        .catch((err) => {
            dispatch(appendToLogger([{ exception: err }]))
            dispatch(setMapMessage(err))
        })
}

export const runLamps = (layer: UserLayerType) => (
    dispatch: Function,
    getState: Function,
) => {
    let delay = 3000
    let fetchError = false
    const token = getState().token.value

    dispatch(lampsStart())

    const poll = (suid) => () => {
        fetch(`/er2_map/api/v1/services/lamps/?token=${token}`, {
            body: JSON.stringify({
                layer: layer.name,
                headers: new Headers({'content-type': 'application/json'}),
                suid,
            }),
            method: "POST",
        })
            .then((res) => {
                fetchError = !res.ok
                return res.json()
            })
            .then((json) => {
                if (fetchError) {
                    dispatch(appendToLogger([{ exception: json.exception }]))
                    dispatch(lampsEnd({ exception: json.exception }))
                } else {
                    const { status, first_poll: firstPoll } = json.state
                    if (status === "Submitted") {
                        // Not finished -- wait and resubmit
                        setTimeout(poll(json.state.suid), delay)
                    } else if (status === "Running") {
                        if (firstPoll) {
                            delay = firstPoll
                        }
                        setTimeout(poll(json.state.suid), delay)
                    } else {
                        if (json.state.error) {
                            dispatch(
                                appendToLogger([
                                    "LAMPS generated an error:",
                                    json.state.res.metainfo.error,
                                ]),
                            )
                        }
                        dispatch(lampsEnd(json.state))
                        dispatch(onUpdateUserLayers())
                    }
                }
            })
            .catch((err) => {
                dispatch(lampsEnd({ exception: err }))
                dispatch(appendToLogger([{ exception: err }]))
            })
    }

    fetch(`/er2_map/api/v1/services/lamps/?token=${token}`, {
        body: JSON.stringify({
            layer: layer.name,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                dispatch(lampsEnd({ exception: json.exception }))
                dispatch(appendToLogger([{ exception: json.exception }]))
            } else if (json.state.status === "Submitted") {
                // Not finished -- wait and resubmit
                setTimeout(poll(json.state.suid), delay)
            } else {
                dispatch(lampsEnd(json.state))
                dispatch(onUpdateUserLayers())
            }
        })
        .catch((err) => {
            dispatch(lampsEnd({ exception: err.exception }))
            dispatch(appendToLogger([{ exception: err }]))
        })
}

export const fieldToWEPP = (
    feature,
    contributingArea,
    tag,
    showAll,
    onFinished,
) => (dispatch, getState) => {
    let fetchError = false
    fetch(`/er2_map/api/v1/field_to_WEPP/?token=${getState().token.value}`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            contributing_area: contributingArea,
            feature,
            tag,
            show_all: showAll,
        }),
    })
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                dispatch(appendToLogger([{ exception: json.exception }]))
            } else {
                dispatch(onUpdateUserLayers())
            }
            if (onFinished) onFinished(json)
        })
        .catch((err) => {
            dispatch(appendToLogger([{ exception: err }]))
        })
}
