import React, { useState } from "react"
import { BrowserRouter } from "react-router-dom"
import { dataCacheCtx, setDataCacheCtx } from "./ssr/ctx"
import type { IPageLoaded } from "../types"

interface IProps {
    initCache?: IPageLoaded
}

const SSRContextProvider: React.FC<IProps> = ({ initCache, children }) => {
    const [dataCache, setDataCache] = useState<IPageLoaded>(initCache ?? {})
    return (
        <BrowserRouter>
            <dataCacheCtx.Provider value={dataCache}>
                <setDataCacheCtx.Provider value={setDataCache}>
                    {children}
                </setDataCacheCtx.Provider>
            </dataCacheCtx.Provider>
        </BrowserRouter>
    )
}

export default SSRContextProvider
