import React from "react"

import slugify from "slugify"

import type { Styles } from "@material-ui/styles"
import type { Gathering } from "@owsi/catena-folio"

import {
    Theme,
    createTheme as createMuiTheme,
    responsiveFontSizes,
    makeStyles,
    CircularProgress,
    LinearProgress,
} from "@material-ui/core"

import { createSvs } from "react-hook-svs"
import type { ISvs } from "react-hook-svs/out/createSvs"

import {
    addCatenaPalette,
    addCatenaTypography,
} from "@owsi/catena/er2_styles/mui"

import { PlainMessage } from "./components/PlainMessage"
import { Layout } from "./components/Layout"
import { Head } from "./components/Head"
import { ITheme } from "../vellum-client/types"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorMessage } from "./components/ErrorMessage"
import { ContentModule, ContentError } from "./components/ContentModule"

interface IThemeBuilder {
    override(overrides: any): IThemeBuilder
    addCatenaTypography(): IThemeBuilder
    addCatenaPalette(colors?: Record<string, string>): IThemeBuilder
}

type StyleProps = any

type ThemeConfig = {
    title?: string
    browserColor?: string
    helmetProps?: object
    buildSearchIndex?: boolean
    rootSvs?: ISvs<any, object>
    appStyles?: Styles<Theme, StyleProps>
    appComponent: React.ComponentType<any>
    loadingComponent?: React.ComponentType<any>
    onCreateMuiTheme?: (builder: IThemeBuilder) => void
    onError?: ContentError
}

export type VellumPageProps = {
    id: string
    title: string
    storyKey?: string
    [field: string]: any
}

export type VellumPage<P = {}> = React.ComponentType<VellumPageProps & P>

const THEME_NAME = "StandardVellumTheme"

/**
 * Global Stylesheet context
 */
const DefaultRootSvs = createSvs((scope, staticData: any) => ({}))

export function createTheme(config: ThemeConfig) {
    const {
        title,
        browserColor,
        helmetProps,
        onCreateMuiTheme,
        onError,
        appComponent: App,
        loadingComponent: Loading = CircularProgress,
        rootSvs = DefaultRootSvs,
        appStyles = {},
    } = config
    const useStyles = makeStyles(appStyles)
    const muiTheme = createCustomizedMuiTheme(onCreateMuiTheme)

    const StandardVellumTheme: ITheme = ({
        loadState,
        loadedData,
        staticData,
    }) => {
        const frontmatter = staticData[loadState.routePath]
        const [appScope] = rootSvs.useProvideNewScope(frontmatter)

        return (
            <Layout
                {...{
                    muiTheme,
                    useStyles,
                    fallback: <LinearProgress variant="indeterminate" />,
                }}
            >
                <Head {...{ title, browserColor, helmetProps }} />
                <ErrorBoundary
                    onError={onError}
                    FallbackComponent={ErrorMessage}
                >
                    {appScope.injectTo(matchLoadState())}
                </ErrorBoundary>
            </Layout>
        )

        function matchLoadState() {
            const Comp404 = loadedData["/404"]?.main?.default

            switch (loadState.type) {
                default:
                    return (
                        <App>
                            <Head title="Starting..." />
                            <Loading />
                        </App>
                    )

                case "loading":
                    return (
                        <App>
                            <Head title="Loading..." />
                            <Loading />
                        </App>
                    )

                case "404":
                    return (
                        <App>
                            <Head title="404" />
                            {Comp404 ? (
                                <Comp404 routePath={loadState.routePath} />
                            ) : (
                                <PlainMessage align="center">
                                    <p>
                                        <strong>404</strong>
                                    </p>
                                    {loadState.routePath}
                                    <p>Not Found</p>
                                </PlainMessage>
                            )}
                        </App>
                    )

                case "load-error":
                    return (
                        <App>
                            <Head title="Missing Assets" />
                            {Comp404 ? (
                                <Comp404
                                    loadError={loadState.error}
                                    routePath={loadState.routePath}
                                />
                            ) : (
                                <PlainMessage align="center">
                                    <p>
                                        <strong>Well, that's not good.</strong>
                                    </p>
                                    <p>
                                        Some assets didn't load from our server
                                        while handling "{loadState.routePath}"
                                        <br />
                                        <small>
                                            Visit{" "}
                                            <a href="https://erams.com">
                                                ERams
                                            </a>{" "}
                                            for OWSI server status.
                                            <br />
                                            You may also want to check your
                                            internet connection.
                                        </small>
                                    </p>
                                    <details>
                                        <pre>
                                            {JSON.stringify(loadState.error)}
                                        </pre>
                                    </details>
                                </PlainMessage>
                            )}
                        </App>
                    )

                case "loaded":
                    const pageData = loadedData[loadState.routePath]
                    const pageStaticData = staticData[loadState.routePath]

                    return (
                        <App>
                            <Head
                                title={frontmatter.title}
                                description={frontmatter.description}
                                browserColor={frontmatter.browserColor}
                            />
                            <ContentModule
                                key={loadState.routePath}
                                mod={pageData.main}
                                staticData={pageStaticData.main}
                                onError={onError}
                            />
                        </App>
                    )
            }
        }
    }

    StandardVellumTheme.displayName = THEME_NAME

    return StandardVellumTheme
}

function onCreateDefault(mui: IThemeBuilder) {
    mui.addCatenaPalette().addCatenaTypography()
}

function createCustomizedMuiTheme(onCreate = onCreateDefault) {
    const [builder, build] = MuiThemeBuilder({})
    onCreate(builder)

    const built = createMuiTheme(build())
    responsiveFontSizes(built)

    return built
}

function MuiThemeBuilder(
    baseTheme: Partial<Theme>
): [IThemeBuilder, () => Theme] {
    let config = { ...baseTheme }

    const builder: IThemeBuilder = {
        override(values) {
            config = { ...config, ...values }
            return builder
        },
        addCatenaPalette(colors) {
            config = addCatenaPalette(config, colors)
            return builder
        },
        addCatenaTypography() {
            config = addCatenaTypography(config)
            return builder
        },
    }

    const build = () => config as Theme

    return [builder, build]
}
