import React from "react"

import { Theme, MuiThemeProvider, CssBaseline } from "@material-ui/core"
import { WindowManager } from "./WindowManager"
import { MuiMdxProvider } from "./MuiMdxProvider"
import { Styles } from "@material-ui/styles"

/**
 * Global Stylesheet context
 */
type useStylesHook = (props?: any) => Record<string, string>

const AppStylesContext = React.createContext<useStylesHook>((props) => ({}))

export const useAppStyles: useStylesHook = (props) =>
    React.useContext(AppStylesContext)(props)

export { useErrorHandler as useErrorFallback } from "react-error-boundary"

export const Layout: React.FC<{
    muiTheme: Theme
    useStyles: useStylesHook
    fallback?: React.ReactNode
}> = ({ children, muiTheme, useStyles, fallback = null }) => {
    return (
        <AppStylesContext.Provider value={useStyles}>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline>
                    <WindowManager>
                        <React.Suspense fallback={fallback}>
                            <MuiMdxProvider>{children}</MuiMdxProvider>
                        </React.Suspense>
                    </WindowManager>
                </CssBaseline>
            </MuiThemeProvider>
        </AppStylesContext.Provider>
    )
}
