// @flow
const styles = {
    identifyAttribute: {
        color: "darkblue",
        fontSize: 12,
        padding: 2,
        verticalAlign: "top",
    },
    identifyAttributesContainer: {
        margin: "6px 2px",
    },
    identifyCloseButton: {
        backgroundColor: "white",
        height: 20,
        marginTop: 1,
        marginRight: 1,
        padding: 2,
        position: "absolute",
        right: 3,
        top: 3,
        width: 20,
        zIndex: 2,
    },
    identifyLoadingIcon: {
        color: "black",
        position: "absolute",
        top: 10,
        right: 28,
        zIndex: 2,
    },
    identifyCloseButtonIcon: {
        color: "gray",
    },
    identifyContainer: {
        borderRadius: "50%",
        fontSize: "small",
        height: 300,
        width: 400,
    },
    identifyMain: {
        padding: "4px",
    },
    identifySelect: {
        display: "inline-block",
        fontFamily: "'Roboto Condensed', sans-serif",
        fontSize: 12,
        width: "calc(100% - 20px)",
    },
    identifyTable: {
        display: "block",
        width: "100%",
    },
    identifyTbody: {
        display: "inline-block",
        height: "200px",
        overflow: "auto",
        width: "100%",
    },
    identifyTitleBar: {
        color: "black",
        padding: "4px",
    },
    identifyValue: {
        fontSize: 12,
        padding: 2,
        verticalAlign: "top",
    },
    identifyZoomTo: {
        fontSize: 10,
    },
}

export default styles
