// @flow
import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import type { GeoBarType } from "@owsi/catena/er2_ui"
import { getActiveTool } from "@owsi/catena/er2_ui"

type Props = {
    actions: {
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
    },
    geoBar: GeoBarType,
}

function RenderNotifications(props: Props) {
    const tool = getActiveTool(props)
    if (tool) {
        const layerName = tool.result ? tool.result.layerName : ""
        const message = layerName
            ? `Result saved to ${layerName}`
            : "No result found for this operation"
        return (
            <div>
                <Snackbar
                    open={tool.resultRendered === false}
                    autoHideDuration={6000}
                    onClose={() =>
                        props.actions.onGeoBarResultRendered(tool.id)
                    }
                    message={<span>{message}</span>}
                />
                {tool.fetchError && (
                    <Snackbar
                        open={Boolean(tool.fetchError)}
                        autoHideDuration={6000}
                        onClose={() =>
                            props.actions.onGeoBarFetchErrorRendered(tool.id)
                        }
                        message={
                            <span>
                                Error performing operation:{" "}
                                {tool.fetchError.exception.message}
                            </span>
                        }
                    />
                )}
            </div>
        )
    }
    return null
}

export default RenderNotifications
