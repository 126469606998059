// @flow
import * as types from "../constants/action_types"
import {
    setUserLayers,
    updateServiceProps,
} from "@owsi/catena/er2_map_userlayers/js/actions/map"
import type { SitesType } from "../reducers/public_sites"

export const getURLparams = (o) =>
    Object.entries(o)
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
        .join("&")
export const addServiceDataToSite = (siteName, serviceName, serviceData) => ({
    type: types.PUBLIC_DATA_ADD_SERVICE_DATA,
    siteName,
    serviceName,
    serviceData,
})
export const addServiceLegendToSite = (siteName, serviceName, legendData) => ({
    type: types.PUBLIC_DATA_ADD_LEGEND_DATA,
    siteName,
    serviceName,
    legendData,
})

/**
 *
 * @param siteData Array of [site_name, service_name]
 * @returns {Function}
 */
export const onAddPublicServices = (siteData: []) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token
    const siteList = siteData.map((data) => ({
        service_name: data.service.name,
        site_name: data.site.name,
        url: data.site.url,
        ...data.service,
    }))
    fetch(`/er2_map/add_public_services/?token=${token.value}`, {
        body: JSON.stringify({ site_list: siteList }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((ret) => ret.json())
        .then((json) => {
            dispatch(setUserLayers(json.state.mapsources, json.state.mapfile))
        })
}

export const onDeletePublicService = (mapsource) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token
    dispatch({ type: types.PUBLIC_DATA_DELETE, mapsource })
    fetch(`/er2_map/delete_public_service/?token=${token.value}`, {
        body: JSON.stringify({
            service: mapsource.service_name,
            site: mapsource.site_name,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((ret) => ret.json())
        .then((json) => {})
}

export const getPublicSites = (onSiteLoaded: Function) => (
    dispatch: Function,
    getState: Function,
) => {
    const handleLegend = (site, service, serviceState) => {
        if (site.hasLegend) {
            const url = `${site.url}/${service.name}/MapServer/legend`
            const params = {
                f: "json",
                pretty: true,
            }

            fetch(`${url}?${getURLparams(params)}`, {
                mode: "cors",
            })
                .then((ret) => ret.json())
                .then((json) => {
                    dispatch(
                        addServiceLegendToSite(site.name, service.name, json),
                    )
                    if (onSiteLoaded) {
                        // Add service name to state
                        onSiteLoaded(site, { ...serviceState, ...service })
                    }
                })
                .catch((err) => {
                    console.error(`${url} failed`)
                    console.error(err)
                })
        }
    }
    const getService = (site, service) => {
        const url = `${site.url}/${service.name}/MapServer/`
        const params = {
            f: "json",
            pretty: true,
        }

        let fetchError = false
        fetch(`${url}?${getURLparams(params)}`)
            .then((ret) => {
                fetchError = !ret.ok
                if (fetchError) {
                    console.error("Got service failure")
                    return null
                } else {
                    return ret.json()
                }
            })
            .then((json) => {
                dispatch(addServiceDataToSite(site.name, service.name, json))
                handleLegend(site, service, json)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    // Keep track of how many ArcGISRest layers have been processed.  When done, create
    //   the jstree tree.  Needed because legend requests are asynchronous.
    const sites = getState().publicSites.sites
    sites.forEach((site) => {
        site.services.forEach((service) => {
            getService(site, service)
        })
    })
}

export const onServiceDownload = (service, boundary) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token
    fetch(`/er2_map/export_service_layer/?token=${token.value}`, {
        body: JSON.stringify({
            boundary,
            site_name: service.site_name,
            service_name: service.service_name,
            layers: service.layers
                .filter((l) => l.visible)
                .map((l) => ({ id: l.id, name: l.name })),
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((ret) => ret.json())
        .then((json) =>
            dispatch(setUserLayers(json.state.mapsources, json.state.mapfile)),
        )
}

export const onServicePropsChange = (siteName, serviceName, serviceprops) => (
    dispatch: Function,
    getState: Function,
) => {
    dispatch(updateServiceProps(siteName, serviceName, serviceprops))

    const token = getState().token
    fetch(`/er2_map/update_service_layer/?token=${token.value}`, {
        body: JSON.stringify({
            site_name: siteName,
            service_name: serviceName,
            props: serviceprops,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    }).then((ret) => ret.json())
}

export const onAddPublicService = (serviceName) => (
    dispatch: Function,
    getState: Function,
) => {
    // Look up the NHS lines service
    const getSiteAndService = () => {
        let publicService = null
        let publicSite = null
        const publicSites: SitesType = getState().publicSites
        publicSites.sites.forEach((site) => {
            const search = site.services.find(
                (service) => service.name === serviceName,
            )
            if (search) {
                publicService = search
                publicSite = site
            }
        })
        return { site: publicSite, service: publicService }
    }

    const search = getSiteAndService()
    if (search.site && search.service) {
        // Look for layers. If it doesn't exist, then we need to load the service first.
        const onSiteLoaded = (site, service) => {
            // Check if the right service was loaded
            if (service.name === serviceName) {
                const newsearch = getSiteAndService()
                // NHD just got loaded. Now we can add it.
                dispatch(
                    onAddPublicServices([
                        { site: newsearch.site, service: newsearch.service },
                    ]),
                )
                // Make sure it is visible
                dispatch(
                    onServicePropsChange(site.name, service.name, {
                        visible: true,
                    }),
                )
            }
        }
        dispatch(getPublicSites(onSiteLoaded))
    } else {
        console.error(`Can't find ${serviceName} service definition`)
    }
}
