// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import {
    getUniqueLayerName,
    getVectorLayers,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import { getActiveTool } from "../reducers/geo_bar"
import RenderNotifications from "./tool_notifications"
import MultiLayerSelect from "./geo_bar_muilti_layer_selection"

type Props = {
    actions: {
        onClickInlineHelp: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitIntersect: Function,
        onGeoBarSelectLayer: Function,
    },
    mapsources: object,
    theme: object,
}

function isValid(tool) {
    return tool.layers.length > 1
}

function GeoBarIntersection(props: Props) {
    const theme = props.theme
    const tool = getActiveTool(props)

    const [outputName, setOutputName] = React.useState("")

    function intersect() {
        const [basename, ext] = splitExt(outputName)
        const intersectionOutputName = getUniqueLayerName(
            props.mapsources,
            basename,
        )
        props.actions.onGeoBarSubmitIntersect(
            tool.layers,
            intersectionOutputName,
        )
    }

    const onFocus = (event) => event.target.select()

    const onOutputLayerKeyDown = (ev) => {
        if (ev.key === "Enter") {
            intersect()
            ev.preventDefault()
        }
    }

    function renderTool() {
        const layers = getVectorLayers(props.mapsources)
        return (
            <React.Fragment>
                <div className={theme.toolRow}>
                    <MultiLayerSelect
                        inputLabel={"Layers to intersect"}
                        layers={layers}
                        {...props}
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Output layer name"
                        value={outputName}
                        margin="normal"
                        onChange={(evt) => setOutputName(evt.target.value)}
                        onFocus={onFocus}
                        onKeyDown={onOutputLayerKeyDown}
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <Button
                        color={"primary"}
                        disabled={!isValid(tool) || tool.isFetching}
                        onClick={intersect}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Intersect</span>}
                        {tool.isFetching && (
                            <span>
                                Intersecting&nbsp;
                                <i className="fas fa-spinner fa-spin" />
                            </span>
                        )}
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={theme.geoBarCtr}>
            <div className={props.theme.toolCtr}>{renderTool()}</div>
            <RenderNotifications {...props} />
        </div>
    )
}

export default GeoBarIntersection
