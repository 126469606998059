// @flow
import { createReducer } from "@reduxjs/toolkit"
import * as actionTypes from "../constants/action_types"

type ServiceExtentType = {
    spatialReference: { wkid: number, latestWkid: number },
    xmax: number,
    xmin: number,
    ymax: number,
    ymin: number,
}

type LayerType = {
    defaultVisibility: boolean,
    id: number,
    maxScale: number,
    minScale: number,
    name: string,
    parentLayerId: number,
    subLayerIds: number[],
}

export type ServiceType = {
    name: string,
    title: string,
    // Public data results
    documentInfo?: {},
    fullExtent?: ServiceExtentType,
    initialExtent?: ServiceExtentType,
    layers: LayerType[],
    maxScale?: number,
    minScale?: number,
    serviceDescription?: string,
}

export type SiteType = {
    name: string,
    url: string,
    wkid: number,
    hasLegend: boolean,
    services: ServiceType[],
    title: string,
    visible: boolean,
}

export type SitesType = {
    sites: SiteType[],
}

type ActionType = {
    serviceData: {},
    serviceName: string,
    siteName: string,
    type: string,
}

const initialState = {
    sites: [
        {
            name: "U.S. Geo Data",
            url: "https://carto.nationalmap.gov/arcgis/rest/services/",
            // Projection code to use for extraction
            wkid: 102100,

            // Flag indicating whether or not to try to query the service for legend data.
            hasLegend: true,
            // See http://services.nationalmap.gov/ArcGIS/rest/services
            //   Each service manages a number of layers
            services: [
                {
                    name: "transportation",
                    title: "Transportation",
                },
                {
                    name: "geonames",
                    title: "Geographic Names",
                },
                {
                    name: "govunits",
                    title: "Governmental Boundaries",
                },
                {
                    name: "structures",
                    title: "Facilities",
                },
            ],
        },
        {
            name: "U.S. Hydro Data",
            url: "https://hydro.nationalmap.gov/arcgis/rest/services/",
            // Projection code to use for extraction
            wkid: 102100,
            hasLegend: true,
            services: [
                {
                    name: "nhd",
                    title: "Hydrography (NHD)",
                },
                {
                    name: "wbd",
                    title: "Watershed Boundaries",
                },
            ],
        },
        {
            name: "U.S. EPA Water ",
            url: "https://watersgeo.epa.gov/arcgis/rest/services/",
            // Projection code to use for extraction
            wkid: 102100,

            // Set to true if the server has legend information.
            hasLegend: true,
            services: [
                {
                    name: "OWPROGRAM/NPDESPERMIT_WMERC",
                    title: "NPDES",
                },
                {
                    name: "OWPROGRAM/SDWIS_WMERC",
                    title: "SDWIS",
                },
                {
                    name: "OWPROGRAM/BEACON_NAD83",
                    title: "BEACON",
                },
            ],
        },
        // {
        //     name: "Land Use and Land Cover",
        //     services: [
        //         {
        //             name: "NASS",
        //             title: "NASS",
        //         },
        //         {
        //             name: "NLCD",
        //             title: "NLCD",
        //         },
        //     ],
        // },
    ],
}

const publicSites = createReducer(initialState, {
    [actionTypes.PUBLIC_DATA_ADD_SERVICE_DATA]: (state, action) => {
        const isite = state.sites.findIndex(
            (asite) => asite.name === action.siteName,
        )
        const site = state.sites[isite]
        const iservice = site.services.findIndex(
            (aservice) => aservice.name === action.serviceName,
        )
        Object.assign(site.services[iservice], action.serviceData)
    },
    [actionTypes.PUBLIC_DATA_ADD_LEGEND_DATA]: (state, action) => {
        const isite = state.sites.findIndex(
            (asite) => asite.name === action.siteName,
        )
        const site = state.sites[isite]
        const iservice = site.services.findIndex(
            (aservice) => aservice.name === action.serviceName,
        )
        const service = site.services[iservice]

        // Merge the layer info into the current layers
        if (service.layers) {
            service.layers = service.layers.map((l) => {
                const legendLayer = action.legendData.layers.find(
                    (l_l) => l_l.layerId === l.id,
                )
                if (legendLayer) {
                    return {
                        ...l,
                        ...legendLayer,
                        visible: legendLayer.defaultVisibility,
                    }
                }
                return l
            })
        }
    },
})

export default publicSites
