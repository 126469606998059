import React from "react"
import { useActions } from "../hooks"
import { simpleFetch, simpleFetchAction } from "../store/actions/simple_fetch"

export function getToken() {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")
    return token
}

export function getUserSchemeName() {
    return "default"
}
