// @flow
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Fab from "@material-ui/core/Fab"
import FormControl from "@material-ui/core/FormControl"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Snackbar from "@material-ui/core/Snackbar"
import TextField from "@material-ui/core/TextField"
import ErrorBoundary from "@owsi/catena/er2_map_userlayers/js/components/error_boundary"
import {
    onGeoBarSubmitCreateLayer,
    onGeoBarSubmitDigitizeSave,
} from "../actions"
import OlMap from "ol/Map"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import Collection from "ol/Collection"
import Overlay from "ol/Overlay.js"
import Draw, { createRegularPolygon } from "ol/interaction/Draw"
import ModifyInteraction from "ol/interaction/Modify"
import SelectInteraction from "ol/interaction/Select"
import SnapInteraction from "ol/interaction/Snap"
import CircleStyle from "ol/style/Circle"
import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"
import Text from "ol/style/Text"
import { DEVICE_PIXEL_RATIO } from "ol/has"
import * as OlEventsCondition from "ol/events/condition"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import Theme from "@owsi/catena/er2_styles"
import type { GeoBarType } from "@owsi/catena/er2_ui"
import { olUtils } from "@owsi/catena/er2_ui"
import {
    getExt,
    getLayerIcon,
    getUniqueLayerName,
    getVectorLayers,
    nullFunc,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import { getActiveTool } from "../reducers/geo_bar"

type Props = {
    actions: {
        onChangeInput: Function,
        onCreateLayer: Function,
        onGeoBarSubmitCreateLayer: Function,
        onGeoBarSubmitDigitizeSave: Function,
        onGeoBarFetchKnownBoundary: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarFetchKnownBoundaries: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitDigitize: Function,
        onGeoBarSelectLayer: Function,
        onUpdateUserLayers: Function,
        onUserLayersPropsChange: Function,
    },
    geoBar: GeoBarType,
    mapsources: MapsourcesType,
    olMap: OlMap,
    token: {
        value: string,
        expires: number,
    },
    theme: Theme,
}

function getFeatureCoords(geom) {
    let featureCoords
    if (geom.getType() === "Point") {
        featureCoords = [geom.getCoordinates()]
    } else if (geom.getType() === "LineString") {
        featureCoords = geom.getCoordinates()
    } else {
        featureCoords = geom.getCoordinates()[0]
    }
    return featureCoords
}

function correctOverlayCoords(coords, extent) {
    let x = coords[0]
    let y = coords[1]
    const minXExtent = Math.min(extent[0], extent[2])
    const maxXExtent = Math.max(extent[0], extent[2])
    const minYExtent = Math.min(extent[1], extent[3])
    const maxYExtent = Math.max(extent[1], extent[3])
    const xDifference = 0.2 * Math.abs(maxXExtent - minXExtent)
    const yDifference = 0.1 * Math.abs(maxYExtent - minYExtent)
    if (x < minXExtent + xDifference) x = minXExtent + xDifference
    if (x > maxXExtent - xDifference) x = maxXExtent - xDifference
    if (y < minYExtent + yDifference) y = minYExtent + yDifference
    if (y > maxYExtent - yDifference) y = maxYExtent - yDifference
    return [x, y]
}

const state = {}

function GeoBarDigitize(props: Props) {
    const [dragMethod, setDragMethod] = useState()
    const [drawMode, setDrawMode] = useState()
    const [editMode, setEditMode] = useState("Create")
    const [errorMessage, setErrorMessage] = useState(null)
    const [featureCopying, setFeatureCopying] = useState(false)
    const [featureModifying, setFeatureModifying] = useState(false)
    const [featureRenaming, setFeatureRenaming] = useState(false)
    const [featureOptions, setFeatureOptions] = useState()
    const [fetching, setFetching] = useState(false)
    const [isDirty, setIsDirty] = useState()
    const [newLayerName, setNewLayerName] = useState("")
    const [newLayerType, setNewLayerType] = useState("Point")
    const [newFeatureName, setNewFeatureName] = useState("")
    const [position, setPosition] = useState()
    const [selectedFeature, setSelectedFeature] = useState()
    const [selectInteraction, setSelectInteraction] = useState()
    const [vectorLayer, setVectorLayer] = useState()

    const dispatch = useDispatch()
    const tool = getActiveTool(props)
    const foId = "digitize-feature-options"

    const layers = getVectorLayers(props.mapsources)
    const editingLayer = layers.find((l) => l.name === tool.editingLayer)
    const hasEditingLayer =
        editingLayer && editingLayer.name === tool.editingLayer

    const saveEdits = () => {
        const source = vectorLayer.getSource()
        const geojson = olUtils.writeFeatures(source.getFeatures())
        geojson.features.forEach((f, i) => {
            if (!f.properties) {
                f.properties = { name: "new" }
            }
        })
        dispatch(onGeoBarSubmitDigitizeSave(tool.editingLayer, geojson))
        setSelectedFeature(null)
        resetFeatureOptions()
    }

    const undoEdits = () => {
        setIsDirty(false)
        props.actions.onGeoBarSelectLayer(true, "", tool, {
            kind: "editingLayer",
        })
        setTimeout(() => {
            props.actions.onGeoBarSelectLayer(true, tool.editingLayer, tool, {
                kind: "editingLayer",
            })
        }, 1)
    }

    const getStyle = useCallback(() => {
        const rgbFill = [0, 255, 0]
        const fillColor = `rgba(${[...rgbFill, 0.5]})`

        const rgbBorder = [0, 255, 255]
        const borderColor = `rgba(${[...rgbBorder, 0.5]})`

        // Todo: make this dynamic based on layer type
        return olUtils.getOlStyles({
            fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)",
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: fillColor,
                    opacity: 0.6,
                }),
                stroke: new Stroke({
                    color: borderColor,
                    width: 2,
                }),
            }),
            stroke: new Stroke({
                color: borderColor,
                width: 5,
            }),
        })
    }, [])

    const getSelectedStyle = useCallback((feat) => {
        const rgbFill = [0, 255, 0]
        const fillColor = `rgba(${[...rgbFill, 0.5]})`

        const rgbBorder = [0, 255, 255]
        const borderColor = `rgba(${[...rgbBorder, 0.5]})`
        const label = feat.get("name") || "selected"

        const text = new Text({
            fill: new Fill({
                color: "blue",
            }),
            stroke: new Stroke({
                color: "white",
                width: 2,
            }),
            font: '16px "Roboto Condensed", sans-serif',
            offsetY: 20,
            overflow: true,
            padding: [2, 2, 2, 2],
            text: label,
        })

        const pixelRatio = DEVICE_PIXEL_RATIO
        const canvas = document.createElement("canvas")
        const context = canvas.getContext("2d")
        const stripePattern = (fill, stripe) => {
            canvas.width = 20 * pixelRatio
            canvas.height = 20 * pixelRatio
            context.fillStyle = fill
            context.fillRect(0, 0, canvas.width, canvas.height)
            context.strokeStyle = stripe
            context.lineWidth = 3
            context.moveTo(9 * pixelRatio, -pixelRatio)
            context.lineTo(21 * pixelRatio, 11 * pixelRatio)
            context.moveTo(-pixelRatio, 9 * pixelRatio)
            context.lineTo(11 * pixelRatio, 21 * pixelRatio)
            context.stroke()
            return context.createPattern(canvas, "repeat")
        }

        if (
            feat
                .getGeometry()
                .getType()
                .match(/Polygon/)
        ) {
            return olUtils.getOlStyles({
                fill: new Fill({
                    color: stripePattern(fillColor, borderColor),
                    opacity: 0.6,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: fillColor,
                        opacity: 0.6,
                    }),
                    stroke: new Stroke({
                        color: borderColor,
                        width: 2,
                    }),
                }),
                stroke: new Stroke({
                    color: borderColor,
                    width: 3,
                }),
                text,
            })
        } else if (feat.getGeometry().getType().match(/Line/)) {
            return olUtils.getOlStyles({
                stroke: new Stroke({
                    color: borderColor,
                    width: 5,
                }),
                text,
            })
        } else if (feat.getGeometry().getType().match(/Point/)) {
            return olUtils.getOlStyles({
                fill: new Fill({
                    color: stripePattern(fillColor, borderColor),
                    opacity: 0.6,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: fillColor,
                        opacity: 0.6,
                    }),
                    stroke: new Stroke({
                        color: borderColor,
                        width: 10,
                    }),
                }),
                stroke: new Stroke({
                    color: borderColor,
                    width: 3,
                }),
                text,
            })
        }
    }, [])

    const getModifiedStyle = useCallback(() => {
        const rgbFill = [0, 255, 0]
        const fillColor = `rgba(${[...rgbFill, 0.5]})`

        const rgbBorder = [0, 255, 255]
        const rgbEdit = [0, 0, 255]

        const borderColor = `rgba(${[...rgbBorder, 0.5]})`
        const editColor = `rgba(${[...rgbEdit, 0.5]})`
        const label = ""

        const modifiedBorder = new Stroke({
            color: editColor,
            width: 4,
        })

        // Todo: make this dynamic based on layer type
        return olUtils.getOlStyles({
            fill: new Fill({
                color: "rgba(0, 255, 0, 0.5)",
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: fillColor,
                    opacity: 0.6,
                }),
                stroke: new Stroke({
                    color: borderColor,
                    width: 2,
                }),
            }),
            stroke: modifiedBorder,
            text: new Text({
                fill: new Fill({
                    color: "blue",
                }),
                stroke: new Stroke({
                    color: "white",
                    width: 2,
                }),
                font: '16px "Roboto Condensed", sans-serif',
                offsetY: 20,
                overflow: true,
                padding: [2, 2, 2, 2],
                text: label,
            }),
        })
    }, [])

    const positionFeatureOptionsBar = useCallback(() => {
        if (selectedFeature) {
            const featureCoords = getFeatureCoords(
                selectedFeature.getGeometry(),
            )
            const highestY = Math.max(...featureCoords.map((c) => c[1]))
            const middleX =
                (Math.min(...featureCoords.map((c) => c[0])) +
                    Math.max(...featureCoords.map((c) => c[0]))) /
                2
            let coordinates = [middleX, highestY]
            const extent = props.olMap.getView().calculateExtent()
            coordinates = correctOverlayCoords(coordinates, extent)
            setPosition(coordinates)
        } else {
            setPosition()
        }
    }, [props.olMap, selectedFeature])

    const onDeleteFeature = useCallback(() => {
        selectInteraction.getFeatures().clear()
        vectorLayer.getSource().removeFeature(selectedFeature)
        setPosition()
        setIsDirty(true)
    }, [selectedFeature, selectInteraction, vectorLayer])

    const getYDistanceToSelected = (e, selFeat) => {
        const map = document.getElementById("map")
        const rect = map.getBoundingClientRect()
        const point = [e.clientX - rect.left, e.clientY - rect.top]
        const geometry = selFeat.getGeometry()
        let center
        if (geometry.getType() === "Point") {
            center = geometry.getCoordinates()
        } else {
            const extent = geometry.getExtent()
            center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2]
        }
        const pixel = props.olMap.getPixelFromCoordinate(center)
        return point[1] - pixel[1]
    }

    function getCursorPoint(e) {
        if (!e) {
            console.error("no mount click event")
        }
        const map = document.getElementById("map")
        const rect = map.getBoundingClientRect()
        return [e.clientX - rect.left, e.clientY - rect.top]
    }

    function resetFeatureOptions() {
        setDrawMode("")
        setDragMethod(null)
        setFeatureCopying(false)
        setFeatureModifying(false)
    }

    const scaleFeature = (e, selFeat) => {
        const geometry = selFeat.getGeometry()
        const distance = getYDistanceToSelected(e, selFeat)
        if (Math.abs(distance) > 1) {
            const scaleDistance = state.scaleDistance
            const scale = distance / scaleDistance
            console.log(`${distance} / ${scaleDistance} = ${scale}`)
            if (Math.abs(scale) > 0.01) {
                geometry.scale(distance / scaleDistance)
            }
            state.scaleDistance = distance
            setIsDirty(true)
        }
    }

    const startScaleFeature = (e) => {
        resetFeatureOptions()
        state.scaleDistance = getYDistanceToSelected(e, selectedFeature)
        setDragMethod(() => scaleFeature)
        e.dataTransfer.setData("Text", "")
    }

    const translateFeature = (e, selFeat) => {
        const geometry = selFeat.getGeometry()
        const point = props.olMap.getCoordinateFromPixel(getCursorPoint(e))
        const oldPoint = state.translatePoint
        geometry.translate(point[0] - oldPoint[0], point[1] - oldPoint[1])
        state.translatePoint = point
        positionFeatureOptionsBar()
        setIsDirty(true)
    }

    const toggleModify = useCallback(() => {
        resetFeatureOptions()
        setFeatureModifying(!featureModifying)
    }, [featureModifying])

    const startTranslateFeature = (e) => {
        resetFeatureOptions()
        setDragMethod(() => translateFeature)

        // Store the old point in the feature itself.
        state.translatePoint = props.olMap.getCoordinateFromPixel(
            getCursorPoint(e),
        )
        e.dataTransfer.setData("Text", "")
    }

    const rotateFeature = (e, selFeat) => {
        const geometry = selFeat.getGeometry()
        const point = getCursorPoint(e)
        const pixelCenter = props.olMap.getPixelFromCoordinate(
            state.rotateCenter,
        )
        const oldAngle = Math.atan2(
            state.rotatePoint[1] - pixelCenter[1],
            state.rotatePoint[0] - pixelCenter[0],
        )
        const newAngle = Math.atan2(
            point[1] - pixelCenter[1],
            point[0] - pixelCenter[0],
        )
        geometry.rotate(oldAngle - newAngle, state.rotateCenter)
        state.rotatePoint = point
        setIsDirty(true)
    }

    const startRotateFeature = (e) => {
        resetFeatureOptions()

        const geometry = selectedFeature.getGeometry()
        const extent = geometry.getExtent()
        const center = [
            (extent[0] + extent[2]) / 2,
            (extent[1] + extent[3]) / 2,
        ]
        setDragMethod(() => rotateFeature)
        state.rotatePoint = getCursorPoint(e)
        state.rotateCenter = center
        e.dataTransfer.setData("Text", "")
    }

    const renameFeature = (e, selFeat) => {
        setFeatureRenaming(true)
        setFeatureCopying(false)
        setNewFeatureName(selFeat.properties.name || "")
    }

    const toggleRenameFeature = useCallback(() => {
        setFeatureRenaming(!featureRenaming)
        if (!featureRenaming) {
            setFeatureCopying(false)
            setNewFeatureName("")
        }
    }, [featureRenaming])

    const onRenameFeature = () => {
        selectedFeature.set("name", newFeatureName)
        setNewFeatureName("")
        setFeatureRenaming(false)
        setFeatureCopying(false)
        setIsDirty(true)
    }

    const onDragEnd = useCallback(() => {
        positionFeatureOptionsBar()
    }, [positionFeatureOptionsBar])

    const toggleCopyFeature = useCallback(() => {
        resetFeatureOptions()
        setFeatureCopying(!featureCopying)
    }, [featureCopying])

    const cloneSelectedFeature = useCallback(() => {
        const id = vectorLayer.getSource().getFeatures().length + 1
        const featureClone = selectedFeature.clone()
        featureClone.setId(id)
        featureClone.set("gid", id)
        return featureClone
    }, [selectedFeature, vectorLayer])

    const getPixelSize = useCallback(() => {
        const mapSize = props.olMap.getSize()
        const extent = props.olMap.getView().calculateExtent(mapSize)
        const dx = (extent[2] - extent[0]) / mapSize[0]
        const dy = (extent[3] - extent[1]) / mapSize[1]
        return [dx, dy]
    }, [props.olMap])

    const getSelectedFeaturePixelExtent = useCallback(() => {
        const geometry = selectedFeature.getGeometry()
        const extent = geometry.getExtent()
        const topLeft = props.olMap.getPixelFromCoordinate([
            extent[0],
            extent[1],
        ])
        const lowerRight = props.olMap.getPixelFromCoordinate([
            extent[2],
            extent[3],
        ])
        return [...topLeft, ...lowerRight]
    }, [props.olMap, selectedFeature])

    const getCopyPosition = useCallback(() => {
        const pixExtent = getSelectedFeaturePixelExtent()
        const rect = document.getElementById(foId).getBoundingClientRect()
        const buttonRect = document
            .getElementById("feature-copy")
            .getBoundingClientRect()
        const buttonOffset = buttonRect.width / 2
        const center = [rect.width / 2, rect.height / 2]
        const width = pixExtent[2] - pixExtent[0]
        const height = pixExtent[1] - pixExtent[3]
        const positions = {
            copyDown: {
                top: center[1] + buttonRect.width + buttonOffset + height,
                left: center[0] - buttonOffset,
            },
            copyLeft: {
                left: center[0] - buttonOffset - buttonOffset * 2 - width / 2,
                top: center[1] + buttonOffset + height / 2,
            },
            copyRight: {
                left: center[0] + buttonOffset + width / 2,
                top: center[1] + buttonOffset + height / 2,
            },
            copyUp: {
                left: center[0] - buttonOffset,
            },
        }
        return {
            buttonSize: buttonRect.width,
            center,
            height,
            positions,
            pixExtent,
            width,
        }
    }, [getSelectedFeaturePixelExtent])

    const copyFeature = useCallback(
        (direction) => {
            const featureClone = cloneSelectedFeature()
            const { buttonSize } = getCopyPosition()
            const pixelExtent = getPixelSize()
            const geometry = featureClone.getGeometry()
            const extent = geometry.getExtent()
            const featWidth = Math.abs(extent[2] - extent[0])
            const featHeight = Math.abs(extent[3] - extent[1])
            if (direction === "up") {
                geometry.translate(
                    0,
                    buttonSize * 2.5 * pixelExtent[1] + featHeight,
                )
            } else if (direction === "down") {
                geometry.translate(
                    0,
                    -(buttonSize * 2 * pixelExtent[1] + featHeight),
                )
            } else if (direction === "left") {
                geometry.translate(
                    -(buttonSize * 2 * pixelExtent[0] + featWidth),
                    0,
                )
            } else if (direction === "right") {
                geometry.translate(
                    buttonSize * 2 * pixelExtent[0] + featWidth,
                    0,
                )
            }
            vectorLayer.getSource().addFeature(featureClone)
            setIsDirty(true)
        },
        [cloneSelectedFeature, getCopyPosition, getPixelSize, vectorLayer],
    )

    const copyFeatureUp = useCallback(() => copyFeature("up"), [copyFeature])
    const copyFeatureDown = useCallback(() => copyFeature("down"), [
        copyFeature,
    ])
    const copyFeatureRight = useCallback(() => copyFeature("right"), [
        copyFeature,
    ])
    const copyFeatureLeft = useCallback(() => copyFeature("left"), [
        copyFeature,
    ])

    const getCopyLeftStyle = () => {
        const { positions } = getCopyPosition()
        return {
            left: `${positions.copyLeft.left}px`,
            top: `${positions.copyLeft.top}px`,
        }
    }

    const getCopyRightStyle = () => {
        const { positions } = getCopyPosition()
        return {
            left: `${positions.copyRight.left}px`,
            top: `${positions.copyRight.top}px`,
        }
    }

    const getCopyUpStyle = () => {
        const { positions } = getCopyPosition()
        return {
            left: `${positions.copyUp.left}px`,
        }
    }

    const getCopyDownStyle = () => {
        const { positions } = getCopyPosition()
        return {
            left: `${positions.copyDown.left}px`,
            top: `${positions.copyDown.top}px`,
        }
    }

    const onChangeNewFeatureName = (e) => {
        setNewFeatureName(e.target.value)
    }

    const onKeyDownRename = (e) => {
        if (e.keyCode === 13) {
            onRenameFeature()
        }
    }

    // set draw mode if source has no features
    const editingLayerName = editingLayer ? editingLayer.name : null
    const updateEditingToolSelection = useCallback(() => {
        if (
            vectorLayer &&
            vectorLayer.getSource().getFeatures().length === 0 &&
            editingLayer
        ) {
            setDrawMode(editingLayer.layer_type)
        }
    }, [editingLayer, vectorLayer])

    useEffect(() => {
        const vs = new VectorSource()
        const vl = new VectorLayer({
            source: vs,
            style: getStyle,
            zIndex: 100,
        })
        vl.set("digitizingLayer", true)
        props.olMap.addLayer(vl)
        setVectorLayer(vl)

        return () => {
            props.olMap.removeLayer(vl)
            props.actions.onGeoBarSelectLayer(true, "", tool, {
                kind: "editingLayer",
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getStyle, props.actions, props.olMap])

    // Selecting a new layer
    useEffect(() => {
        if (vectorLayer) {
            const source = vectorLayer.getSource()
            source.clear()
            if (selectInteraction) {
                selectInteraction.getFeatures().clear()
                setPosition()
            }

            if (tool.editingLayer) {
                const olayer = getVectorLayers(props.mapsources).find(
                    (l) => l.name === tool.editingLayer,
                )
                if (olayer) {
                    const query = `?token=${props.token.value}&layerName=${olayer.name}`
                    let fetchError
                    setFetching(true)
                    fetch(`/er2_map/api/v1/get_geojson/${query}`)
                        .then((response) => {
                            fetchError = !response.ok
                            return response.json()
                        })
                        .then((json) => {
                            if (fetchError) {
                                setErrorMessage(json.exception.message)
                            } else if (json.geojson.features.length > 0) {
                                source.addFeatures(
                                    olUtils.readFeatures(json.geojson),
                                )
                                const extent = source.getExtent()
                                props.olMap
                                    .getView()
                                    .fit(extent, props.olMap.getSize())
                            }
                            setFetching(false)
                            updateEditingToolSelection()
                        })
                        .catch((err) => {
                            setFetching(false)
                        })
                }
                return () => {
                    // Check if another layer was being edited, in which case we set it visible.
                    const oldLayer = getVectorLayers(props.mapsources).find(
                        (l) => l.name === editingLayerName,
                    )
                    if (oldLayer && !oldLayer.visible) {
                        props.actions.onUserLayersPropsChange(
                            oldLayer.mapsource,
                            oldLayer,
                            { visible: true },
                        )
                    }
                }
            }
        }
    }, [
        vectorLayer,
        updateEditingToolSelection,
        props.mapsources,
        selectInteraction,
        tool.editingLayer,
        props.token.value,
        props.olMap,
        props.actions,
        editingLayerName,
    ])

    useEffect(() => {
        if (vectorLayer) {
            const select = new SelectInteraction({
                layers: [vectorLayer],
                style: getSelectedStyle,
            })
            props.olMap.addInteraction(select)
            select.on("select", (e) => {
                // Check if nothing is selected
                if (e.selected.length === 0) {
                    setSelectedFeature(null)
                } else {
                    e.selected.forEach((feat) => {
                        setSelectedFeature(feat)
                    })
                }
            })
            setSelectInteraction(select)

            const el = document.getElementById(foId)
            el.style.visibility = "visible"
            const featOpts = new Overlay({
                id: foId,
                element: el,
                insertFirst: true,
                positioning: "bottom-center",
            })
            props.olMap.addOverlay(featOpts)
            setFeatureOptions(featOpts)
            return () => {
                props.olMap.removeOverlay(featOpts)
                props.olMap.removeInteraction(select)
                setSelectInteraction(null)
            }
        }
        return nullFunc
    }, [getSelectedStyle, vectorLayer, props.olMap])

    useEffect(() => {
        if (selectedFeature) {
            const editButton = document.getElementById("feature-edit")
            const renameButton = document.getElementById("feature-rename")
            const copyButton = document.getElementById("feature-copy")
            const deleteButton = document.getElementById("feature-delete")
            if (editButton) {
                editButton.onclick = toggleModify
            }
            renameButton.onclick = toggleRenameFeature
            copyButton.onclick = toggleCopyFeature
            deleteButton.onclick = onDeleteFeature

            if (dragMethod) {
                const dragOverEvent = (e) => dragMethod(e, selectedFeature)
                document.addEventListener("dragover", dragOverEvent)
                document.addEventListener("dragend", onDragEnd)
                return () => {
                    document.removeEventListener("dragover", dragOverEvent)
                    document.removeEventListener("dragend", onDragEnd)
                }
            }
            if (featureModifying) {
                const selectedCollection = new Collection([selectedFeature])
                const modify = new ModifyInteraction({
                    deleteCondition: (evt) =>
                        OlEventsCondition.shiftKeyOnly(evt) &&
                        OlEventsCondition.singleClick(evt),
                    features: selectedCollection,
                })
                selectedFeature.setStyle(getModifiedStyle)
                modify.on("modifyend", () => {
                    positionFeatureOptionsBar()
                    setIsDirty(true)
                })
                props.olMap.addInteraction(modify)

                const snap = new SnapInteraction({
                    source: vectorLayer.getSource(),
                })
                props.olMap.addInteraction(snap)

                return () => {
                    selectedFeature.setStyle(getStyle)
                    props.olMap.removeInteraction(snap)
                    props.olMap.removeInteraction(modify)
                }
            }
        }
        return nullFunc
    }, [
        getStyle,
        getModifiedStyle,
        selectedFeature,
        dragMethod,
        featureCopying,
        featureModifying,
        onDeleteFeature,
        onDragEnd,
        positionFeatureOptionsBar,
        props.olMap,
        toggleCopyFeature,
        toggleModify,
        toggleRenameFeature,
        vectorLayer,
    ])

    useEffect(() => {
        if (featureCopying) {
            const copyUpButton = document.getElementById("feature-copy-up")
            const copyLeftButton = document.getElementById("feature-copy-left")
            const copyRightButton = document.getElementById(
                "feature-copy-right",
            )
            const copyDownButton = document.getElementById("feature-copy-down")
            copyUpButton.onclick = copyFeatureUp
            copyLeftButton.onclick = copyFeatureLeft
            copyRightButton.onclick = copyFeatureRight
            copyDownButton.onclick = copyFeatureDown
        }
    }, [
        copyFeatureDown,
        copyFeatureUp,
        copyFeatureLeft,
        copyFeatureRight,
        featureCopying,
    ])

    useEffect(() => {
        if (selectedFeature) {
            const featureCoords = getFeatureCoords(
                selectedFeature.getGeometry(),
            )
            const highestY = Math.max(...featureCoords.map((c) => c[1]))
            const middleX =
                (Math.min(...featureCoords.map((c) => c[0])) +
                    Math.max(...featureCoords.map((c) => c[0]))) /
                2
            let coordinates = [middleX, highestY]
            const extent = props.olMap.getView().calculateExtent()
            coordinates = correctOverlayCoords(coordinates, extent)
            setPosition(coordinates)
        } else {
            setPosition()
        }
    }, [selectedFeature, props.olMap])

    useEffect(() => {
        if (featureOptions) {
            featureOptions.setPosition(position)
        }
    }, [featureOptions, position])

    useEffect(() => {
        if (vectorLayer && drawMode) {
            const opts = {
                style: getStyle,
                source: vectorLayer.getSource(),
                type: drawMode,
            }
            if (drawMode === "Circle") {
                opts.geometryFunction = createRegularPolygon(40)
            }
            const draw = new Draw(opts)
            draw.on("drawend", (evt) => {
                const id = vectorLayer.getSource().getFeatures().length + 1
                evt.feature.setId(id)
                evt.feature.set("gid", id)
                setIsDirty(true)
            })
            props.olMap.addInteraction(draw)
            return () => {
                props.olMap.removeInteraction(draw)
            }
        }
        return nullFunc
    }, [vectorLayer, drawMode, getStyle, props.olMap])

    useEffect(() => {
        if (
            tool.result.kind === "Create" &&
            tool.editingLayer !== tool.result.layerName
        ) {
            setEditMode("Edit")
            props.actions.onGeoBarSelectLayer(
                true,
                tool.result.layerName,
                tool,
                { kind: "editingLayer" },
            )
        }
    }, [tool, props.actions.onGeoBarSelectLayer, props.actions])

    const onCreateNewLayer = () => {
        let outputName = getUniqueLayerName(props.mapsources, newLayerName)
        const ext = getExt(outputName)
        if (!ext) {
            outputName += ".json"
        }

        // This action is not getting passed in.
        dispatch(onGeoBarSubmitCreateLayer(newLayerType, outputName))
        // props.actions.onGeoBarSubmitCreateLayer(newLayerType, outputName)
    }

    const onCreateNewLayerKeyDown = (ev) => {
        if (ev.key === "Enter") {
            onCreateNewLayer()
            ev.preventDefault()
        }
    }

    const onSetLayer = (evt) => {
        setSelectedFeature(null)
        resetFeatureOptions()

        // Turn on old layer
        const oldLayer = getVectorLayers(props.mapsources).find(
            (l) => l.name === editingLayerName,
        )
        if (oldLayer) {
            props.actions.onUserLayersPropsChange(
                oldLayer.mapsource,
                oldLayer,
                { visible: true },
            )
        }

        // Turn off the current layer
        const layerName = evt.target.value
        const layer = getVectorLayers(props.mapsources).find(
            (l) => l.name === layerName,
        )
        if (layer) {
            props.actions.onUserLayersPropsChange(layer.mapsource, layer, {
                visible: false,
            })
            props.actions.onGeoBarSelectLayer(true, layerName, tool, {
                kind: "editingLayer",
            })
        }
    }

    const onSetNewLayerName = (evt) => {
        setNewLayerName(evt.target.value)
    }

    const onSetNewLayerType = (evt) => {
        setNewLayerType(evt.target.value)
    }

    const toggleDrawMode = (newDrawMode) => {
        setDrawMode(drawMode === newDrawMode ? "" : newDrawMode)
    }

    const selectLine = () => {
        toggleDrawMode("LineString")
    }

    const selectPoint = () => {
        toggleDrawMode("Point")
    }

    const selectCircle = () => {
        toggleDrawMode("Circle")
    }

    const selectPolygon = () => {
        toggleDrawMode("Polygon")
    }

    const onCloseErrorMessage = () => {
        setErrorMessage(null)
    }

    const layerIsPolygon = () =>
        editingLayer && editingLayer.layer_type === "Polygon"
    const layerIsNotPoint = () =>
        editingLayer && editingLayer.layer_type !== "Point"

    function renderCreateControls() {
        const isValid = newLayerName.length > 0
        return (
            <ErrorBoundary>
                <div className={props.theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={"vector_create_options"}>
                            New Layer Type
                        </InputLabel>
                        <Select
                            value={newLayerType}
                            inputProps={{
                                id: "vector_create_options",
                            }}
                            onChange={onSetNewLayerType}
                        >
                            <MenuItem value={"Point"}>Point</MenuItem>
                            <MenuItem value={"LineString"}>Line</MenuItem>
                            <MenuItem value={"Polygon"}>Polygon</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        id={"new_layer_name"}
                        required
                        label="New Layer Name"
                        value={newLayerName}
                        onChange={onSetNewLayerName}
                        onKeyDown={onCreateNewLayerKeyDown}
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <Button
                        color={"primary"}
                        disabled={!isValid || tool.isFetching}
                        onClick={onCreateNewLayer}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Create</span>}
                        {tool.isFetching && (
                            <span>
                                Creating&nbsp;
                                <i className="fas fa-spinner fa-spin" />
                            </span>
                        )}
                    </Button>
                </div>
            </ErrorBoundary>
        )
    }

    function renderEditButtons() {
        if (editingLayer.layer_type === "Point") {
            return (
                <Fab
                    color={drawMode === "Point" ? "primary" : "default"}
                    onClick={selectPoint}
                    size={"small"}
                    title="Click on the map to create the point."
                >
                    <Icon className={"ms ms-draw-point"} />
                </Fab>
            )
        } else if (editingLayer.layer_type === "LineString") {
            return (
                <Fab
                    color={drawMode === "LineString" ? "primary" : "default"}
                    onClick={selectLine}
                    size={"small"}
                    title="Click on the map where the beginning of the line should be located and move the mouse to direct it. Click on the map once the line is correct."
                >
                    <Icon className={"ms ms-draw-line"} />
                </Fab>
            )
        } else if (editingLayer.layer_type === "Polygon") {
            return (
                <React.Fragment>
                    <Fab
                        color={drawMode === "Polygon" ? "primary" : "default"}
                        onClick={selectPolygon}
                        size={"small"}
                        title="Click on the map where the beginning of the line should be located and move the mouse to direct it. Click on the map once the line is correct."
                    >
                        <Icon className={"ms ms-draw-polygon"} />
                    </Fab>
                    <Fab
                        color={drawMode === "Circle" ? "primary" : "default"}
                        onClick={selectCircle}
                        size={"small"}
                        style={{ marginLeft: 20 }}
                        title="Click on the map where the center of the circle should be located and move the mouse outward. Click on the map once the circle is correct."
                    >
                        <Icon
                            className={`ms ms-draw-point ${props.theme.iconBar}`}
                            onClick={selectPolygon}
                            title="Create the polygon by clicking on the map where each vertex should be located and click the last point twice to end the drawing."
                        >
                            <i
                                className={`far fa-circle ${props.theme.faIconStacked}`}
                            />
                        </Icon>
                    </Fab>
                </React.Fragment>
            )
        }
        return null
    }

    function renderEditControls() {
        return (
            <div className={props.theme.toolCtr}>
                <div className={props.theme.toolRow}>
                    <FormControl disabled={fetching} fullWidth>
                        <InputLabel htmlFor={"editing_layer"}>
                            Editing Layer
                        </InputLabel>
                        <Select
                            value={tool.editingLayer}
                            inputProps={{
                                id: "editing_layer",
                            }}
                            onChange={onSetLayer}
                        >
                            {layers.map((l) => (
                                <MenuItem key={l.name} value={l.name}>
                                    {getLayerIcon(l)}&nbsp;{l.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ margin: "auto", marginLeft: 20, width: 20 }}>
                        {fetching && (
                            <span>
                                <i className="fas fa-spinner fa-spin" />
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className={props.theme.toolRow}
                    style={{ justifyContent: "initial" }}
                >
                    {hasEditingLayer &&
                        !tool.isFetching &&
                        !fetching &&
                        renderEditButtons()}
                </div>
                {hasEditingLayer && (
                    <div className={props.theme.toolRow}>
                        <Button
                            color="primary"
                            disabled={!isDirty || tool.isFetching}
                            onClick={saveEdits}
                            variant={"contained"}
                        >
                            {!tool.isFetching && <span>Save</span>}
                            {tool.isFetching && (
                                <span>
                                    Saving&nbsp;
                                    <i className="fas fa-spinner fa-spin" />
                                </span>
                            )}
                        </Button>
                        <Button
                            color="primary"
                            disabled={!isDirty || tool.isFetching}
                            onClick={undoEdits}
                            variant={"contained"}
                        >
                            {!tool.isFetching && <span>Cancel</span>}
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    const renderRenameFeature = () => (
        <div className={props.theme.overlayAddon}>
            <TextField
                autoFocus
                id="feature-rename-field"
                value={newFeatureName}
                onChange={onChangeNewFeatureName}
                onKeyDown={onKeyDownRename}
                onFocus={(e) => e.target.select()}
                label={"Feature Name"}
            />
            <IconButton
                id="feature-accept-rename"
                title={"Click to accept feature name."}
                onClick={onRenameFeature}
            >
                <i className={`fas fa-check ${props.theme.digIcon}`} />
            </IconButton>
        </div>
    )

    const renderCopyFeature = () => (
        <div>
            <Fab
                className={`${props.theme.copyUp} ${props.theme.editButton}`}
                id="feature-copy-up"
                size={"small"}
                style={getCopyUpStyle()}
                title={"Click to create a copy to the North."}
            >
                <i className={`fas fa-arrow-up ${props.theme.digIcon}`} />
            </Fab>
            <Fab
                className={`${props.theme.copyLeft} ${props.theme.editButton}`}
                id="feature-copy-left"
                size={"small"}
                style={getCopyLeftStyle()}
                title={"Click to create a copy to the West."}
            >
                <i className={`fas fa-arrow-left ${props.theme.digIcon}`} />
            </Fab>
            <Fab
                className={`${props.theme.copyRight} ${props.theme.editButton}`}
                id="feature-copy-right"
                size={"small"}
                style={getCopyRightStyle()}
                title={"Click to create a copy to the East."}
            >
                <i className={`fas fa-arrow-right ${props.theme.digIcon}`} />
            </Fab>
            <Fab
                className={`${props.theme.copyDown} ${props.theme.editButton}`}
                id="feature-copy-down"
                size={"small"}
                style={getCopyDownStyle()}
                title={"Click to create a copy to the South."}
            >
                <i className={`fas fa-arrow-down ${props.theme.digIcon}`} />
            </Fab>
        </div>
    )

    const renderModifyFeature = () => (
        <div className={props.theme.featureHelpContainer}>
            <em className={props.theme.modifyFeature}>
                Click and drag to move or add a vertex.
            </em>
            <em className={props.theme.modifyFeature}>
                Shift-click to delete a vertex.
            </em>
        </div>
    )

    function renderOverlay() {
        return (
            <div
                id={foId}
                className={props.theme.featureEditBar}
                style={{ visibility: "collapse" }}
            >
                {featureRenaming && renderRenameFeature()}
                {featureCopying && renderCopyFeature()}
                {featureModifying && renderModifyFeature()}
                <div>
                    {layerIsNotPoint() && (
                        <IconButton
                            id="feature-edit"
                            title={`Click to ${
                                featureModifying ? "finish editing" : "edit"
                            } boundaries.`}
                        >
                            <i
                                className={`${
                                    featureModifying
                                        ? "fas fa-check"
                                        : "fas fa-draw-polygon"
                                } ${props.theme.digIcon}`}
                            />
                        </IconButton>
                    )}
                    <IconButton
                        id="feature-rename"
                        title={"Click to change feature name."}
                    >
                        <i
                            className={`fas fa-pencil-alt ${props.theme.digIcon}`}
                        />
                    </IconButton>
                    {layerIsPolygon() && (
                        <IconButton
                            className={props.theme.draggableButton}
                            draggable
                            onDragStart={startScaleFeature}
                            title={"Click and drag to resize."}
                        >
                            <i
                                className={`ms ms-maximize ${props.theme.draggableIcon}`}
                            />
                        </IconButton>
                    )}
                    {layerIsNotPoint() && (
                        <IconButton
                            draggable
                            className={props.theme.draggableButton}
                            onDragStart={startRotateFeature}
                            title={"Click and drag to rotate."}
                        >
                            <i
                                className={`fas fa-sync-alt ${props.theme.draggableIcon}`}
                            />
                        </IconButton>
                    )}
                    <IconButton
                        draggable
                        className={props.theme.draggableButton}
                        onDragStart={startTranslateFeature}
                        title={"Click and drag to move."}
                    >
                        <i
                            className={`ms ms-max-extent ${props.theme.draggableIcon}`}
                        />
                    </IconButton>
                    <IconButton
                        id="feature-copy"
                        onClick={toggleCopyFeature}
                        title={"Click to duplicate."}
                    >
                        <i className={`fas fa-copy ${props.theme.digIcon}`} />
                    </IconButton>
                    <IconButton id="feature-delete" title={"Click to delete."}>
                        <i className={`fas fa-trash ${props.theme.digIcon}`} />
                    </IconButton>
                </div>
            </div>
        )
    }

    function renderTool() {
        const onChangeTab = (e) => {
            setEditMode(e.target.textContent)
        }
        return (
            <ErrorBoundary>
                <div className={props.theme.toolRow}>
                    <Select defaultValue={"blam"}>
                        <MenuItem value={"blam"}>Blam</MenuItem>
                        <MenuItem value={"blam1"}>Blam1</MenuItem>
                        <MenuItem value={"blam2"}>Blam2</MenuItem>
                    </Select>

                    <ButtonGroup color="primary" size="small">
                        <Button
                            onClick={onChangeTab}
                            variant={
                                editMode === "Create" ? "contained" : "outlined"
                            }
                        >
                            Create
                        </Button>
                        <Button
                            onClick={onChangeTab}
                            variant={
                                editMode === "Edit" ? "contained" : "outlined"
                            }
                        >
                            Edit
                        </Button>
                    </ButtonGroup>
                </div>
                {editMode === "Create"
                    ? renderCreateControls()
                    : renderEditControls()}
            </ErrorBoundary>
        )
    }

    function renderNotifications() {
        const layerName = tool.result ? tool.result.layerName : ""
        const message = layerName
            ? `Result saved to ${layerName}`
            : "No result found for this operation"
        return (
            <div>
                <Snackbar
                    open={tool.resultRendered === false}
                    autoHideDuration={6000}
                    onClose={() => {
                        props.actions.onGeoBarResultRendered(tool.id)
                        props.actions.onUpdateUserLayers()
                        setIsDirty(false)
                    }}
                    message={<span id="message-id">{message}</span>}
                />
                <Snackbar
                    open={tool.fetchError}
                    autoHideDuration={6000}
                    onClose={() =>
                        props.actions.onGeoBarFetchErrorRendered(tool.id)
                    }
                    message={
                        <span id="message-id">Error performing operation</span>
                    }
                />
            </div>
        )
    }

    function renderErrors() {
        return (
            <div>
                <Snackbar
                    open={errorMessage !== null}
                    autoHideDuration={6000}
                    onClose={onCloseErrorMessage}
                    message={<span>{errorMessage}</span>}
                />
            </div>
        )
    }

    return (
        <div className={props.theme.geoBarCtr}>
            {renderOverlay()}
            {renderTool()}
            {renderNotifications()}
            {renderErrors()}
        </div>
    )
}

export default GeoBarDigitize
