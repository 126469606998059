// @flow
import React from "react"
import ResizableTable from "@owsi/catena/er2_map_userlayers/js/components/resizable_table"
import { getAttributes } from "@owsi/catena/er2_map_userlayers/js/components/attribute_table/attribute_table_stable"
import type { UserLayerType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import VectorSource from "ol/source/Vector"
import VectorLayer from "ol/layer/Vector"
import OlMap from "ol/Map"
import { olUtils } from "@owsi/catena/er2_ui"
import SelectInteraction from "ol/interaction/Select"
import { nullFunc } from "@owsi/catena/er2_map_userlayers/js/utils"

type Props = {
    height: number,
    layer: UserLayerType,
    olMap: OlMap,
}

export default function LAMPS(props: Props) {
    const [data, setData] = React.useState([])
    const [feature, setFeature] = React.useState()
    const [layerName, setLayerName] = React.useState()
    const [rows, setRows] = React.useState([])

    React.useEffect(() => {
        if (props.layer.name !== layerName) {
            setLayerName(props.layer.name)
            getAttributes(
                { ...props, attributeTable: props.layer },
                (layerRows) => {
                    setRows(layerRows)
                },
            )
        }
    }, [props.layer])

    React.useEffect(() => {
        if (rows.length) {
            const ifield = feature ? feature.getId() : 0
            const field = rows[ifield]
            const years = Object.keys(field.properties)
                .filter((k) => k.indexOf("dcrop") === 0)
                .map((k) => parseInt(k.substring(k.length - 4)))

            const lampsData = []
            years.forEach((y) => {
                lampsData.push({
                    year: y,
                    dom_crop: field.properties[`dcrop_${y}`],
                    dom_conf: field.properties[`dconf_${y}`],
                    irrigation: field.properties[`irr_${y}`],
                    sec_conf: field.properties[`sconf_${y}`],
                    sec_crop: field.properties[`scrop_${y}`],
                })
            })
            setData(lampsData)
        }
    }, [rows, feature])

    React.useEffect(() => {
        if (rows.length) {
            const vs = new VectorSource()
            vs.addFeatures(olUtils.readFeatures(rows))

            const vl = new VectorLayer({
                source: vs,
                zIndex: 100,
            })
            props.olMap.addLayer(vl)

            const select = new SelectInteraction({
                layers: [vl],
            })
            props.olMap.addInteraction(select)
            select.on("select", (e) => {
                // Check if nothing is selected
                if (e.selected.length === 0) {
                    setFeature(null)
                } else {
                    e.selected.forEach((feat) => {
                        setFeature(feat)
                    })
                }
            })

            return () => {
                props.olMap.removeInteraction(select)
                props.olMap.removeLayer(vl)
            }
        }
        return nullFunc
    }, [rows])

    if (data.length) {
        const columns = [
            {
                Header: "Year",
                accessor: "year",
                Cell: (cellProps) => (
                    <div className={props.theme.lampsCell}>
                        {cellProps.original.year}
                    </div>
                ),
            },
            {
                Header: "Dominant Crop",
                accessor: "dom_crop",
                Cell: (cellProps) => (
                    <div className={props.theme.lampsCell}>
                        {cellProps.original.dom_crop}
                    </div>
                ),
            },
            {
                Header: "Confidence",
                accessor: "dom_conf",
                Cell: (cellProps) => (
                    <div className={props.theme.lampsCell}>
                        {cellProps.original.dom_conf}
                    </div>
                ),
            },
            {
                Header: "Secondary Crop",
                accessor: "sec_crop",
                Cell: (cellProps) => (
                    <div className={props.theme.lampsCell}>
                        {cellProps.original.sec_crop}
                    </div>
                ),
            },
            {
                Header: "Confidence",
                accessor: "sec_conf",
                Cell: (cellProps) => (
                    <div className={props.theme.lampsCell}>
                        {cellProps.original.sec_conf}
                    </div>
                ),
            },
            {
                Header: "Irrigation",
                accessor: "irrigation",
                Cell: (cellProps) => (
                    <div className={props.theme.lampsCell}>
                        {cellProps.original.irrigation}
                    </div>
                ),
            },
        ]

        return (
            <ResizableTable
                columns={columns}
                data={data}
                height={props.height}
            />
        )
    }
    return (
        <div
            style={{
                fontSize: 24,
                marginTop: 40,
                textAlign: "center",
            }}
        >
            Loading&nbsp;
            <i className="fas fa-spinner fa-spin" />
        </div>
    )
}
