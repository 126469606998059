// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { getBaseType } from "@owsi/catena/er2_map_userlayers/js/utils"

type PropsType = {
    // This will be non-null if an existing column is being edited.
    columnName?: string,
    columnType?: string,
    onAccept: Function,
    onClose: Function,
    open: boolean,
}

function AddColumn(props: PropsType) {
    const [name, setName] = React.useState("")
    const [type, setType] = React.useState("")

    React.useEffect(() => {
        if (props.columnName) {
            setName(props.columnName)
            setType(getBaseType(props.columnType))
        }
    }, [props])

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleTypeChange = (e) => {
        setType(e.target.value)
    }

    const onAccept = () => {
        props.onAccept(name, type)
    }

    const onClose = () => {
        props.onClose()
    }

    const mode = props.columnName ? "Edit" : "Add"
    return (
        <Dialog open={props.open} onClose={onClose}>
            <DialogTitle>{mode} Column</DialogTitle>
            <DialogContent>
                <TextField
                    id="with-placeholder"
                    label="Column Name"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        id: "add-column-name",
                        autoFocus: true,
                    }}
                    style={{ marginBottom: "10px" }}
                    fullWidth
                    onChange={handleNameChange}
                    value={name}
                />
                <FormControl fullWidth>
                    <InputLabel
                        shrink
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        Column Type
                    </InputLabel>
                    <Select value={type} onChange={handleTypeChange}>
                        <MenuItem value="str">String</MenuItem>
                        <MenuItem value="int">Integer</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="float">Real</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccept}>Accept</Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

AddColumn.defaultProps = {
    columnName: "",
    columnType: "",
}

export default AddColumn
