// @flow
import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Theme from "@owsi/catena/er2_styles"
import {
    getLayers,
    getVectorLayers,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import Snackbar from "@material-ui/core/Snackbar"
import { windowStates } from "@owsi/catena/er2_map_userlayers/js/components/window_state"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import type { ServicesType } from "@owsi/catena/er2_map_services/js/reducers/services"
import * as ids from "../constants/ids"

type PropsType = {
    actions: {
        runLamps: Function,
        showLampsResults: Function,
    },
    appendToLogger: Function,
    mapsources: MapsourcesType,
    runLamps: Function,
    services: ServicesType,
    theme: Theme,
    token: {
        value: string,
    },
}

function Lamps(props: PropsType) {
    const [lampsLayer, setLampsLayer] = useState("")
    const [resultRendered, setResultRendered] = useState(false)

    const tool = props.services.items.find((i) => i.id === ids.DATA_LAMPS)

    function getLampsLayer() {
        return getLayers(props.mapsources).find((l) => l.name === lampsLayer)
    }

    function hasResults() {
        const layer = getLampsLayer()
        if (layer) {
            const attrs = Object.keys(layer.schema.properties).filter(
                (k) => k.indexOf("dcrop") === 0,
            )
            return attrs.length > 0
        }
        return false
    }

    function showResults() {
        const layer = getLampsLayer()
        if (layer) {
            if (hasResults()) {
                props.actions.closeServiceResults(ids.DATA_LAMPS)
                props.actions.showLampsResults(layer)
                if (props.tabbedPanel.windowState === windowStates.minimized) {
                    props.actions.setTabbedPanelWindowState(windowStates.opened)
                }
            }
        }
    }

    const execute = () => {
        const layer = getLampsLayer()
        props.actions.runLamps(layer)
        setResultRendered(false)
    }

    const zoomToLayer = () => {
        const layer = getLampsLayer()
        props.actions.onUserLayersZoom([layer])
    }

    function renderButtons() {
        return (
            <div className={props.theme.toolRow}>
                <Button
                    color={"primary"}
                    disabled={props.services.running || !lampsLayer}
                    onClick={execute}
                    variant={"contained"}
                >
                    {!props.services.running && <span>Run LAMPS</span>}
                    {props.services.running && (
                        <span>
                            Running LAMPS&nbsp;
                            <i className="fas fa-spinner fa-spin" />
                        </span>
                    )}
                </Button>
                <Button
                    color={"primary"}
                    disabled={props.services.running || !hasResults()}
                    onClick={showResults}
                    variant={"contained"}
                >
                    {!props.services.running && <span>See Results</span>}
                </Button>
            </div>
        )
    }

    function renderTool() {
        const layers = getVectorLayers(props.mapsources, "Polygon").filter(
            (l) => l.row_count > 0,
        )
        return (
            <div className={props.theme.toolRow}>
                <FormControl fullWidth>
                    <InputLabel htmlFor={"lamps_layer"}>Field Layer</InputLabel>
                    <Select
                        inputProps={{
                            name: "lamps_layer",
                            id: "lamps_layer",
                        }}
                        onChange={(evt) => setLampsLayer(evt.target.value)}
                        value={lampsLayer}
                    >
                        {layers.map((layer) => (
                            <MenuItem key={layer.name} value={layer.name}>
                                {layer.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Fab
                    disabled={!lampsLayer}
                    onClick={zoomToLayer}
                    size={"small"}
                >
                    <i className={"ms ms-zoom-to-extent"} />
                </Fab>
            </div>
        )
    }

    function renderNotifications() {
        if (!resultRendered && props.services.result) {
            let message = `Result saved to ${lampsLayer}`
            if (props.services.result.exception) {
                message = "LAMPS generated an error"
            }
            return (
                <div>
                    <Snackbar
                        open={resultRendered === false}
                        autoHideDuration={6000}
                        onClose={() => setResultRendered(true)}
                        message={<span>{message}</span>}
                    />
                </div>
            )
        }
        return null
    }

    return (
        <div className={props.theme.geoBarCtr}>
            {renderTool()}
            {renderButtons()}
            {renderNotifications(props.theme)}
        </div>
    )
}

export default Lamps
