// @flow
import * as React from "react"
import { withRouter } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCog, faGlobe, faTools } from "@fortawesome/free-solid-svg-icons"
import MapToolbarStyles from "@owsi/catena//er2_styles/map_toolbar"
import MapToolbarItem from "./map_toolbar_item"
import type GeoBarType from "./geo_bar"

library.add(faCog, faGlobe, faTools)

type Props = {
    geoBar: GeoBarType,
    geoBarIsActive?: Function,
    mapToolbar: {
        items: Array<{
            active: boolean,
            id: string,
            name: string,
        }>,
        isContracted: boolean,
        panel: { visible: boolean },
    },
    theme: MapToolbarStyles,
    onClickMapToolbarItem: Function,
    onClickGeoBarItem: Function,
}

function generateClassNames(props) {
    // Use the given theme if provided (don't try to merge,
    // since there's no guarantee the styles were build with Aphrodite)
    return `${props.theme.mapToolbar}
        ${props.mapToolbar.isContracted ? props.theme.contracted : ""}
        ${props.mapToolbar.panel.visible ? props.theme.noBoxShadow : ""}`
}

function MapToolbar(props: Props) {
    const renderedTheme = props.theme
    const classNames = generateClassNames(props)
    const activeIcon = props.mapToolbar.items.find((item) => item.active)
    const showActiveIcon = false

    const isIconClass = (str) =>
        str &&
        typeof str === "string" &&
        ["fa", "ms"].includes(str.substr(0, 2))

    const style = {}
    if (showActiveIcon) {
        style.gridRowStart = "subHeader"
    }

    return (
        <div className={classNames} style={style}>
            <div className={renderedTheme.mapToolbarTop}>
                {showActiveIcon && activeIcon && (
                    <div className={renderedTheme.mapToolbarSpacer}>
                        {showActiveIcon && (
                            <div className={renderedTheme.iconCtr}>
                                {activeIcon.content ? (
                                    activeIcon.content(renderedTheme)
                                ) : isIconClass(activeIcon.icon.default) ? (
                                    <i
                                        className={activeIcon.icon.default}
                                        aria-hidden="true"
                                        title={activeIcon.name}
                                    />
                                ) : (
                                    activeIcon.icon.default
                                )}
                            </div>
                        )}
                    </div>
                )}
                {showActiveIcon && !activeIcon && (
                    <div className={renderedTheme.mapToolbarSpacer}>
                        <div className={renderedTheme.iconCtr}>
                            {
                                <FontAwesomeIcon
                                    icon={faGlobe}
                                    aria-hidden="true"
                                    title="@owsi/catena/"
                                />
                            }
                        </div>
                    </div>
                )}
                {props.mapToolbar.items.map((item, idx) =>
                    item.icon ? (
                        <MapToolbarItem
                            key={item.id}
                            idx={idx}
                            item={item}
                            // Inject the theme to child components if provided
                            theme={renderedTheme}
                            onClick={props.onClickMapToolbarItem}
                        />
                    ) : null,
                )}
            </div>
        </div>
    )
}

MapToolbar.defaultProps = {
    geoBarIsActive: false,
}

export default withRouter(MapToolbar)
