// @flow
export { default as attributeTables } from "./attribute_tables"
export { default as identify } from "./identify"
export { default as map } from "./map"
export { default as mapBaseLayer } from "./mapbaselayer"
export { default as mapsources } from "./mapsources"
export { default as logger } from "./logger"
export { default as publicSites } from "./public_sites"
export { default as tabbedPanel } from "./tabbed_panel"
export { default as theme } from "./theme"

// Standard catena actions
export type ActionsType = {
    clearMapError: Function,
    fetchState: Function,
    getPublicSites: Function,
    onAddTimeseriesSource: Function,
    onClickFormBreadcrumb: Function,
    onClickContextBarItem: Function,
    onClickInlineHelp: Function,
    onChangeBaseLayer: Function,
    onChangeMapScale: Function,
    onChangeMapToolbarOpacity: Function,
    onChangeResolution: Function,
    onChangeResolutionUnit: Function,
    onCreateLayer: Function,
    onHideAttributeTable: Function,
    onLogout: Function,
    onResizeAnalysisPanel: Function,
    onSharingSetPushMapView: Function,
    onSharingSetLayersSharable: Function,
    onUserLayersAdd: Function,
    onUserLayerDelete: Function,
    onUserLayersDelete: Function,
    onUserLayerDownload: Function,
    onUserLayersPropsChange: Function,
    onUserLayersSort: Function,
    onUserLayersZoom: Function,
    onResizeAnalysisPanel: Function,
    setMapError: Function,
    setTabbedPanelIndex: Function,
    setTabbedPanelWindowState: Function,
    showMapToolbar: Function,
    toggleLoggerVisibility: Function,
    userLayerSelectFeatures: Function,
    userMapfileHandled: Function,
}

export type HistoryType = {
    action: string,
    block: Function,
    length: number,
    push: Function,
    replace: Function,
    go: Function,
    goBack: Function,
    goForward: Function,
}

export type LocationType = {
    pathname: string,
    search?: string,
    hash?: string,
}
