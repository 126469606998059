// @flow
import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"

type PropsType = {
    onAccept: Function,
    onClose: Function,
    open: boolean,
    schema: {
        properties: {},
    },
}

type StateType = {
    datum: string,
    hemisphere: string,
    system: string,
    tabIndex: number,
    x: string,
    y: string,
    xyOptions: {},
    zone: string,
}

function getEPSG(datum, coordsys, zone, hemisphere) {
    const zoneint = parseInt(zone)
    if (coordsys === "LatLng") {
        if (datum === "WGS84") return "4326"
        else if (datum === "NAD83") return "4267"
        else if (datum === "NAD27") return "4269"
    } else if (
        datum === "NAD27" &&
        hemisphere === "N" &&
        zoneint >= 3 &&
        zoneint <= 23
    ) {
        return 26703 + zoneint + -3
    } else if (
        datum === "NAD83" &&
        hemisphere === "N" &&
        zoneint >= 3 &&
        zoneint <= 23
    ) {
        return 26903 + zoneint + -3
    } else if (datum === "WGS84" && hemisphere === "N") {
        return 32600 + zoneint
    } else if (datum === "WGS84" && hemisphere === "S") {
        return 32700 + zoneint
    }
    return "not supported"
}

class CreateLayer extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props)

        const xyOptions = Object.keys(this.props.schema.properties).filter(
            (k) => {
                const val = this.props.schema.properties[k].toLowerCase()
                return val.startsWith("int") || val.startsWith("float")
            },
        )

        this.state = {
            datum: "WGS84",
            hemisphere: "north",
            system: "latlng",
            tabIndex: 0,
            x: xyOptions[0],
            y: xyOptions[0],
            xyOptions,
            zone: 1,
        }
    }

    handleDatumChange = (e) => {
        this.setState({
            datum: e.target.value,
        })
    }

    handleSystemChange = (e) => {
        this.setState({
            system: e.target.value,
        })
    }

    handleXChange = (e) => {
        this.setState({
            x: e.target.value,
        })
    }

    handleYChange = (e) => {
        this.setState({
            y: e.target.value,
        })
    }

    handleZoneChange = (e) => {
        this.setState({
            zone: e.target.value,
        })
    }

    handleHemisphereChange = (e) => {
        this.setState({
            hemisphere: e.target.value,
        })
    }

    handleStateChange = (attr) => (e) => {
        this.setState({ [`geocode_${attr}`]: e.target.value })
    }

    handleTabChange = (e, value) => {
        this.setState({ tabIndex: value })
    }

    createLayer = () => {
        const mode = this.state.tabIndex === 0 ? "coords" : "geocoder"
        const { datum, system, zone, hemisphere } = this.state
        const epsg = getEPSG(datum, system, zone, hemisphere)
        this.props.onAccept(mode, { epsg, ...this.state })
    }

    renderCreateFromLL() {
        const xyMenu = this.state.xyOptions.map((k) => (
            <MenuItem key={k} value={k}>
                {k}
            </MenuItem>
        ))

        const zoneOptions = []
        for (let i = 1; i <= 60; i += 1) {
            zoneOptions.push(
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>,
            )
        }

        return (
            <div>
                <FormControl fullWidth>
                    <InputLabel
                        shrink
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        Datum
                    </InputLabel>
                    <Select
                        value={this.state.datum}
                        onChange={this.handleDatumChange}
                    >
                        <MenuItem value="WGS84">WGS84</MenuItem>
                        <MenuItem value="NAD27">NAD27</MenuItem>
                        <MenuItem value="NAD83">NAD83</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        shrink
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        Coordinate System
                    </InputLabel>
                    <Select
                        value={this.state.system}
                        onChange={this.handleSystemChange}
                    >
                        <MenuItem value="latlng">LatLng</MenuItem>
                        <MenuItem value="UTM">UTM</MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    style={
                        this.state.system === "UTM"
                            ? { width: "50%" }
                            : { display: "none" }
                    }
                >
                    <InputLabel
                        shrink
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        Zone
                    </InputLabel>
                    <Select
                        value={this.state.zone}
                        onChange={this.handleZoneChange}
                    >
                        {zoneOptions}
                    </Select>
                </FormControl>
                <FormControl
                    style={
                        this.state.system === "UTM"
                            ? { width: "50%" }
                            : { display: "none" }
                    }
                >
                    <InputLabel
                        shrink
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        Hemisphere
                    </InputLabel>
                    <Select
                        value={this.state.hemisphere}
                        onChange={this.handleHemisphereChange}
                    >
                        <MenuItem value="north">North</MenuItem>
                        <MenuItem value="south">South</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        X
                    </InputLabel>
                    <Select value={this.state.x} onChange={this.handleXChange}>
                        {xyMenu}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        Y
                    </InputLabel>
                    <Select value={this.state.y} onChange={this.handleYChange}>
                        {xyMenu}
                    </Select>
                </FormControl>
            </div>
        )
    }

    renderGeocoder() {
        const attrs = ["", ...Object.keys(this.props.schema.properties).sort()]
        const inputs = ["address1", "address2", "city", "state", "zipcode"]
        const titleCase = (input) => input[0].toUpperCase() + input.slice(1)
        return (
            <div>
                {inputs.map((input) => (
                    <FormControl fullWidth key={input}>
                        <InputLabel htmlFor={input}>
                            {titleCase(input)}
                        </InputLabel>
                        <Select
                            inputProps={{ id: input }}
                            value={this.state[`geocode_${input}`] || ""}
                            onChange={this.handleStateChange(input)}
                        >
                            {attrs.map((a) => (
                                <MenuItem key={a} value={a}>
                                    {a}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ))}
            </div>
        )
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogContent>
                    <AppBar position="static" color="default">
                        <Tabs
                            color="primary"
                            value={this.state.tabIndex}
                            onChange={this.handleTabChange}
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Coordinates" />
                            <Tab label="Geocode" />
                        </Tabs>
                    </AppBar>
                    {this.state.tabIndex === 0 && this.renderCreateFromLL()}
                    {this.state.tabIndex === 1 && this.renderGeocoder()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.createLayer}>Accept</Button>
                    <Button onClick={this.props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default CreateLayer
