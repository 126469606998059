// @flow
import { createReducer } from "@reduxjs/toolkit"
import * as wsActionTypes from "@owsi/catena/er2_sharing/js/constants/action_types"
import type { LayerExtentType } from "@owsi/catena/er2_map_userlayers/js/reducers/map"
import * as grouping from "../mapsources_grouping"
import * as actionTypes from "../constants/action_types"

export type StatisticsType = {
    [key: string]: {
        min: number,
        max: number,
    },
}

export const SINGLE_SYMBOL = "Single Symbol"
export const NATURAL_BREAKS = "Natural Breaks"
export const EQUAL_DISTANCE = "Equal Distance"
export const UNIQUE_VALUES = "Unique Values"

export type ClassificationType = {
    field: string,
    classify_type: string,
    max_color: string,
    min_color: string,
    nclass: number,
    random_colors: boolean,
}

export type GeoJSONFeatureType = {
    type: "Feature",
    properties: {
        // Selected management name
        management: string,
    },
    geometry: {
        coordinates: [],
        type: string,
    },
    id: number,
}

export type GeoJSONType = {
    type: string,
    features: [GeoJSONFeatureType],
}

export type GroupingType = "none" | "layerType"

export type OrderingType = "none" | "asc" | "desc"

export type ActionsType = {
    getUserLayerClassifications: Function,
    getPublicServices: Function,
    onUserLayersAdd: Function,
    onUserLayerDelete: Function,
    onUserLayerGetAttributes: Function,
    onUserLayersPropsChange: Function,
    onUserLayersSort: Function,
    onUserLayersZoom: Function,
}

export type UserLayerClassificationType = {
    bordercolor?: string,
    borderopacity: number,
    bordersize: number,
    color: string,
    expression: string,
    hatchsize: string,
    iconpath?: string,
    label: string,
    opacity: number,
    outlinecolor: string,
    rangemax?: number,
    rangemin?: number,
    size: number,
    // Icon or pattern
    symbol: string,
    // Icon type of pattern type
    symbolType: string,
}

export type SymbolType = {
    color: string,
    expression: string,
    label: string,
}

export type FieldFormatType = "default" | "currency" | "thousands"

export type ViewType = {
    classifications: SymbolType[],
    classify_type: string,
    colorscale: string[],
    field: string,
    format_type: FieldFormatType,
    max_color: string,
    min_color: string,
    name: string,
    nclass: number,
    random_colors: boolean,
}

export type ViewListType = { [string]: ViewType }

export type UserMetadataType = {
    published: Boolean,
}

export type UserLayerType = {
    author: string,
    crs: { init: string },
    displayName: string,
    current_view_name: string,
    expanded: boolean,
    extent: LayerExtentType,
    filepath: string,
    layer_type: string,
    name: string,
    opacity: number,
    proj4: string,
    row_count: number,
    schema: {
        geometry: string,
        properties: {},
    },
    tags: [],
    userMetadata: UserMetadataType,
    view_list: ViewListType,
    visible: boolean,
}

export type UserTableType = {
    author: string,
    expanded: boolean,
    filepath: string,
    name: string,
    row_count: number,
    tags: [],
    userMetadata: {},
}

export type UserMapsourceType = {
    // Corresponds to a single collection of layers represented by a single mapfile.
    layers: UserLayerType[],
    label: string,
    mapfile: string,
    name: string,
    tags: string[],
    type: string,
}

type MapsourceActionType = {
    type: string,
    id: string,
    layerName?: string,
    layerNames?: string[],
    mapsources?: Array<UserMapsourceType>,
    mapsourceName?: string,
    newProps?: any,
    ordering?: Array<UserMapsourceType>,
    state?: any,
    stats?: StatisticsType,
    userlayerProps?: UserLayerType,
}

export type MapsourcesType = {
    adding: boolean,
    apiKeys: {},
    // True if the default mapsource has been altered. Currently only used for mapserver
    fetchingLayers: {},
    filters: string[],
    grouping: GroupingType,
    groups: [],
    mapfile: string,
    mapsources: UserMapsourceType[],
    ordering: OrderingType,
}

export function getDataType(schema, field) {
    const props = schema.properties
    let ret
    if (props[field].includes("str")) {
        ret = "string"
    } else if (props[field].includes("float")) {
        ret = "float"
    } else if (props[field].includes("int")) {
        ret = "int"
    } else if (props[field].includes("date")) {
        ret = "date"
    } else {
        console.error(`Unhandled field type ${props[field]}`)
    }
    return ret
}

function getNewGrouping(state: MapsourcesType) {
    const groups = grouping.getGrouping(
        state.grouping,
        state.ordering,
        state.mapsources,
    )
    const filteredGroups = grouping.filterGroups(groups, state.filters)
    return filteredGroups
}

const initialState = {
    adding: false,
    fetchingLayers: {},
    filters: [],
    grouping: "",
    groups: [],
    mapsources: [],
    ordering: "none",
}

/**
 * Return new mapsource and mutate the layer so it can be directly modified
 * @param state
 * @param action
 * @returns {{newstate: {baseLayer: string, extent?: ExtentType, mapView: ViewType, mapsources: Array<UserMapsourceType>}, imapsource: number, mapsource: {layers: UserLayerType[], mapfile: string, label: string, name: string}, ilayer: number, layer: *}}
 */
const getLayerForMapsource = (
    state: MapsourcesType,
    mapsourceName: string,
    layerName: string,
) => {
    const imapsource = state.mapsources.findIndex(
        (f) => f.name === mapsourceName,
    )
    const mapsource = state.mapsources[imapsource]
    // Locate the layer
    const ilayer = mapsource.layers.findIndex((l) => l.name === layerName)
    const layer = { ...mapsource.layers[ilayer] }
    return layer
}

function addMapsourceToLayers(mapsourceList: UserMapsourceType[]) {
    mapsourceList.forEach((m) => {
        m.layers.forEach((l) => {
            l.mapsource = m.name
        })
    })
}

function updateLayer(state, layerToUpdate, newProps) {
    const mapsource = state.mapsources.find((m) =>
        m.layers.some((l) => l.filepath === layerToUpdate.filepath),
    )
    if (mapsource) {
        if (mapsource.type === "arcgisrest") {
            Object.assign(mapsource, newProps)
        } else {
            // I would think this should work since the references should be the same but some layers don't match.
            // const ilayer = mapsource.layers.indexOf(layerToUpdate)
            const ilayer = mapsource.layers.findIndex(
                (l) => l.name === layerToUpdate.name,
            )

            if (ilayer >= 0) {
                const layer = mapsource.layers[ilayer]
                Object.assign(layer, newProps)
            } else {
                console.error(`No layer found for ${layerToUpdate.name}`)
            }
        }
        // Group object has a copy to the new layers, so regenerate them separately
        state.groups = grouping.getGrouping(
            state.grouping || "",
            state.ordering || "none",
            state.mapsources,
        )
    }
}

export function getMapsourceLayerName(mapsource: UserMapsourceType) {
    if (mapsource.type === "arcgisrest") {
        return `${mapsource.site_name}-${mapsource.service_name}`
    } else if (mapsource.mapfile) {
        return mapsource.mapfile
    }
    return null
}

function getMapsourceAndLayerIndex(thestate, mapsourceName, layerName) {
    const imap = thestate.mapsources.findIndex((f) => f.name === mapsourceName)
    const themapsource = thestate.mapsources[imap]
    // Locate the layer
    const ilayer = themapsource.layers.findIndex((l) => l.name === layerName)
    return { mapsource: themapsource, ilayer }
}

function deleteLayer(state, mapsourceName, layerName) {
    // There may be multiple layers with the same name, so loop until there are none.
    let r = getMapsourceAndLayerIndex(state, mapsourceName, layerName)
    do {
        r.mapsource.layers.splice(r.ilayer, 1)
        r.mapsource.groups = grouping.getGrouping(
            state.grouping,
            state.ordering || "none",
            state.mapsources,
        )
        r = getMapsourceAndLayerIndex(state, mapsourceName, layerName)
    } while (r.ilayer >= 0)
}

const mapsources = createReducer((initialState: MapsourcesType), {
    [actionTypes.UPDATE_MAP_STATE]: (state, action) => {
        state.apiKeys = action.state.map.apiKeys
        state.mapsources = action.state.mapsources
        state.groups = grouping.getGrouping(
            action.state.mapsources.grouping || "",
            action.state.ordering || "none",
            action.state.mapsources,
        )
        addMapsourceToLayers(state.mapsources)
    },
    [actionTypes.PUBLIC_DATA_DELETE]: (state, action) => {
        // Public service definitions are stored in public_sites reducer, but service mapsource props are stored here.
        const mapsourceName = action.mapsource.name
        const imapsource = state.mapsources.findIndex(
            (f) => f.name === mapsourceName,
        )
        state.mapsources.splice(imapsource, 1)
        state.groups = grouping.getGrouping(
            state.mapsources.grouping || "",
            state.ordering || "none",
            state.mapsources,
        )
    },
    [actionTypes.PUBLIC_DATA_PROPS_CHANGE]: (state, action) => {
        // Public service definitions are stored in public_sites reducer, but service mapsource props are stored here.
        const mapsourceName = getMapsourceLayerName({
            site_name: action.siteName,
            service_name: action.serviceName,
            type: "arcgisrest",
        }).replace("/", "__")
        const imapsource = state.mapsources.findIndex(
            (f) => f.name === mapsourceName,
        )
        state.mapsources[imapsource] = Object.assign(
            state.mapsources[imapsource],
            action.serviceProps,
        )
        state.groups = grouping.getGrouping(
            state.mapsources.grouping || "",
            state.ordering || "none",
            state.mapsources,
        )
    },
    [actionTypes.SET_FILTERS]: (state, action) => {
        // Update map ordering based on new grouping
        state.filters = action.filters
        state.groups = getNewGrouping(state)
    },
    [actionTypes.SET_GROUPING]: (state, action) => {
        // Update map ordering based on new grouping
        state.grouping = action.grouping
        state.groups = getNewGrouping(state)
    },
    [actionTypes.SET_GROUP_ORDERING]: (state, action) => {
        // Update map ordering based on new grouping
        state.ordering = action.ordering
        state.groups = getNewGrouping(state)
        state.mapsources = grouping.reorderMapsourcesFromGroups(
            state.groups,
            state.mapsources,
        )
    },
    [actionTypes.SET_LAYER_ORDERING]: (state, action) => {
        // User has changed the order in a group. Reshuffle all the mapsources.
        const newOrder = grouping.reorderMapsourcesFromLayers(
            action.groupName,
            action.layers,
            state.groups,
            state.mapsources,
        )
        state.groups = newOrder.groups
        state.mapsources = newOrder.mapsources
    },
    [actionTypes.UPDATE_FIELD_STATS]: (state, action) => {
        const layer = getLayerForMapsource(
            state,
            action.mapsourceName,
            action.layerName,
        )
        Object.assign(layer.statistics, action.stats)
    },
    [actionTypes.UPDATE_MAPSOURCE_LAYERS]: (state, action) => {
        const mapsource = state.mapsources.find(
            (m) => m.name === action.mapsourceName,
        )
        let layerNames = action.layerNames
        if (!Array.isArray(action.layerNames)) {
            layerNames = [action.layerNames]
        }
        mapsource.layers = layerNames.map((layerName) => {
            const layer = getLayerForMapsource(
                state,
                action.mapsourceName,
                layerName,
            )
            // Update layer state
            Object.assign(layer, action.newProps)
            return layer
        })
        addMapsourceToLayers(state.mapsources)
    },
    [actionTypes.UPDATE_MAPSOURCE_ORDER]: (state, action) => {
        // Reorder the mapsources to match the order
        const mapsourcesFromNames = action.mapsourceNames.map((name) =>
            state.mapsources.find((m) => m.name === name),
        )
        const newOrder = {}
        // Only mapserver mapsources have layers that can be sorted.
        action.mapsourceNames.forEach((name) => (newOrder[name] = []))
        action.ordering.forEach((o) => {
            const msource = mapsourcesFromNames.find(
                (m) => m.name === o.mapsource,
            )
            if (msource.type === "mapserver") {
                const mapserverLayer = msource.layers.find(
                    (l) => l.name === o.name,
                )
                newOrder[o.mapsource].push(mapserverLayer)
            }
        })
        mapsourcesFromNames.forEach((m) => {
            if (m.type === "mapserver") {
                m.layers = newOrder[m.name]
            }
        })
        state.mapsources = mapsourcesFromNames
    },
    [actionTypes.USER_LAYER_DELETE]: (state, action) => {
        deleteLayer(state, action.mapsourceName, action.layerName)
    },
    [actionTypes.USER_LAYERS_DELETE]: (state, action) => {
        action.layerNames.forEach((layerName) => {
            deleteLayer(state, action.mapsourceName, layerName)
        })
        state.groups = grouping.getGrouping(
            state.grouping,
            state.ordering || "none",
            state.mapsources,
        )
    },
    [actionTypes.USER_LAYERS_ADDING]: (state, action) => {
        state.adding = action.adding
        addMapsourceToLayers(state.mapsources)
    },
    [actionTypes.USER_LAYERS_SET]: (state, action) => {
        state.mapsources = action.mapsources
        state.groups = grouping.getGrouping(
            state.grouping,
            state.ordering || "none",
            action.mapsources,
        )
        addMapsourceToLayers(state.mapsources)
    },
    [actionTypes.USER_LAYERS_SORT]: (state, action) => {
        const mapsource = state.mapsources.find(
            (f) => f.name === action.mapsourceName,
        )
        // rearrange the layers
        mapsource.layers = action.layerNames.map((n) =>
            mapsource.layers.find((l) => l.name === n),
        )
    },
    [actionTypes.USER_LAYER_UPDATE]: (state, action) => {
        // User is updating a single layer
        updateLayer(state, action.layer, action.newProps)
    },
    [actionTypes.USER_LAYERS_UPDATE]: (state, action) => {
        action.layers.forEach((l, i) => {
            updateLayer(state, l, action.newProps[i])
        })
    },
    [actionTypes.USER_LAYERS_BATCH_UPDATE]: (state, action) => {
        action.layerdata.forEach(([l, props]) => updateLayer(state, l, props))
    },
    [actionTypes.USER_LAYER_FETCHING_GEOJSON]: (state, action) => {
        state.fetchingLayers[action.layer.name] = action.fetching
    },
    [actionTypes.USER_LAYER_SET_GEOJSON]: (state, action) => {
        // Find the mapsource that contains this layer. I'm not using a check for layer by reference because the layers arra  may have changed.
        const mapsource = state.mapsources.find((m) =>
            m.layers.some((l) => l.metapath === action.layer.metapath),
        )
        const layer = mapsource.layers.find((l) => l.name === action.layer.name)
        layer.geojson = action.geojson
        layer.geojson.features.forEach((f) => {
            f.properties._layer = action.layer.name
        })
    },
    [actionTypes.USER_LAYERS_ZOOM]: (state, action) => {
        if (action.layerNames.length > 1) {
            console.warn("Only zooming to first layer of list")
        }
        const mapsource = state.mapsources.find(
            (f) => f.name === action.mapsourceName,
        )
        // Locate the layer
        const layer = mapsource.layers.find(
            (l) => l.name === action.layerNames[0],
        )
        state.extent = layer.bbox_3857
    },
    [wsActionTypes.SET_SHARING_LAYERS_SHARABLE]: (state, action) => {
        // Add sharable to all layers owned by the user
        const mapsource = state.mapsources.find((f) => f.name === "default")
        mapsource.layers.forEach((l) => {
            if (!l.sharing) {
                l.sharing = {
                    shared: false,
                }
            }
            l.sharing.enabled = action.sharable
        })
    },
})

export default mapsources
