import mapToolbarStyles from "./map_toolbar"

const rawStyles = {
    geoBar: { ...mapToolbarStyles.mapToolbar },
    geoBarCtr: {
        flexBasis: "0",
        flexGrow: "1",
    },
    geoBarBottom: { ...mapToolbarStyles.mapToolbarBottom },
    geoBarTop: { ...mapToolbarStyles.mapToolbarTop },
}

export default rawStyles
