// Global styles, usually merged into other styles objects
import * as colors from "./colors"

const rawStyles = {
    removeFocus: {
        ":focus": {
            outline: "0",
        },
    },
    button: {
        borderWidth: "0",
        borderRadius: "2px",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        cursor: "pointer",
        fontFamily: "'Roboto Condensed', sans-serif",
        paddingBottom: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "5px",
    },
    input: {
        ":focus": {
            /* sizes for the 2 images (focus state) */
            backgroundSize: "100% 2px, 100% 1px",
            caretColor: colors.primaryTwo,
            outline: "none",
        },
        ":invalid": {
            boxShadow: "none !important",
        },
        ":moz-placeholder": {
            boxShadow: "none !important",
        },
        background: `${colors.white} no-repeat`,
        /* 2 imgs : 1px gray line (normal state) AND 2px primaryOne line (focus state) */
        backgroundImage: `linear-gradient(to bottom, ${colors.primaryTwo}, ${colors.primaryTwo}),
            linear-gradient(to bottom, ${colors.gray}, ${colors.gray})`,
        /* sizes for the 2 images (default state) */
        backgroundSize: "0 2px, 100% 1px",
        /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
        backgroundPosition: "50% 100%, 50% 100%",
        border: "0",
        color: colors.textColor,
        padding: "5px",
        /* animation solely on background-size */
        transition: "background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1)",
    },
    label: {
        padding: "5px",
    },
    select: {
        ":focus": {
            /* sizes for the 2 images (focus state) */
            backgroundSize: "100% 2px, 100% 1px",
            caretColor: colors.primaryTwo,
            outline: "none",
        },
        background: `${colors.white} no-repeat`,
        /* 2 imgs : 1px gray line (normal state) AND 2px primaryOne line (focus state) */
        backgroundImage: `linear-gradient(to bottom, ${colors.primaryTwo}, ${colors.primaryTwo}),
            linear-gradient(to bottom, ${colors.gray}, ${colors.gray})`,
        /* sizes for the 2 images (default state) */
        backgroundSize: "0 2px, 100% 1px",
        /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
        backgroundPosition: "50% 100%, 50% 100%",
        border: "0",
        color: colors.textColor,
        /* animation solely on background-size */
        transition: "background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1)",
    },
    textArea: {
        ":focus": {
            /* sizes for the 2 images (focus state) */
            backgroundSize: "100% 2px, 100% 1px",
            caretColor: colors.primaryTwo,
            outline: "none",
        },
        background: `${colors.white} no-repeat`,
        /* 2 imgs : 1px gray line (normal state) AND 2px primaryOne line (focus state) */
        backgroundImage: `linear-gradient(to bottom, ${colors.primaryTwo}, ${colors.primaryTwo}),
            linear-gradient(to bottom, ${colors.gray}, ${colors.gray})`,
        /* sizes for the 2 images (default state) */
        backgroundSize: "0 2px, 100% 1px",
        /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
        backgroundPosition: "50% 100%, 50% 100%",
        border: "0",
        color: colors.textColor,
        padding: "5px",
        /* animation solely on background-size */
        transition: "background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1)",
    },
    truncatedText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}

export default rawStyles
