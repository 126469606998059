const rawStyles = {
    active: {
        "&:before": {
            content: '"\\F0D7"',
            color: (props) => props.success,
            fontFamily: "FontAwesome",
            fontSize: "18px",
            position: "absolute",
            width: "10px",
            top: "-6px",
            left: "50%",
            marginLeft: "-5px",
        },
        borderTop: (props) =>
            `3px solid ${props.colors?.primaryOne || props.primaryOne}`,
    },
    breadcrumb: {
        cursor: "pointer",
        display: "flex",
        height: "44px",
    },
    breadcrumbHeader: {
        backgroundColor: (props) =>
            props.colors?.secondaryOne || props.secondaryOne,
        color: "white",
        width: "100%",
    },
    breadcrumbs: {
        alignSelf: "flex-start",
        display: "flex",
        height: "44px",
        margin: "0",
        gridColumnEnd: "breadcrumbOverflow",
        gridColumnStart: "controlBar",
        gridRowStart: "subHeader",
        zIndex: "402",
    },
    breadcrumbTriangle: {
        height: "100%",
        position: "absolute",
        overflow: "visible",
        width: "90%",
    },
    breadcrumbTriangleCtr: {
        backgroundColor: "transparent",
        flexShrink: "0",
        overflow: "hidden",
        position: "relative",
        width: "18px",
        height: "44px",
    },
    breadcrumbTriangleFill: {
        fill: (props) => props.colors?.secondaryOne || props.secondaryOne,
    },
    headerTextContent: {
        alignItems: "center",
        backgroundColor: "transparent",
        color: (props) =>
            props.colors?.contrastTextColor || props.contrastTextColor,
        display: "flex",
        fontSize: "1.5em",
        fontWeight: "normal",
        margin: "10px",
    },
    item: {
        borderTop: (props) =>
            `3px solid ${
                props.colors?.primaryOneDarken5 || props.primaryOneDarken5
            }`,
        lineHeight: "18px",
        position: "relative",
    },
    itemTextContent: {
        fontSize: "14px",
        height: "21px",
        lineHeight: "21px",
        margin: "10px",
    },
}

export default rawStyles
