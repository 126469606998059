// @flow
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import * as React from "react"
import ReactDOM from "react-dom"
import Map from "ol/Map"
import View from "ol/View"
import { defaults as OlControlDefaults } from "ol/control"
import MousePosition from "ol/control/MousePosition"
import ScaleLine from "ol/control/ScaleLine"
import { createStringXY } from "ol/coordinate"
import Layer from "ol/layer/Layer"
import Tile from "ol/layer/Tile"
import TileWMS from "ol/source/TileWMS"
import Image from "ol/layer/Image"
import ImageWMS from "ol/source/ImageWMS"
import * as olEventsCondition from "ol/events/condition"
import * as olExtent from "ol/extent"
import OlInteractionDragBox from "ol/interaction/DragBox"
import Overlay from "ol/Overlay"
import * as Proj from "ol/proj"
import OlStyle from "ol/style/Style"
import OlStyleStroke from "ol/style/Stroke"
import MapBrowserEvent from "ol/MapBrowserEvent"
import Geocode from "react-geocode"
import "ol/ol.css"
import LayerAnimation from "./layer_animation"
import type { ExtentType, ViewType } from "./map"

export type LayerParamsType = {
    opacity?: number,
    visible?: boolean,
    visibleLayerNames?: string[],
}

const INCHES_PER_UNIT = {
    m: 39.37,
    dd: 4374754,
}
const DOTS_PER_INCH = 72

class OlMap {
    layerAnimation: LayerAnimation
    olmap: Map

    // Properties of the map
    properties: {
        currentViewData?: ViewType,
        onIdentify: Function[],

        // TODO: extract this to its own class
        placemarks: string[],
        placemarksNextId: number,
    }

    constructor(el: string, mapProps) {
        const mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(4),
            projection: "EPSG:4326",
            // comment the following two lines to have the mouse position
            // be placed within the map.
            className: "custom-mouse-position",
            target: document.getElementById("mouse-position"),
            undefinedHTML: "&nbsp;",
        })

        const controls = OlControlDefaults({
            zoom: mapProps.hasZoom || false,
            attribution: false,
            rotate: false,
        })
        this.olmap = new Map({
            controls: controls.extend([mousePositionControl, new ScaleLine()]),
            target: el,
            view: new View({
                center: [-11000000, 5000000],
                zoom: 4,
            }),
        })

        this.properties = {
            navHistoryUndo: [],
            navHistoryRedo: [],
            ignoreMoveEnd: false,
            onIdentify: [],
            onNavigationHistoryChanged: [],
            placemarks: [],
            placemarksNextId: 0,
        }
    }

    get(key: string) {
        return this.properties[key]
    }

    set(key: string | Object, value: any) {
        if (typeof key === "object") {
            Object.assign(this.properties, key)
        } else if (typeof key === "string") {
            this.properties[key] = value
        }
    }

    addSingleClickEvent(cb: Function) {
        this.olmap.on("singleclick", (e) => {
            // Augment e with lat, lon
            e.lonlat = this.getLonLat(e.coordinate)
            cb(e)
        })
    }

    addViewChangedEvent(cb: Function) {
        this.olmap.on(["moveend"], (e) => {
            e.view = this.olmap.getView().getProperties()
            e.view.mapScale = this.getMapScale()
            // Make sure viewis valid
            if (e.view.mapScale) {
                // Extent values are off just a little each time the view is changed.
                e.view.extent = this.getExtent().map((ex) => parseInt(ex))
                cb(e)
            }
        })
    }

    addNavigationHistoryChangedEvent(cb: Function) {
        this.properties.onNavigationHistoryChanged.push(cb)
    }

    simulateEvent(type, x, y, width, height, optShiftKey) {
        const viewport = this.olmap.getViewport()
        const position = viewport.getBoundingClientRect()
        const shiftKey = optShiftKey !== undefined ? optShiftKey : false
        const event = new PointerEvent(type, {
            clientX: position.left + x,
            clientY: position.top + y,
            shiftKey,
        })
        this.olmap.handleMapBrowserEvent(
            new MapBrowserEvent(type, this.olmap, event),
        )
    }

    hasBaseLayer() {
        const baselayer = this.olmap
            .getLayers()
            .getArray()
            .find((layer) => layer.get("isbaselayer"))
        return baselayer
    }

    setBaseLayer(baseLayer: Layer) {
        this.removeBaseLayers()
        if (baseLayer) {
            try {
                baseLayer.setProperties({ isbaselayer: true })
                this.olmap.getLayers().insertAt(0, baseLayer)
            } catch (e) {
                console.error(
                    "ol_map.js - setBaseLayer received ",
                    baseLayer,
                    ".\nUnable to setProperties",
                )
            }
        }
    }

    getLayerByName(layerLabel: string) {
        return this.olmap
            .getLayers()
            .getArray()
            .filter((l) => l.get("label") === layerLabel)[0]
    }

    removeBaseLayers() {
        this.olmap
            .getLayers()
            .getArray()
            .slice()
            .forEach((layer) => {
                if (layer.get("isbaselayer")) this.olmap.removeLayer(layer)
            })
    }

    removeLayer(layer: Layer) {
        this.olmap.removeLayer(layer)
    }

    setUserMapfile(userMapfile: string, params: LayerParamsType) {
        const layer = this.getLayerByName(userMapfile)
        if (layer) {
            this.refreshLayer(layer, params)
        } else {
            this.addMapserver(userMapfile, params, userMapfile)
        }
        return layer
    }

    getMapserverUrl() {
        let mapserv = "mapserv.fcgi"
        let url = `${window.location.origin}/cgi-bin/${mapserv}`
        // Check for django dev server, in which case mapserver requests just go to port 80
        let port = window.location.port
        if (window.location.port == 3000) {
            // create-react-app dev port, map to 8000
            port = 8000
        }
        if (port >= 8000) {
            mapserv = "mapserv"
            url = `${window.location.protocol}//${window.location.hostname}/cgi-bin/${mapserv}`
        }
        return url
    }

    /**
     * Add WMS mapserver layer
     * @param mapfile Path to the mapfile. Assumes mapserver is running on the same host.
     * @param params Parameters for the new layer.
     * @param name String to use for the layer's name
     * @returns {ol.layer.Image}
     */
    addMapserver(mapfile: string, params: LayerParamsType, name: string) {
        const mapserver = new Image({
            source: new ImageWMS({
                url: this.getMapserverUrl(),
                crossOrigin: "Anonymous",
                params: {
                    MAP: mapfile,
                    format: "image/png",
                    LAYERS: params.visibleLayerNames,
                },
                serverType: "mapserver",
            }),
            label: name,
            mapfile,
            name,
            visible: params.visible === undefined || params.visible,
            zIndex: 0,
        })

        this.refreshLayer(mapserver, params)

        this.olmap.addLayer(mapserver)
        return mapserver
    }

    addMapserverTile(mapfile: string, params: LayerParamsType, name: string) {
        const mapserver = new Tile({
            source: new TileWMS({
                url: this.getMapserverUrl(),
                crossOrigin: "Anonymous",
                params: {
                    MAP: mapfile,
                    format: "image/png",
                },
                serverType: "mapserver",
            }),
            label: name,
            visible: params.visible === undefined || params.visible,
            zIndex: 0,
        })

        this.refreshLayer(mapserver, params)

        this.olmap.addLayer(mapserver)
        return mapserver
    }

    /**
     * Redraw the layer if layer properties have changed
     * @param layer Openlayers layer
     * @param params Layer properties
     */
    refreshLayer(layer: Image, params: LayerParamsType) {
        // Mapserver requires that at least one layer is visible
        if (params.visibleLayerNames) {
            layer.setVisible(params.visibleLayerNames.length > 0)
            const source = layer.getSource()
            source.updateParams({
                LAYERS: params.visibleLayerNames,
                // Needed to force a refresh
                foo: Math.random(),
            })
        } else {
            // layer.setVisible(false)
        }
    }

    setView(view: ViewType) {
        this.olmap.getView().setCenter(view.center)
        this.olmap.getView().setResolution(view.resolution)
    }

    /**
     * Zoom to the given extent
     * @param extent
     * @param buffer
     */
    setExtent(extent: ExtentType, buffer: boolean = false) {
        if (extent) {
            if (extent.indexOf(null) < 0) {
                let ex = extent
                let bufferVal = buffer
                // Check for point
                if (extent[0] === extent[2] && !buffer) bufferVal = 200
                if (bufferVal) ex = olExtent.buffer(extent, bufferVal)
                this.olmap.getView().fit(ex, this.olmap.getSize())
            } else {
                console.error(`Bad extent: ${extent}`)
            }
        }
    }

    /**
     * @returns {array} [minx, miny, maxx, maxy]
     */
    getExtent() {
        return this.olmap.getView().calculateExtent(this.olmap.getSize())
    }

    reorderLayers(layers: Layer[]) {
        // Remove and add at end assuming that other layers should be on top.
        layers.reverse()
        layers.forEach((layer) => this.olmap.removeLayer(layer))
        layers.forEach((layer) => this.olmap.addLayer(layer))
    }

    /**
     * Set the map zoom level based on the requested scale.
     * @param mapScale 1:<mapScale>
     */
    setMapScale(mapScale: number) {
        // https://gis.stackexchange.com/questions/242424/how-to-get-map-units-to-find-current-scale-in-openlayers
        const res =
            mapScale /
            (INCHES_PER_UNIT[this.olmap.getView().getProjection().getUnits()] *
                DOTS_PER_INCH)
        this.setResolution(res)
    }

    getMapScale() {
        return Math.round(
            INCHES_PER_UNIT[this.olmap.getView().getProjection().getUnits()] *
                DOTS_PER_INCH *
                this.getResolution(),
        )
    }

    getResolution() {
        return this.olmap.getView().getResolution()
    }

    setResolution(resolution: number) {
        return this.olmap.getView().setResolution(resolution)
    }

    updateSize() {
        // Don't store any change in view
        this.properties.ignoreMoveEnd = true

        const center = this.olmap.getView().getCenter()
        this.olmap.updateSize()
        this.olmap.getView().setCenter(center)
    }

    zoomToCenterResolutionRotation(triple: ViewType) {
        this.olmap.getView().setProperties(triple)
    }

    zoomToAddress(addr) {
        Geocode.setApiKey("AIzaSyCsOooQMi8LoTtBWOkITIXt8lK-bovDcdw")
        Geocode.fromAddress(addr).then((response) => {
            const viewport = response.results[0].geometry.viewport
            // [minx, miny, maxx, maxy]
            let extent = [
                viewport.southwest.lng,
                viewport.southwest.lat,
                viewport.northeast.lng,
                viewport.northeast.lat,
            ]
            extent = Proj.transformExtent(extent, "EPSG:4326", "EPSG:3857")
            this.setExtent(extent)
        })
    }

    /**
     * Convert the map coordinate value to lon,lat
     * @param coordinate
     * @returns {ol.Coordinate}
     */
    getLonLat(coordinate: [number, number]) {
        /* @namespace result.entries */
        return Proj.transform(coordinate, "EPSG:3857", "EPSG:4326")
    }

    addOverlay(container: {}) {
        return new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        })
    }

    getOverlay(coords) {
        const overlay = new Overlay({
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            insertFirst: false,
            position: coords,
        })
        this.olmap.addOverlay(overlay)
        return overlay
    }

    removeOverlay(pmId: string) {
        const overlay = this.olmap.getOverlayById(pmId)
        this.olmap.removeOverlay(overlay)
    }

    removeOverlays() {
        this.olmap.getOverlays().clear()
    }

    /**
     * Set the interaction mode for map navigation, usually called from maptoolbar.
     * Assumption is that the map can only have one interaction at a time.
     * @param {string|ol.interaction} controlType Type of interaction to add.
     */
    setMapInteraction(controlType: string) {
        let interaction

        // Clear previous interaction
        this.removeInteraction()

        const mapcontainer = document.getElementById(this.olmap.get("target"))
        if (mapcontainer) {
            mapcontainer.style.cursor = "inherit"
        }

        if (controlType === "zoomIn") {
            // User draws a rectangle representing the new extent.
            interaction = new OlInteractionDragBox({
                condition: olEventsCondition.always,
                style: new OlStyle({
                    stroke: new OlStyleStroke({
                        color: [0, 0, 255, 1],
                    }),
                }),
            })

            // Map events
            interaction.on("boxend", () => {
                if (interaction) {
                    const extent = interaction.getGeometry().getExtent()
                    this.setExtent(extent)
                }
            })
        }

        if (interaction) {
            this.addInteraction(interaction)
        }
        return interaction
    }

    addInteraction(interaction: any) {
        interaction.set("name", "mapInteraction")
        this.olmap.addInteraction(interaction)
    }

    removeInteraction() {
        const interaction = this.olmap
            .getInteractions()
            .getArray()
            .find((i) => i.get("name") === "mapInteraction")
        if (interaction) {
            this.olmap.removeInteraction(interaction)
        }
    }

    /**
     * Add an overlay at the given map location with the given content.
     * @param coord
     * @param content
     * @param buttonEventsByClass Assign onclick and onchange events. Needed because reactdom.render will not
     *   keep the events and so need to be handled separately.
     * @param style
     * @returns {string}
     */
    addPlacemark(
        coord: [number, number],
        content: React.Element<*>,
        buttonEventsByClass: {} = {},
        style = {},
    ) {
        this.set("placemarksNextId", this.get("placemarksNextId") + 1)
        const pmId = `popup-${this.get("placemarksNextId")}`
        const pmDiv = document.createElement("div")
        pmDiv.className = "ol-popup ficus-popup"
        pmDiv.id = pmId

        const popup = new Overlay({
            id: pmId,
            element: pmDiv,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            insertFirst: false,
        })
        this.olmap.addOverlay(popup)
        popup.setPosition(coord)
        this.properties.placemarks.push(pmId)
        ReactDOM.render(content, popup.getElement())
        this.addButtonEventsForOverlay(pmId, buttonEventsByClass)
        return pmId
    }

    /**
     * Update content for placemark
     * @param {string} pmId
     * @param {jsx} content
     * @param {object} buttonEventsByClass Dictionary of class -> function
     */
    updatePlacemark(
        pmId: string,
        content: React.Element<*>,
        buttonEventsByClass: {},
    ) {
        const overlay = this.olmap.getOverlayById(pmId)
        if (overlay) {
            const pmDiv = overlay.get("element")
            ReactDOM.render(content, pmDiv)
            this.addButtonEventsForOverlay(pmId, buttonEventsByClass)
        } else {
            console.warn(
                `overlay ${pmId} no longer exists. There are ${
                    this.get("placemarks").length
                } placemarks currently.`,
            )
            console.warn(
                `There are currently ${this.olmap
                    .getOverlays()
                    .getLength()} overlays in the map`,
            )
        }
    }

    addPlacemarkUsingId(ref, coords: [number, number]) {
        const popup = new Overlay({
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            element: ref,
            id: ref.id,
            insertFirst: false,
            position: coords,
            positioning: "center-center",
            stopEvent: true,
        })
        this.olmap.addOverlay(popup)
    }

    /**
     * Change the popup ordering so that the given placemark appears on top
     * @param pmId
     */
    movePlacemarkToTop(pmId: string) {
        const overlay = this.olmap.getOverlayById(pmId)
        if (overlay) {
            this.olmap.removeOverlay(overlay)
            this.olmap.addOverlay(overlay)
        }
    }

    addButtonEventsForOverlay(pmId: string, buttonEventsByClass: {}) {
        const overlay = this.olmap.getOverlayById(pmId)
        const pmDiv = overlay.get("element")

        function checkEvent(e) {
            let el = e.target
            while (el !== pmDiv) {
                // Look for clicks on buttons
                el.classList.forEach((c) => {
                    if (buttonEventsByClass[c]) {
                        buttonEventsByClass[c](e)
                    }
                })
                el = el.parentNode
            }
        }

        pmDiv.onclick = (e) => {
            checkEvent(e)
            const dataButton = e.target.dataset.button
            if (dataButton && buttonEventsByClass[dataButton]) {
                buttonEventsByClass[dataButton]()
            }
            this.movePlacemarkToTop(pmId)
        }

        pmDiv.onchange = (e) => {
            checkEvent(e)
        }
    }

    zoomIn() {
        this.olmap.getView().setZoom(this.olmap.getView().getZoom() + 1)
    }

    zoomOut() {
        this.olmap.getView().setZoom(this.olmap.getView().getZoom() - 1)
    }

    /**
     * Pass no arguments to remove animation data
     * @param args
     */
    animate(...args: any[]) {
        if (args.length === 0) {
            if (this.layerAnimation) {
                this.layerAnimation.unload()
                this.layerAnimation = null
            }
        } else {
            if (!this.layerAnimation) {
                this.layerAnimation = new LayerAnimation(this)
            }
            this.layerAnimation.animate(...args)
        }
    }
}

export default OlMap
