export const GEO_BAR_HOME = "geoBar/home"
export const GEO_BAR_BOUNDARY_LAYER = "geoBar/boundaryLayer"
export const GEO_BAR_BUFFER = "geoBar/buffer"
export const GEO_BAR_BUFFER_DISTANCE = "geoBar/bufferDistance"
export const GEO_BAR_CALCULATOR = "geoBar/calculator"
export const GEO_BAR_CALCULATOR_EXPRESSION = "geoBar/calculatorExpression"
export const GEO_BAR_CLASSIFICATIONS = "geoBar/classifications"
export const GEO_BAR_CLIP = "geoBar/clip"
export const GEO_BAR_DIGITIZE_CREATE = "geoBar/digitizeCreate"
export const GEO_BAR_DIGITIZE_DRAW_PROMPT_RENDERED =
    "geoBar/digitizeDrawPromptRendered"
export const GEO_BAR_DIGITIZE_METHOD = "geoBar/digitizeMethod"
export const GEO_BAR_DIGITIZE_DRAWN_FEATURE = "geoBar/digitizeDrawnFeature"
export const GEO_BAR_DIGITIZE_DRAWN_FEATURE_NAME =
    "geoBar/digitizeDrawnFeatureName"
export const GEO_BAR_DIG_KNOWN_BOUNDARY = "geoBar/digitizeKnownBoundaries"
export const GEO_BAR_DIG_KNOWN_BOUNDARY_TYPE = "geoBar/digKnownBoundaryType"
export const GEO_BAR_DIG_KB_CITIES = "geoBar/digKnownBoundaryCities"
export const GEO_BAR_DIG_KB_HUC8 = "geoBar/digKnownBoundaryHuc8"
export const GEO_BAR_DIG_KB_HUC10 = "geoBar/digKnownBoundaryHuc10"
export const GEO_BAR_DIG_KB_HUC12 = "geoBar/digKnownBoundaryHuc12"
export const GEO_BAR_DIG_METHOD_DRAW_POINT = "geoBar/digMethodDrawPoint"
export const GEO_BAR_DIG_METHOD_DRAW_LINE = "geoBar/digMethodDrawLine"
export const GEO_BAR_DIG_METHOD_DRAW_POLYGON = "geoBar/digMethodDrawPolygon"
export const GEO_BAR_DIG_METHOD_DRAW_RECTANGLE = "geoBar/digMethodDrawRectangle"
export const GEO_BAR_DIG_METHOD_DRAW_CIRCLE = "geoBar/digMethodDrawCircle"
export const GEO_BAR_DIG_METHOD_KNOWN_BOUNDARY = "geoBar/digMethodKnownBoundary"
export const GEO_BAR_DISSOLVE = "geoBar/dissolve"
export const GEO_BAR_DISSOLVE_KEY = "geoBar/dissolveKey"
export const GEO_BAR_DISTANCE = "geoBar/distance"
export const GEO_BAR_EQUAL_DISTANCE = "EQUAL_DISTANCE"
export const GEO_BAR_FETCH_KNOWN_BOUNDARIES = "geoBar/fetchKnownBoundaries"
export const GEO_BAR_FETCH_KNOWN_BOUNDARY = "geoBar/fetchKnownBoundary"
export const GEO_BAR_INTERSECT = "geoBar/intersect"
export const GEO_BAR_NATURAL_BREAKS = "NATURAL_BREAKS"
export const GEO_BAR_QUERY = "geoBar/query"
export const GEO_BAR_SEARCH = "geoBar/search"
export const GEO_BAR_RECLASS = "geoBar/reclass"
export const GEO_BAR_RECLASS_COUNT = "geoBar/reclassCount"
export const GEO_BAR_RECLASS_TYPE = "geoBar/reclassType"
export const GEO_BAR_TARGET_LAYER = "geoBar/targetLayer"
export const GEO_BAR_UNION = "geoBar/union"
export const GEO_BAR_ZONAL_STATS = "geoBar/zonalStats"
export const HELP_GEO_BAR_CLIP = "geoBar/helpClip"
export const HELP_GEO_BAR_INTERSECTION = "geoBar/helpIntersection"
export const HELP_GEO_BAR_SEARCH_TOOL = "geoBar/helpSearch"
export const MAP_TOOLBAR_USER_LAYERS = "mapToolbar/selectUserLayers"
export const SELECT_BASE_LAYERS = "mapToolbar/selectBaseLayers"
export const SELECT_GEOPROCESSING = "mapToolbar/selectGeoprocessing"
export const SELECT_SERVICES = "mapToolbar/selectServices"
export const SELECT_TIMESERIES = "mapToolbar/selectTimeseries"
export const SELECT_USER_LAYERS = "mapToolbar/selectUserLayers"
export const SELECT_USER_TABLES = "mapToolbar/selectUserTables"
export const MAP_PANEL_MRRP_TOOLS = "mapToolbar/mrrpTools"
