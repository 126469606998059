// @flow
import React from "react"
import { CompactPicker as ColorComp } from "react-color"

type PropTypes = {
    color: string,
    disabled?: boolean,
    onSel: Function,
    width?: number,
}

export type ColorType = {
    hex: string,
}

function Color(props: PropTypes) {
    const [color, setColor] = React.useState(props.color)
    const [displayColorPicker, setDisplayColorPicker] = React.useState(false)

    React.useEffect(() => {
        setColor(props.color)
    }, [props.color])

    function onSelColor(newcolor: ColorType) {
        setColor(newcolor.hex)
        props.onSel(newcolor)
    }

    const colorStyle = {
        width: props.width,
        height: 14,
        borderRadius: 2,
        border: "1px solid #888",
        background: color,
    }

    const swatchStyle = {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "2px 2px",
    }

    const popoverStyle = {
        position: "absolute",
        zIndex: 2,
    }

    const coverStyle = {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    }

    let colorPicker
    if (displayColorPicker) {
        colorPicker = (
            <div style={popoverStyle}>
                <div
                    role="button"
                    tabIndex="-1"
                    style={coverStyle}
                    onClick={() => setDisplayColorPicker(false)}
                />
                <ColorComp
                    color={color}
                    disabled={props.disabled === true}
                    onChange={(newcolor) => onSelColor(newcolor)}
                    triangle={"hide"}
                />
            </div>
        )
    }
    return (
        <div style={swatchStyle}>
            <div
                role="button"
                tabIndex="-1"
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
            >
                <div style={colorStyle} />
            </div>
            {colorPicker}
        </div>
    )
}

Color.defaultProps = {
    disabled: false,
    width: 36,
}

export default Color
