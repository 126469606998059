const rawStyles = {
    buttonSubmit: {
        marginLeft: 10,
    },
    checkbox: {
        height: "auto",
    },
    controlGroup: {
        padding: "5px 10px",
    },
    geoBarIntersectionCtr: {
        flexGrow: 1,
    },
    geobarTable: {
        width: "100%",
    },
    geobarTableCell: {
        textAlign: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    geobarTableHeaderCell: {},
    geobarTableHeaderRow: {
        fontWeight: "normal",
    },
    layerGroup: {
        alignItems: "center",
        padding: "2px 5px",
    },
    layersListCtr: {
        maxHeight: "200px",
        overflowY: "auto",
    },
    toolButton: {
        margin: 4,
    },
    toolRow: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
    },
    // This is a select with a button to the right
    toolSelectWithButton: {
        width: 200,
    },
    subHeaderLabel: {
        marginLeft: "10px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
    },
    textField: {
        flexGrow: 1,
    },
    toolCtr: {
        display: "flex",
        flexDirection: "column",
    },
    toolLabel: {
        fontSize: "larger",
        fontWeight: "bold",
    },
}

const styles = {
    ...rawStyles,
    toolRowNoMargins: {
        ...rawStyles.toolRow,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
    },
}

export default styles
