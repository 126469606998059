import { onSetTabbedPanelKey } from "@owsi/catena/er2_map_userlayers/js/actions/tabbed_panel"
import * as types from "constants/action_types"

export const onTabIndexClick = (tabKey) => (dispatch, getState) => {
    dispatch(onSetTabbedPanelKey(tabKey))
}

export const setEnabledLayers = (mapLayer) => (dispatch, getState) => {
    dispatch({ type: types.SET_MRRP_MAP_LAYER_LIST, mrrpMapLayer: mapLayer })
}
