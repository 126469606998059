import { useMemo } from "react"
import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

// TODO(srichard): its better to just dispatch events instead of relying on callbacks -
// dispatch is injected with a hook now.

/**
 * https://react-redux.js.org/api/hooks#recipe-useactions
 */
export function useActions(actions: any, deps = []) {
    const dispatch = useDispatch()
    return useMemo(
        () => {
            if (Array.isArray(actions)) {
                return actions.map((a) => bindActionCreators(a, dispatch))
            }
            return bindActionCreators(actions, dispatch)
        }, //
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ? [dispatch, ...deps] : [dispatch]
    )
}

export const useSearchQueryArgs = (...takeParams: string[]) => {
    const location = useLocation()

    const search = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    )

    const args: Record<string, string | null> = {}
    for (const param of takeParams) {
        args[param] = search.get(param)
    }
    return args
}
