// @flow
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartBar } from "@fortawesome/free-regular-svg-icons"
import { compare } from "@owsi/catena/er2_map_userlayers/js/utils"
import * as actionTypes from "../constants/action_types"
import * as ids from "../constants/ids"
import GeoBarClip from "../components/geo_bar_clip"
import GeoBarIntersection from "../components/geo_bar_intersection"
import GeoBarUnion from "../components/geo_bar_union"
import GeoBarBuffer from "../components/geo_bar_buffer"
import GeoBarDissolve from "../components/geo_bar_dissolve"
import GeoBarZonalStats from "../components/geo_bar_zonal_stats"
import GeoBarReclassify from "../components/geo_bar_reclassify"
import GeoBarCalculator from "../components/geo_bar_calculator"
import GeoBarDigitize from "../components/geo_bar_digitize"
import GeoBarDistance from "../components/geo_bar_distance"
import QueryBuilder from "../components/query_builder"

// Default function to return a position at the bottom of the GeoBar
export const getBottomPosition = (props) => "bottom"
// Default function to return a position at the top of the GeoBar
export const getTopPosition = (props) => "top"

export const homeComponent = {
    toolbar: {
        active: false,
        icon: { default: "ms ms-catalog" },
        id: ids.GEO_BAR_HOME,
        name: "GIS Catalog",
        position: getTopPosition,
        items: [],
    },
}

export const searchComponent = {
    helpActive: false,
    toolbar: {
        active: false,
        icon: { default: <FontAwesomeIcon icon={"search"} /> },
        id: ids.GEO_BAR_SEARCH,
        name: "Search for a Tool",
        position: getTopPosition,
        items: [],
    },
}

// Represents a menu item in the GeoBar.  Each menu item can have items of its
// own, e.g.
// - Home
//   - Geoprocessing
//     - Vector
//     - Raster
//   - Area Selection
export type GeoBarItemType = {
    // Actions local to each tool can be held here
    actions: object,
    // Place for handling local state associated with the tool in operation
    state: object,
    id: string,
    label: string,
    fullLabel: string,
    render: Function,
    toolbar: {
        id: string,
        name: string,
        icon: Function,
        active: boolean,
        isEnabled: Function,
        isVisible: Function,
        position: Function,
        items: Array<GeoBarItemType>,
    },
}

export type GeoBarType = {
    active: boolean,
    homeComponent: GeoBarItemType,
    search: {
        active: boolean,
        suggestions: Array,
        value: string,
    },
    searchComponent: GeoBarItemType,
    tool?: string,
    tools: Array<GeoBarItemType>,
}

export const tools = [
    {
        distance: 0,
        targetLayer: "",
        icon: "ms ms-buffer",
        id: ids.GEO_BAR_BUFFER,
        description: "Buffer one or more layers",
        help:
            "To perform a buffer, click the checkbox next to one or more layers and then select a buffer " +
            "distance (in meters). Then click on Submit.  A buffer will be performed on the selected layers, " +
            "and the result will be added to your user layers (above).",
        label: "Buffer",
        fullLabel: "Geoprocessing > Vector > Buffer",
        render: (props) => <GeoBarBuffer {...props} />,
        toolType: "Geoprocessing",
    },
    {
        expression: "",
        layers: [],
        icon: <FontAwesomeIcon icon={"calculator"} />,
        id: ids.GEO_BAR_CALCULATOR,
        description:
            "Perform an arithmetic operation on a set of one or more rasters",
        help: (
            <React.Fragment>
                <p>
                    Perform an arithmetic operation on a set of one or more
                    rasters.
                </p>
                <p>
                    All available layers below will be assigned a variable, such
                    as &quot;A&quot; or &quot;B.&quot; Use these variables,
                    along with arithmetic operators (+&minus;&divide;*&lowast;
                    and other supported operators, to build a mathematical
                    expression. This expression will transform existing layers
                    into a new one with the provided logic.
                </p>
                <p>
                    Note that additional scientific expressions are also
                    supported. See&nbsp;
                    <a
                        href="https://docs.scipy.org/doc/numpy-1.13.0/reference/routines.math.html"
                        target={"calc_doc"}
                    >
                        this link
                    </a>
                    &nbsp;for details.
                </p>
            </React.Fragment>
        ),
        label: "Calculator",
        fullLabel: "Geoprocessing > Raster > Calculator",
        render: (props) => <GeoBarCalculator {...props} />,
        toolType: "Geoprocessing",
    },
    {
        boundaryLayer: "",
        targetLayer: "",
        description: "Clip a target layer to a boundary",
        help: (
            <React.Fragment>
                <p>Clip one layer to another.</p>
                <p>
                    To perform a clip, select a target layer and a boundery
                    layer then click on Submit. A clip will be performed on the
                    selected layers, and the result will be added to your user
                    layers (above).
                </p>
            </React.Fragment>
        ),
        icon: "ms ms-crop",
        id: ids.GEO_BAR_CLIP,
        label: "Clip",
        fullLabel: "Geoprocessing > Vector > Clip",
        render: (props) => <GeoBarClip {...props} />,
        toolType: "Geoprocessing",
    },
    {
        drawnFeatureName: "",
        drawPromptRendered: true,
        drawToolActive: false,
        knownBoundaryType: "",
        knownBoundaryId: "",
        editingLayer: "",
        help: <p>Create and edit layers.</p>,
        icon: "ms ms-draw-polygon",
        id: ids.GEO_BAR_DIGITIZE_CREATE,
        description: "Draw or edit features of a layer",
        label: "Digitize & Edit",
        fullLabel: "Digitize & Edit",
        method: "",
        render: (props) => <GeoBarDigitize {...props} />,
        snappingLayer: "",
        toolType: "Geoprocessing",
    },
    {
        key: "",
        help:
            "To perform a dissolve, select a layer and then select a key. " +
            "The key is the name of an attribute that is the common value that features will be aggregated on.  Then click on " +
            "Submit, and the result will be added to your user layers.",
        icon: "ms ms-merge",
        id: ids.GEO_BAR_DISSOLVE,
        description: "Dissolve two or more layers together",
        label: "Dissolve",
        fullLabel: "Geoprocessing > Vector > Dissolve",
        targetLayer: "",
        render: (props) => <GeoBarDissolve {...props} />,
        toolType: "Geoprocessing",
    },
    {
        icon: "ms ms-measure-distance",
        id: ids.GEO_BAR_DISTANCE,
        description: "Measure distance and area",
        label: "Distance & Area",
        fullLabel: "Distance & Area",
        help: "Click on the map to draw lines and areas.",
        render: (props) => <GeoBarDistance {...props} />,
        toolType: "Geoprocessing",
    },
    {
        layers: [],
        icon: "ms ms-intersection",
        id: ids.GEO_BAR_INTERSECT,
        help:
            "To perform an intersection, click the checkbox next to two or more layers and then click on " +
            "Submit.  An intersection will be performed on the selected layers, and the result will be " +
            "added to your user layers (above).",
        description: "Intersect two or more layers together",
        label: "Intersection",
        fullLabel: "Geoprocessing > Vector > Intersection",
        render: (props) => <GeoBarIntersection {...props} />,
        toolType: "Geoprocessing",
    },
    {
        layers: [],
        icon: <FontAwesomeIcon icon={"search"} />,
        id: ids.GEO_BAR_QUERY,
        help: (
            <div>
                <p>Select features based on attributes.</p>
                <p>First select the layer of interest.</p>
                <p>
                    Then build expressions from the layers's attributes and
                    values.
                </p>
            </div>
        ),
        description: "Select features",
        label: "Feature Selection",
        fullLabel: "Geoprocessing > Vector > Selection",
        render: (props) => <QueryBuilder {...props} />,
        toolType: "Geoprocessing",
    },
    {
        classificationCount: 5,
        classificationType: ids.GEO_BAR_NATURAL_BREAKS,
        targetLayer: "",
        icon: "ms ms-resample",
        id: ids.GEO_BAR_RECLASS,
        description: "Reclassify the values of a raster",
        label: "Reclassify",
        fullLabel: "Geoprocessing > Raster > Reclassify",
        help: (
            <div>
                <p>Reclassify a raster layer.</p>
                <p>To reclassify a raster, first select a raster.</p>
                <p>
                    Once the raster is selected, break the raster into
                    classifications. To do this, select a method to use to break
                    the raster into logical segments.
                </p>
                <p>
                    Using Natural Breaks will divide the raster based on
                    groupings that exist naturally in the raster. Equal distance
                    will divide the raster into evenly-spaced groups between the
                    smallest and largest value contained in the raster.
                </p>
                <p>
                    Once a method is found, select the number of classifications
                    or groups you wish the new raster to be segmented. Click
                    Fetch Classifications to have the classifications generated
                    for you.
                </p>
                <p>
                    When the classifications have been generated, you are ready
                    to reclassify the raster. Click on Submit to perform the
                    operation. A new raster with the resulting reclassification
                    will be added to your user layers above.
                </p>
            </div>
        ),
        render: (props) => <GeoBarReclassify {...props} />,
        toolType: "Geoprocessing",
    },
    {
        layers: [],
        help:
            "To perform a union, click the checkbox next to two or more layers and then click on " +
            "Submit.  A union will be performed on the selected layers, and the result will be " +
            "added to your user layers.",
        icon: "ms ms-union",
        id: ids.GEO_BAR_UNION,
        description: "Union two or more layers together",
        label: "Union",
        fullLabel: "Geoprocessing > Vector > Union",
        render: (props) => <GeoBarUnion {...props} />,
        toolType: "Geoprocessing",
    },
    {
        boundaryLayer: "",
        targetLayer: "",
        icon: <FontAwesomeIcon icon={faChartBar} />,
        id: ids.GEO_BAR_ZONAL_STATS,
        description: "Retrieve zonal statistics for a raster",
        label: "Zonal Statistics",
        fullLabel: "Geoprocessing > Raster > Zonal Statistics",
        help:
            "To calculate zonal statistics, select a target layer (a raster file) and a zonal layer " +
            "(a vector layer) then click on Submit.  Zonal statistics will be calculated for the selected " +
            "layers, and the results will be displayed below.",
        render: (props) => <GeoBarZonalStats {...props} />,
        toolType: "Geoprocessing",
    },
]

tools.forEach((t) => {
    t.toolbar = {}
    t.result = {}
})

export const initialState = {
    active: false,
    enabled: true,
    homeComponent,
    searchComponent,
    search: {
        active: true,
        suggestions: [],
        value: "",
    },
    tools,
}

export function getActiveTool(props) {
    return props.geoBar.tools.find((tool) => tool.id === props.geoBar.tool)
}

export function matchesSelectedGeometryType(
    layerToCheck,
    selectedLayerIds,
    layers,
) {
    if (selectedLayerIds.length === 0) {
        // No layers have been selected, so all geometry types are valid
        return true
    }
    const selLayer = layers.find((lyr) => lyr.name === selectedLayerIds[0])

    return (
        (layerToCheck.layer_type || "").toLowerCase().replace("multi", "") ===
        (selLayer.layer_type || "").toLowerCase().replace("multi", "")
    )
}

export const isGeoBarActive = (state, active) => {
    if (!state.mapToolbar.panel.visible) {
        return false
    }
    const activeMapToolbarItem = state.mapToolbar.items.find(
        (item) => item.id === ids.MAP_TOOLBAR_USER_LAYERS,
    )
    if (!activeMapToolbarItem || !activeMapToolbarItem.active) {
        return false
    }
    // If the map toolbar panel is visible and the user layer panel is visible,
    // return the provided visibility
    return active
}

export function getSuggestions(value, suggestions) {
    const inputValue = value.trim().toLowerCase()
    const tokens = inputValue.split(" ")
    const inputLength = inputValue.length
    let count = 0

    return inputLength === 0
        ? suggestions
        : suggestions
              .sort((a, b) => compare(a.toolType, b.toolType))
              .sort((a, b) => compare(a.label, b.label))
              .filter((suggestion) => {
                  const lbl = suggestion.fullLabel.toLowerCase()
                  let idx = 0
                  const matches = tokens.every((tkn) => {
                      const foundIdx = lbl.indexOf(tkn, idx)
                      if (foundIdx < idx) {
                          // console.log(`Couldn't find ${tkn} at ${lbl}. foundIdx = ${foundIdx}, idx = ${idx}`)
                          return false
                      }
                      // console.log(`Found ${tkn} at ${lbl} = ${foundIdx}`)
                      idx = foundIdx
                      return true
                  })

                  const keep = count < 100 && matches
                  if (keep) {
                      count += 1
                  }
                  return keep
              })
}

export function toggleLayerSelected(
    layer,
    isSelected: boolean,
    layerIds: Array,
) {
    if (isSelected) {
        layerIds.push(layer)
        return layerIds.filter((lyr, pos) => layerIds.indexOf(lyr) === pos)
    }
    // Remove the layer from selected layers
    return layerIds.filter((lyrId) => lyrId !== layer)
}

function isDrawToolActive(method, tool) {
    if ([ids.GEO_BAR_DIG_METHOD_KNOWN_BOUNDARY].includes(method)) {
        return false
    }
    return method.length > 0
}

// @flow
export function reducer(state: GeoBarType = initialState, action: any) {
    switch (action.type) {
        case actionTypes.CHANGE_INPUT: {
            if (action.id === ids.GEO_BAR_BUFFER_DISTANCE) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_BUFFER) {
                            return {
                                ...tool,
                                distance: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_CALCULATOR_EXPRESSION) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_CALCULATOR) {
                            return {
                                ...tool,
                                expression: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DIGITIZE_DRAWN_FEATURE) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                drawnFeature: action.value,
                                drawToolActive: false,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DIGITIZE_DRAWN_FEATURE_NAME) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                drawnFeatureName: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (
                action.id === ids.GEO_BAR_DIGITIZE_DRAW_PROMPT_RENDERED
            ) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                drawPromptRendered: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DIG_KNOWN_BOUNDARY) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                knownBoundaryId: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DIG_KNOWN_BOUNDARY_TYPE) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                knownBoundaryType: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DIGITIZE_METHOD) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            // Remove the drawn feature whenever the method is updated
                            const {
                                drawnFeature,
                                knownBoundary,
                                ...newState
                            } = tool
                            const drawToolActive = isDrawToolActive(
                                action.value,
                                tool,
                            )
                            const drawPromptRendered = !drawToolActive
                            return {
                                ...newState,
                                method: action.value,
                                drawToolActive,
                                drawPromptRendered,
                                knownBoundaryType: "",
                                knownBoundaryId: "",
                                knownBoundaries: [],
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DISSOLVE_KEY) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DISSOLVE) {
                            return {
                                ...tool,
                                key: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_RECLASS_TYPE) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_RECLASS) {
                            return {
                                ...tool,
                                classificationType: action.value,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_RECLASS_COUNT) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_RECLASS) {
                            return {
                                ...tool,
                                classificationCount: parseInt(action.value, 10),
                            }
                        }
                        return tool
                    }),
                }
            }
            return state
        }

        case actionTypes.CLICK_GEO_BAR_ITEM:
            if (action.id === ids.GEO_BAR_SEARCH) {
                return {
                    ...state,
                    search: {
                        ...state.search,
                        active: !state.search.active,
                    },
                }
            }
            return state

        case actionTypes.GEO_BAR_FETCHED_OPERATION: {
            let newState = { ...state }
            if (action.id === ids.GEO_BAR_CLASSIFICATIONS) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_RECLASS) {
                            return {
                                ...tool,
                                classifications: action.result,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                result: { ...action.result, kind: action.kind },
                                resultRendered: false,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_FETCH_KNOWN_BOUNDARIES) {
                newState = {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                knownBoundaries: action.result,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_FETCH_KNOWN_BOUNDARY) {
                newState = {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                drawnFeature: action.result,
                                knownBoundary: action.result,
                            }
                        }
                        return tool
                    }),
                }
            }
            return {
                ...newState,
                tools: newState.tools.map((tool) => {
                    if (tool.id === action.id) {
                        return {
                            ...tool,
                            result: action.result,
                            resultRendered: false,
                        }
                    }
                    return tool
                }),
            }
        }

        case actionTypes.FETCH_ERROR: {
            return {
                ...state,
                tools: state.tools.map((tool) => {
                    if (tool.id === action.id) {
                        return {
                            ...tool,
                            result: action.result,
                            resultRendered: true,
                            fetchError: action.error,
                            isFetching: false,
                        }
                    }
                    return tool
                }),
            }
        }

        case actionTypes.GEO_BAR_FETCHING_OPERATION: {
            if (action.id === ids.GEO_BAR_CLASSIFICATIONS) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_RECLASS) {
                            return {
                                ...tool,
                                isFetchingClassifications: action.isFetching,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_FETCH_KNOWN_BOUNDARIES) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                isFetchingKnownBoundaries: action.isFetching,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.id === ids.GEO_BAR_FETCH_KNOWN_BOUNDARY) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                            return {
                                ...tool,
                                isFetchingKnownBoundary: action.isFetching,
                            }
                        }
                        return tool
                    }),
                }
            }
            return {
                ...state,
                tools: state.tools.map((tool) => {
                    if (tool.id === action.id) {
                        return {
                            ...tool,
                            isFetching: action.isFetching,
                            fetchError: false,
                        }
                    }
                    return tool
                }),
            }
        }

        case actionTypes.GEO_BAR_FETCH_ERROR_RENDERED: {
            return {
                ...state,
                tools: state.tools.map((tool) => {
                    if (tool.id === action.id) {
                        return {
                            ...tool,
                            fetchError: false,
                        }
                    }
                    return tool
                }),
            }
        }

        case actionTypes.GEO_BAR_RESULT_RENDERED: {
            return {
                ...state,
                tools: state.tools.map((tool) => {
                    if (tool.id === action.id) {
                        return {
                            ...tool,
                            result: {},
                            resultRendered: true,
                        }
                    }
                    return tool
                }),
            }
        }

        case actionTypes.GEO_BAR_SELECT_LAYER: {
            if (
                [ids.GEO_BAR_INTERSECT, ids.GEO_BAR_UNION].includes(
                    action.tool.id,
                )
            ) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === action.tool.id) {
                            const layers = toggleLayerSelected(
                                action.layer,
                                action.isSelected,
                                tool.layers,
                            )
                            return {
                                ...tool,
                                layers,
                            }
                        }
                        return tool
                    }),
                }
            } else if (
                [
                    ids.GEO_BAR_BUFFER,
                    ids.GEO_BAR_CLIP,
                    ids.GEO_BAR_DISSOLVE,
                    ids.GEO_BAR_ZONAL_STATS,
                ].includes(action.tool.id)
            ) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === action.tool.id) {
                            if (action.opts.kind === "targetLayer") {
                                return {
                                    ...tool,
                                    targetLayer: action.layer,
                                }
                            } else if (action.opts.kind === "boundaryLayer") {
                                return {
                                    ...tool,
                                    boundaryLayer: action.layer,
                                }
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.tool.id === ids.GEO_BAR_RECLASS) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (tool.id === action.tool.id) {
                            return {
                                ...tool,
                                targetLayer: action.layer,
                            }
                        }
                        return tool
                    }),
                }
            } else if (action.tool.id === ids.GEO_BAR_DIGITIZE_CREATE) {
                return {
                    ...state,
                    tools: state.tools.map((tool) => {
                        if (
                            tool.id === action.tool.id &&
                            action.opts.kind === "editingLayer"
                        ) {
                            return {
                                ...tool,
                                editingLayer: action.layer,
                            }
                        } else if (
                            tool.id === action.tool.id &&
                            action.opts.kind === "snappingLayer"
                        ) {
                            return {
                                ...tool,
                                snappingLayer: action.layer,
                            }
                        }
                        return tool
                    }),
                }
            }
            return state
        }

        case actionTypes.POST_CLICK_MAP_TOOLBAR_ITEM: {
            if (state.active !== isGeoBarActive(action.state, state.active)) {
                return {
                    ...state,
                    active: isGeoBarActive(action.state, state.active),
                }
            }
        }

        case actionTypes.GEO_BAR_CHANGE_SEARCH_VALUE: {
            if (typeof action.value === "string") {
                // Sometimes the React Autosuggest component will emit a change event when a tool is selected,
                // passing an integer of the index of the tool in the list of suggestions. We don't care about
                // handling this here, since it's already handled with the GEO_BAR_SELECT_TOOL event
                return {
                    ...state,
                    search: {
                        ...state.search,
                        value: action.value,
                    },
                }
            }
            return state
        }

        case actionTypes.GEO_BAR_SELECT_TOOL: {
            return {
                ...state,
                tool: action.tool.id,
                search: {
                    ...state.search,
                    active: false,
                    value: action.tool.label,
                },
            }
        }

        case actionTypes.GEO_BAR_SUGGESTIONS_CLEAR_REQUESTED: {
            return {
                ...state,
                search: {
                    ...state.search,
                    suggestions: [],
                },
            }
        }

        case actionTypes.GEO_BAR_SUGGESTIONS_FETCH_REQUESTED: {
            return {
                ...state,
                search: {
                    ...state.search,
                    suggestions: getSuggestions(
                        action.value ? action.value.value : "",
                        state.tools,
                    ),
                },
            }
        }

        case actionTypes.POST_CLICK_GEO_BAR_ITEM: {
            if (action.id === ids.GEO_BAR_HOME) {
                return {
                    ...state,
                    active: isGeoBarActive(action.state, !state.active),
                }
            }
            return state
        }

        default:
            return state
    }
}

export default reducer
