// @flow
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import jStat from "jstat"
import Theme from "@owsi/catena/er2_styles"
import EditManager from "./edit_manager"

type PropsType = {
    editManager: EditManager,
    fieldname: string,
    onDelete: Function,
    onEdit: Function,
    onRename: Function,
    onShowCalculator: Function,
    onShowStatistics: Function,
    theme: Theme,
}

function ContextMenuColumn(props: PropsType) {
    const [columnMenuActionsAnchor, setColumnMenuActionsAnchor] = useState(null)
    const [columnMenuActionsColumn, setColumnMenuActionsColumn] = useState(null)

    const handleColumnMenuClick = (event) => {
        setColumnMenuActionsAnchor(event.currentTarget)
        setColumnMenuActionsColumn(props.fieldname)
    }
    const values = props.editManager.rows
        .map((r) => parseFloat(r[props.fieldname]))
        .filter((n) => n !== 0)
    const jObj = jStat(values)

    const handleColumnMenuClose = (action) => () => {
        setColumnMenuActionsAnchor(null)
        setColumnMenuActionsColumn(props.fieldname)
        if (action === "statistics") {
            props.onShowStatistics()
        } else if (action === "calculate") {
            props.onShowCalculator()
        } else if (action === "editColumn") {
            props.onEdit()
        } else if (action === "deleteColumn") {
            props.onDelete()
        } else if (action === "renameColumn") {
            props.onRename()
        } else if (action !== undefined) {
            console.warn(`Action is ${action}`)
        }
    }

    return (
        <React.Fragment>
            <div className={props.theme.attributeTableTH}>
                {props.fieldname}
                <IconButton
                    aria-owns={columnMenuActionsAnchor}
                    aria-haspopup="true"
                    onClick={handleColumnMenuClick}
                    className={props.theme.littleButton}
                >
                    <FontAwesomeIcon icon="caret-down" />
                </IconButton>
                <Menu
                    id={props.fieldname}
                    anchorEl={columnMenuActionsAnchor}
                    open={
                        columnMenuActionsColumn === props.fieldname &&
                        columnMenuActionsAnchor !== null
                    }
                    onClose={handleColumnMenuClose(null)}
                >
                    <MenuItem
                        className={props.theme.attributeTableMenuItem}
                        onClick={handleColumnMenuClose("editColumn")}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        className={props.theme.attributeTableMenuItem}
                        onClick={handleColumnMenuClose("deleteColumn")}
                    >
                        Delete
                    </MenuItem>
                    <MenuItem
                        className={props.theme.attributeTableMenuItem}
                        onClick={handleColumnMenuClose("renameColumn")}
                    >
                        Rename
                    </MenuItem>
                    <MenuItem
                        className={props.theme.attributeTableMenuItem}
                        onClick={handleColumnMenuClose("calculate")}
                    >
                        Calculate
                    </MenuItem>
                    {!isNaN(jObj.mean()) && (
                        <MenuItem
                            className={props.theme.attributeTableMenuItem}
                            onClick={handleColumnMenuClose("statistics")}
                        >
                            Statistics
                        </MenuItem>
                    )}
                </Menu>
            </div>
        </React.Fragment>
    )
}

export default ContextMenuColumn
