// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Theme from "@owsi/catena/er2_styles"
import {
    compare,
    getPolygonVectorLayers,
    getUniqueLayerName,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import * as ids from "../constants/ids"
import { getActiveTool } from "../reducers/geo_bar"

type Props = {
    actions: {
        onClickInlineHelp: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitClip: Function,
        onGeoBarSelectLayer: Function,
        onUpdateUserLayers: Function,
    },
    mapsources: object,
    theme: Theme,
}

function isValid(tool) {
    return tool.targetLayer.length > 0 && tool.boundaryLayer.length > 0
}

function targetLayerFilter(lyr) {
    return !(!lyr.layer_type || lyr.layer_type.toLowerCase() === "raster")
}

function boundaryLayerFilter(lyr, props) {
    const tool = getActiveTool(props)
    return !(
        !lyr.layer_type ||
        lyr.layer_type.toLowerCase() === "raster" ||
        tool.targetLayer === lyr.name
    )
}

function GeoBarClip(props: Props) {
    const tool = getActiveTool(props)
    const layers = getPolygonVectorLayers(props.mapsources, "Polygon")
    const { theme } = props

    const [outputName, setOutputName] = React.useState("")

    const clip = () => {
        props.actions.onGeoBarSubmitClip(
            tool.targetLayer,
            tool.boundaryLayer,
            outputName,
        )
    }

    const onOutputLayerKeyDown = (e) => {
        if (e.key === "Enter") {
            clip()
            e.preventDefault()
        }
    }

    const onFocus = (event) => event.target.select()

    const selectBoundary = (e) => {
        const boundaryLayer = layers.find((lyr) => lyr.name === e.target.value)
        props.actions.onGeoBarSelectLayer(true, boundaryLayer.name, tool, {
            kind: "boundaryLayer",
        })
    }

    const selectTarget = (e) => {
        const targetLayer = layers.find((lyr) => lyr.name === e.target.value)

        // Create an output name based on the input
        const [basename, ext] = splitExt(targetLayer.name)
        const addExt = ext || ".json"
        const uniqueName =
            getUniqueLayerName(props.mapsources, `${basename} (clipped)`) +
            addExt
        setOutputName(uniqueName)

        props.actions.onGeoBarSelectLayer(true, targetLayer.name, tool, {
            kind: "targetLayer",
        })
    }

    const renderTool = () => (
        <div className={theme.toolCtr}>
            <div className={theme.toolRow}>
                <FormControl fullWidth>
                    <InputLabel htmlFor={ids.GEO_BAR_TARGET_LAYER}>
                        Target Layer
                    </InputLabel>
                    <Select
                        inputProps={{
                            name: ids.GEO_BAR_TARGET_LAYER,
                        }}
                        onChange={selectTarget}
                        value={tool.targetLayer}
                    >
                        {layers
                            .filter((lyr) => targetLayerFilter(lyr, props))
                            .sort((a, b) => compare(a.name, b.name))
                            .map((layer) => (
                                <MenuItem key={layer.name} value={layer.name}>
                                    {layer.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
            <div className={theme.toolRow}>
                <FormControl fullWidth>
                    <InputLabel htmlFor={ids.GEO_BAR_BOUNDARY_LAYER}>
                        Boundary Layer
                    </InputLabel>
                    <Select
                        inputProps={{
                            name: ids.GEO_BAR_BOUNDARY_LAYER,
                        }}
                        onChange={selectBoundary}
                        value={tool.boundaryLayer}
                    >
                        {layers
                            .filter((lyr) => boundaryLayerFilter(lyr, props))
                            .sort((a, b) => compare(a.name, b.name))
                            .map((layer) => (
                                <MenuItem key={layer.name} value={layer.name}>
                                    {layer.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
            <div className={theme.toolRow}>
                <TextField
                    fullWidth
                    label="Output layer name"
                    margin="normal"
                    onChange={(evt) => setOutputName(evt.target.value)}
                    onFocus={onFocus}
                    onKeyDown={onOutputLayerKeyDown}
                    value={outputName}
                />
            </div>
            <div className={theme.toolRow}>
                <Button
                    color={"primary"}
                    disabled={!isValid(tool) || tool.isFetching}
                    onClick={clip}
                    variant={"contained"}
                >
                    {!tool.isFetching && <span>Clip</span>}
                    {tool.isFetching && (
                        <span>
                            Clipping&nbsp;
                            <i className="fas fa-spinner fa-spin" />
                        </span>
                    )}
                </Button>
            </div>
        </div>
    )

    return <div className={theme.geoBarCtr}>{renderTool()}</div>
}

export default GeoBarClip
