// @flow
import * as actionTypes from "../constants/action_types"

export const loginFields = [
    ["username", "Username"],
    ["password", "Password"],
]

export const userFields = [
    ["id", "Username"],
    ["firstName", "First Name"],
    ["lastName", "Last Name"],
    ["email", "Email"],
    ["password", "Password"],
]

export const registrationFields = [
    ...userFields,
    ["passwordRetyped", "Retype Password"],
]

export type UserType = {
    userError: string,
    userMessage: string,
    isAuthenticated: boolean,
    id: string,
    email: string,
}

const initialState = {
    userError: null,
    userMessage: null,
    id: "anon",
    isAuthenticated: false,
}

export default function user(state: UserType = initialState, action) {
    switch (action.type) {
        case actionTypes.USER_UPDATE:
            if (action.user) {
                return {
                    ...state,
                    ...action.user,
                }
            }
            return state

        case actionTypes.USER_ERROR:
            return {
                ...state,
                userError: action.exception.message,
            }

        case actionTypes.USER_ERROR_HANDLED:
            return {
                ...state,
                userError: null,
            }

        case actionTypes.USER_MESSAGE:
            return {
                ...state,
                userMessage: action.message,
            }

        case actionTypes.USER_MESSAGE_HANDLED:
            return {
                ...state,
                userMessage: null,
            }

        case actionTypes.REGISTRATION_RECEIVE:
            return {
                ...state,
                ...action.user,
            }

        case actionTypes.REMOVE_RECEIVE:
            return {
                ...state,
                isAuthenticated: false,
            }

        default:
            return state
    }
}
