const styles = {
    publicLayerRoot: {
        fontFamily: "'Roboto Condensed', sans-serif",
        minWidth: 200,
    },
    publicLayerBox: {
        backgroundColor: "white",
        position: "absolute",
        zIndex: "200",
        borderRadius: "5px 0px 0px 5px",
        border: "1px solid #888",
    },
    publicLayerButton: {
        backgroundColor: "initial",
    },
    publicLayerCloseButton: {
        margin: "10px",
        float: "right",
    },
}

export default styles
