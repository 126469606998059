// @flow
import * as React from "react"
import { withRouter } from "react-router-dom"
import MapToolbarItem from "./map_toolbar_item"
import type { GeoBarType } from "../reducers/geo_bar"
import type { MapToolbarType } from "../reducers/map_toolbar"

type Props = {
    geoBar: GeoBarType,
    geoBarIsActive: Function,
    mapToolbar: MapToolbarType,
    onClickItem: Function,
    theme: {},
}

/* eslint-disable react/prefer-stateless-function */
class GeoBar extends React.Component<Props> {
    renderItems(component, position) {
        if (component && component.toolbar && component.toolbar.active) {
            return (
                <React.Fragment>
                    {component.toolbar.items
                        .filter(
                            (item) => item.position(this.props) === position,
                        )
                        .map((item, idx) => (
                            <MapToolbarItem
                                key={item.id}
                                idx={idx}
                                item={item}
                                onClick={this.props.onClickItem}
                                theme={this.props.theme}
                            />
                        ))}
                </React.Fragment>
            )
        }
        return null
    }

    render() {
        const searchVisible =
            this.props.geoBar.active && !this.props.geoBar.search.active
        const theme = this.props.theme
        return (
            <div className={theme.geoBarCtr}>
                <div className={theme.geoBarTop}>
                    <MapToolbarItem
                        key={this.props.geoBar.homeComponent.toolbar.id}
                        idx={0}
                        item={{
                            ...this.props.geoBar.homeComponent.toolbar,
                            active: this.props.geoBar.active,
                        }}
                        onClick={this.props.onClickItem}
                        theme={theme}
                    />
                    {searchVisible && (
                        <MapToolbarItem
                            key={this.props.geoBar.searchComponent.toolbar.id}
                            idx={1}
                            item={{
                                ...this.props.geoBar.searchComponent.toolbar,
                                active: this.props.geoBar.search.active,
                            }}
                            onClick={this.props.onClickItem}
                            theme={theme}
                        />
                    )}
                    {this.renderItems(this.props.geoBar.tool, "top")}
                </div>
                <div className={theme.geoBarBottom}>
                    {this.renderItems(this.props.geoBar.tool, "bottom")}
                </div>
            </div>
        )
    }
}

export default withRouter(GeoBar)
