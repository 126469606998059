export const rawStyles = {
    contracted: {
        gridRowEnd: "analysisPanel",
    },
    mapToolbar: {
        alignItems: "stretch",
        backgroundColor: (props) => props.colors?.offwhite || props.offwhite,
        boxShadow: "2px 0 1px 1px rgba(0,0,0,0.2)",
        composes: "aboveMap",
        display: "flex",
        flexBasis: "0",
        flexDirection: "column",
        gridColumnStart: "toolbar",
        gridColumnEnd: "right",
        gridRowStart: "subHeader",
        gridRowEnd: "bottom",
    },
    mapToolbarButton: (props) => {
        if (props.mapToolbar) {
            const transparent = `rgba(255, 255, 255, ${
                props.mapToolbar.opacity / 100
            })`
            return {
                backgroundColor: transparent,
                margin: 4,
            }
        }
        return { border: "1px solid black" }
    },
    mapToolbarButtonActive: {},
    mapToolbarButtonDisabled: {
        border: "1px solid rgba(0,0,0,0.3)",
    },
    mapToolbarButtonSpacer: {
        display: "inline-block",
        width: 20,
    },
    mapToolbarAddress: {
        backgroundColor: "rgba(255,255,255,0.5)",
        borderRadius: 6,
        position: "absolute",
        right: 0,
        padding: 10,
        textAlign: "right",
        transition: "width 2s, height 2s, background-color 2s, transform 2s",
    },
    mapToolbarAddressTextField: {
        width: 200,
    },
    mapToolbarBottom: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
    },
    mapToolbarTitleBar: {
        backgroundColor: (props) =>
            props.colors?.secondaryTwo || props.secondaryTwo,
        color: (props) => props.colors?.white || props.white,
        flex: "0 1",
        maxWidth: "100%",
        lineHeight: "44px",
        margin: "0",
        overflow: "inherit",
        textAlign: "center",
    },
    mapToolbarTop: {
        flexBasis: "0",
        flexGrow: "1",
        overflowY: "hidden",
    },
    mapToolbarHidden: {
        display: "none",
    },
    noBoxShadow: {
        boxShadow: "none",
    },
    mapToolbarSpacer: {
        backgroundColor: (props) =>
            props.colors?.secondaryColor || props.secondaryColor,
        color: (props) =>
            props.colors?.contrastTextColor || props.contrastTextColor,
        height: "44px",
    },
    spacerTransparent: {
        height: "44px",
    },
}

export default rawStyles
