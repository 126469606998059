import React from "react"
import { Box, Typography, TypographyProps } from "@material-ui/core"

export const PlainMessage: React.FC<TypographyProps> = ({
    children,
    ...rest
}) => {
    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="h5" {...rest}>
                {children}
            </Typography>
        </Box>
    )
}
