import { darken, lighten } from "@material-ui/core"
import { graphGeneralRegionColors } from "styles/colors.graph"

export const paletteDarkGrey = "#6c756b"
export const paletteOrange = "#db9053"
export const paletteBlue = "#588096"
export const paletteBlue2 = "#6CA3C1"
export const paletteGreen = "#a7cab1"
export const paletteDarkGreen = "#7EA479"
export const paletteCream = "#f4f1e8"
export const paletteDarkCream = "#e7e3d9"
export const paletteBlack = "#323031"
export const paletteYellow = "#FAC873"
export const palettePink = "#FA8072"
export const paletteThistle = "#CAB1BD"
export const paletteSpace = "#1E2749"

export const primaryColor = paletteBlue
export const primaryLightColor = lighten(primaryColor, 0.7)
export const primaryDarkColor = darken(primaryColor, 0.2)
export const secondaryColor = paletteGreen
export const secondaryLightColor = lighten(secondaryColor, 0.5)
export const secondaryDarkColor = darken(secondaryColor, 0.3)
export const secondaryFaintColor = lighten(secondaryColor, 0.8)
export const accentColor = paletteSpace
export const accentLightColor = lighten(accentColor, 0.7)
export const accentDarkColor = darken(accentColor, 0.2)
export const primaryTextColor = paletteCream
export const secondaryTextColor = "#000000"

export const highlight = paletteOrange

export const textColor = paletteDarkGrey
export const contrastTextColor = paletteCream

export const gray = "#e3eaed"

export const closerBackgroundColor = "rgba(255, 255, 255, 0.6)"

export const yellow = "#F8FD43"
export const orange = "#FD9543"
export const red = "#F7786C"
export const green = "#CFD8B9"
export const lightGreen = "#7fb78e"
export const darkGreen = "#A5CA4C"

export const successColor = paletteGreen
export const successTextColor = lightGreen
export const failureColor = palettePink
export const alertColor = paletteYellow
export const failureLight = lighten(palettePink, 0.8)
export const failureDark = darken(palettePink, 0.3)
export const successLight = lighten(paletteGreen, 0.7)
export const successDark = darken(successTextColor, 0.4)

export const cardNorthLight = lighten(
    graphGeneralRegionColors.northernRegion,
    0.9
)
export const cardNorthDark = darken(
    graphGeneralRegionColors.northernRegion,
    0.1
)

export const cardSouthLight = lighten(
    graphGeneralRegionColors.southernRegion,
    0.9
)
export const cardSouthDark = graphGeneralRegionColors.southernRegion

export const paletteBigQuestion = "#ffa726"
export const paletteManHypothesis = "#aa47bc"
export const paletteSciActivities = "#26c6da"
export const paletteManActions = "#26a69a"
export const paletteFocalQuestion = "#ff6f43"
export const paletteStudy = "#7db036"

export const paletteActionFramework = "#d9d66f"
export const paletteMonitoringActivities = "#6f74d9"
export const paletteVitalStatistics = "#724eb4"
export const paletteActivityComponent = "#5b6b33"
export const paletteCertainty = "#397053"
export const paletteEvidenceLine = "#3b4f80"
export const paletteImplLevel = "#803568"
export const paletteLifeStage = "#6b2027"
export const paletteMonitoringType = "#73513e"
export const palettePerformanceMetric = "#858571"
export const paletteMetricTarget = "#1b5b01"
export const paletteStudyType = "#670202"
export const paletteSubobjectives = "#5b5b58"
export const paletteStatus = "#1f3d36"

export const libraryBookColors = {
    T: paletteThistle,
    J: paletteOrange,
    M: primaryColor,
    R: paletteGreen,
}
