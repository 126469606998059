// @flow
import React from "react"
import { withRouter } from "react-router-dom"
import Theme from "@owsi/catena/er2_styles"
import type { LocationType } from "@owsi/catena/er2_map_userlayers/js/reducers"
import type { ContextBarItemType } from "./context_bar_item2"
import ContextBarItem, { isActive } from "./context_bar_item2"

type Props = {
    items: ContextBarItemType[],
    location: LocationType,
    onClick: Function,
    render?: Function,
    // Display the active context's icon at the top of the context item bar.
    showActiveIcon: boolean,
    theme: Theme,
    token?: { value: string },
}

// Displays the menu bar on the left, indicating the current step (or context) of the application workflow
function ContextBar(props: Props) {
    const activeItem = props.items.find((item) =>
        isActive(props.location, item),
    )
    return (
        <div className={props.theme.contextBar}>
            <div className={props.theme.contextBarTop}>
                {props.showActiveIcon && activeItem && (
                    <div className={props.theme.spacer}>
                        <ContextBarItem item={activeItem} {...props} />
                    </div>
                )}
                {props.items
                    .filter((item) => item.position !== "bottom")
                    .map((item, idx) => (
                        <ContextBarItem
                            key={item.id}
                            idx={idx}
                            item={item}
                            {...props}
                        />
                    ))}
            </div>
            <div className={props.theme.contextBarBottom}>
                {props.items
                    .filter((item) => item.position === "bottom")
                    .map((item, idx) => (
                        <ContextBarItem
                            key={item.id}
                            idx={idx}
                            item={item}
                            {...props}
                        />
                    ))}
            </div>
        </div>
    )
}

ContextBar.defaultProps = {
    render: undefined,
    showActiveIcon: false,
    token: undefined,
}

export default withRouter(ContextBar)
