// @flow
import type { IdentifyType } from "@owsi/catena/er2_map_userlayers/js/reducers/identify"
import * as types from "@owsi/catena/er2_map_userlayers/js/constants/action_types"
import type { ExtentType } from "@owsi/catena/er2_map_userlayers/js/reducers/map"
import { getLayers } from "@owsi/catena/er2_map_userlayers/js/utils"

export const beginIdentify = (layers, extent) => ({
    type: types.IDENTIFY_FEATURES_START,
    layers,
    extent,
})
export const onIdentifyHandled = () => ({
    type: types.IDENTIFY_FEATURES_HANDLED,
})
export const setIdentifyFeatures = (identifyState: IdentifyType) => ({
    type: types.IDENTIFY_FEATURES_FETCHED,
    identifyState,
})
export const setIsIdentifying = (isIdentifying) => ({
    type: types.SET_IS_IDENTIFYING,
    isIdentifying,
})

export const onIdentify = (
    extent: ExtentType,
    clickCoords: [number, number],
) => (dispatch: Function, getState: Function) => {
    const token = getState().token.value

    // Run identify asynchronously. For now start with visible layers
    const layers = getLayers(getState().mapsources)
    const layersToIdentify = layers.filter(
        (l) => l.visible && l.identifiable !== false,
    )
    dispatch(beginIdentify(layersToIdentify, extent))

    layersToIdentify.forEach((layer) => {
        fetch(`/er2_map/api/v1/identify/?token=${token}`, {
            body: JSON.stringify({
                extent,
                click_coords: clickCoords,
                layer_name: layer.name,
                mapsource_name: layer.mapsource,
            }),
            headers: new Headers({'content-type': 'application/json'}),
            method: "POST",
        })
            .then((res) => res.json())
            .then((res) => dispatch(setIdentifyFeatures(res.state)))
            .catch((err) => (res) =>
                console.error(`Got failure to identify: ${res}`),
            )
    })
}
