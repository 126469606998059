import * as types from "constants/action_types"
import { getStatusAndTrend } from "app/store/actions/birds"

const setObservationsData = (output) => ({
    type: types.SET_ATG_OBSERVATIONS_DATA,
    output,
})
export const getObservations = () => (dispatch, getState) =>
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "Observations2",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setObservationsData(json))
            }
        })

const setHydrologicConditionsData = (output) => ({
    type: types.SET_ATG_HYDROLOGIC_CONDITIONS_DATA,
    output,
})
export const getHydrology = () => (dispatch, getState) => {
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HC1",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HC2",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HC3",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HC4",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    return fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HC5",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
}
export const getUSGS = () => (dispatch, getState) => {
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS1",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS2",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS3",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
    return fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS4",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
}

export const getUSGS_1 = () => (dispatch, getState) => {
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS1",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
                console.log("Error:", json.error)
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
}

export const getUSGS_2 = () => (dispatch, getState) => {
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS2",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
}

export const getUSGS_3 = () => (dispatch, getState) => {
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS3",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
}
export const getUSGS_4 = () => (dispatch, getState) => {
    fetch(`/${getState().meta.name}/api/v1/overview/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what: "HCUSGS4",
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                // dispatch(setErrorMessage(json.error))
            } else {
                dispatch(setHydrologicConditionsData(json))
            }
        })
}

const setPsTargetsData = (output) => ({
    type: types.SET_ATG_PS_TARGETS_DATA,
    output,
})

const getPsTargets = () => (dispatch, getState) => {
    Promise.all([
        fetch(`/${getState().meta.name}/api/v1/overview/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "PsTargets",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                } else {
                    dispatch(setPsTargetsData(json))
                }
            }),
    ])
}

export const onOverviewStepChange = (step: number) => (dispatch, getState) => {
    dispatch({ type: types.SET_ATG_ACTIVESTEP, step })
    switch (step) {
        case 0:
            dispatch(getPsTargets())
            return null
        case 1:
            dispatch(getHydrology())
            return null
        case 2:
            dispatch(getStatusAndTrend())
            return null
        case 101:
            dispatch(getUSGS_1())
            return null
        case 102:
            dispatch(getUSGS_2())
            return null
        case 103:
            dispatch(getUSGS_3())
            return null
        case 104:
            dispatch(getUSGS_4())
            return null
        case 461:
            dispatch(getPsTargets())
            return null
        default:
            return null
    }
}

export const onHydroStepChange = (step: number) => (dispatch, getState) => {
    dispatch({ type: types.SET_HC_ACTIVESTEP, step })
    switch (step) {
        case 0:
            dispatch(getHydrology())
            return null
        case 1:
            dispatch(getUSGS())
            return null
        case 2:
            return null
        default:
            return null
    }
}
