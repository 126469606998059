import { StyleRules, Theme } from "@material-ui/core"
import baseStyles from "@owsi/catena/er2_styles"
import { paletteCream, paletteDarkGrey, paletteGreen } from "styles/colors.main"
import { mrrpStyles } from "styles/mrrp"

export const CONTEXT_BAR_WIDTH = 58

/**
 * TODO: this file exports a function but not a stylesheet hook
 * It seems like extra work to pass this function to the page builder theme -
 * Shouldn't we just consume the sheet from an import of `styles`?
 */

function combinedThemeStyles(
    theme: Theme
): StyleRules<string, { textColor: string }> {
    return {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            display: "flex",
            color: (props) => props.textColor,
            fontFamily: "'Roboto Condensed', sans-serif",
            background: "none",
            gridTemplateColumns: undefined,
            gridTemplateRows: undefined,
        },
        breadcrumbs: {
            ...baseStyles.breadcrumbs,
            height: 49,
        },
        breadcrumbHeader: {
            ...baseStyles.breadcrumbHeader,
        },
        breadcrumbTriangleCtr: {
            ...baseStyles.breadcrumbTriangleCtr,
            position: "absolute",
            left: "100%",
            height: 49,
            fill: theme.palette.secondary.main,
        },
        breadcrumbTriangle: {
            ...baseStyles.breadcrumbTriangle,
            filter: `
            drop-shadow(0 1px 1px rgba(0, 0, 0, 0.12))
            drop-shadow(0 2px 0px rgba(0, 0, 0, 0.14))
            drop-shadow(0 0px 4px rgba(0, 0, 0, 0.12))
            drop-shadow(0 -2px 1px rgba(0, 0, 0, 0.1))
        `,
        },
        closerHorizontal: {
            ...baseStyles.closerHorizontal,
            top: 3,
            left: 170,
        },
        closerMinimized: {
            ...baseStyles.closerMinimized,
            top: "-45px !important",
            left: 100,
        },
        contextBar: {
            ...baseStyles.contextBar,
            backgroundColor: "transparent",
            paddingTop: 64,
            paddingBottom: 64,
            height: "100%",
            zIndex: 300,
            position: "absolute",
            top: 0,
            left: 0,
            width: CONTEXT_BAR_WIDTH,
            alignItems: "center",

            "@media print": {
                display: "none",
            },
        },
        contextBarItem: {
            ...baseStyles.contextBarItem,
            color: theme.palette.grey[700],
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            padding: 15,
        },
        contextBarItemActive: {
            ...baseStyles.contextBarItemActive,
            backgroundColor: "rgba(0,0,0,0.2)",
            color: "black",
            padding: 15,
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
        },
        headerLink: {
            color: paletteCream,
            textDecoration: "none",
        },
        headerTextContent: {
            ...baseStyles.headerTextContent,
            color: paletteDarkGrey,
        },
        iconCtr: {
            cursor: "pointer",
            color: paletteDarkGrey,
            display: "block",
            padding: "10px",
            fontSize: "20px",
            textAlign: "center",
            textDecoration: "none",
        },
        link: {
            ...baseStyles.link,
            "&:hover": {
                color: paletteDarkGrey,
                textDecoration: "underline",
            },
            color: paletteDarkGrey,
        },
        tabLabel: {
            ...baseStyles.tabLabel,
            color: "#000000",
            textTransform: "capitalize",
            width: "initial",
            paddingRight: 24,
            paddingLeft: 24,
        },
        tabScrollButton: {
            color: "inherit",
        },
        ...mrrpStyles(theme),
        mapToolbarAddress: {
            backgroundColor: "rgba(255,255,255,0.95)",
            border: `2px solid ${paletteGreen}`,
            borderRadius: 6,
            position: "absolute",
            right: 0,
            top: 50,
            padding: 10,
            textAlign: "right",
            transition:
                "width 2s, height 2s, background-color 2s, transform 2s",
            "& label": {},
        },
        mapToolbarAddressTextField: {
            width: 250,
        },
        mapToolbarContainer: {
            fontSize: "20px",
            marginRight: "10px",
            paddingBottom: "6px",
            padding: "1px",
            position: "absolute",
            right: "55px",
            top: "20px",
            display: "flex",
            "& > *": {
                padding: "3px",
            },
            zIndex: 3,
        },
        overrides: {
            mapToolbarPanel: {
                alignItems: "stretch",
                backgroundColor: paletteCream,
                borderLeft: "10px solid pink",
                display: "flex",
                flexDirection: "column",
                gridColumnStart: "toolbarPanel",
                gridColumnEnd: "toolbar",
                gridRowStart: "subHeader",
                gridRowEnd: "bottom",
                overflowY: "unset",
                zIndex: 2,
            },
        },
    }
}

export default combinedThemeStyles
