import { createTheme } from "@owsi/catena-vellum"
import { lighten } from "@material-ui/core"

import appStyles from "styles"
import * as colors from "styles/colors.main"

import { App as appComponent, Loading as loadingComponent, rootSvs } from "app"

export default createTheme({
    loadingComponent,
    appComponent,
    appStyles,
    rootSvs,
    browserColor: colors.paletteBlue,
    helmetProps: {
        titleTemplate: "MRRP IMS - %s",
        defaultTitle: "MRRP Information Management System",
    },
    onCreateMuiTheme: (mui) => {
        const typography = {
            fontSize: 16,
        }

        const overrides = {
            MuiTab: {
                root: {
                    "&$selected": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                },
            },
        }

        const defaultProps = {
            MuiAccordion: {
                // Accordions are in the same plane by default
                elevation: 0,
            },
        }

        mui.override({
            typography,
            overrides,
            palette: {
                background: {
                    default: lighten(colors.paletteBlue, 0.98),
                },
            },
            props: defaultProps,
        })

        mui.addCatenaPalette(colors as any)
        mui.addCatenaTypography()
    },
})
