// @flow
// Manages attribute table display
import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faWindowClose as farWindowClose } from "@fortawesome/free-regular-svg-icons"
import AttributeTable from "@owsi/catena//er2_map_userlayers/js/components/attribute_table/attribute_table"
import * as actionTypes from "../constants/action_types"
import { getLayer } from "../utils"

library.add(farWindowClose)

export type AttributeTableType = {
    filepath: string,
    geometry: {
        // geojson
        coordinates: [],
        type: string,
    },
    header: string[],
    id: string,
    mapsource: string,
    name: string,
    properties: {
        [key]: string | number,
    },
    schema: {
        geometry?: string,
        properties: {
            [key]: string | number,
        },
    },
    // should be Feature
    type: string,
}

export type ActionType = {
    // Add an attribute table to the display list.
    attributeTable?: AttributeTableType,
    key?: string,
}

export type AttributeTablesType = {
    attributeTables: AttributeTableType[],
}

export function getAttributeTables(props) {
    return props.attributeTables.attributeTables.map((t) => (
        <AttributeTable
            attributeTable={t}
            buttons={[
                {
                    className: "window-close",
                    event: () => props.actions.onHideAttributeTable(t.key),
                },
            ]}
            key={t.name}
            mapsources={props.mapsources}
            theme={props.theme || props.classes}
            token={props.token}
            {...props.actions}
        />
    ))
}

const initialState = {
    // All the open attribute tables
    attributeTables: [],
}

export default function attributeTables(
    state: AttributeTablesType = initialState,
    action: ActionType,
) {
    let ret = state

    if (action.type === actionTypes.ATTRIBUTE_TABLE_SHOW) {
        let key = action.attributeTable.key
        if (!key) {
            // key = `${action.attributeTable.mapsource}:${action.attributeTable.name}`
            key = action.attributeTable.name
        }
        const dups = state.attributeTables.filter((a) => a.key === key)
        if (dups.length === 0) {
            // Update key
            ret = {
                ...state,
                attributeTables: [
                    ...state.attributeTables,
                    { ...action.attributeTable, key },
                ],
            }
        }
    } else if (action.type === actionTypes.ATTRIBUTE_TABLE_HIDE) {
        const itable = state.attributeTables.findIndex(
            (a) => a.key === action.key,
        )
        if (itable >= 0) {
            // Remove from list
            ret = {
                ...state,
                attributeTables: [
                    ...state.attributeTables.slice(0, itable),
                    ...state.attributeTables.slice(itable + 1),
                ],
            }
        }
    } else if (action.type === actionTypes.USER_LAYER_DELETE) {
        // Check if this layer is displayed
        const itable = state.attributeTables.findIndex(
            (a) => a.key === action.layerName,
        )
        if (itable >= 0) {
            // Remove from list
            ret = {
                ...state,
                attributeTables: [
                    ...state.attributeTables.slice(0, itable),
                    ...state.attributeTables.slice(itable + 1),
                ],
            }
        }
    } else if (action.type === actionTypes.SET_QUERYING) {
        const key = `${action.attributeTable.mapsourceName}:${action.attributeTable.layerName}`
        const itable = state.attributeTables.findIndex((a) => a.key === key)
        ret = {
            ...state,
            attributeTables: [
                ...state.attributeTables.slice(0, itable),
                { ...action.attributeTable },
                ...state.attributeTables.slice(itable + 1),
            ],
        }
    }

    return ret
}
