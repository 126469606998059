import React from "react"

import { MDXProvider } from "@mdx-js/react"
import { Typography, Box, Grid } from "@material-ui/core"

const components = {
    h1: (props) => <Typography variant="h1" {...props} />,
    h2: (props) => <Typography variant="h2" {...props} />,
    h3: (props) => <Typography variant="h3" {...props} />,
    h4: (props) => <Typography variant="h4" {...props} />,
    h5: (props) => <Typography variant="h5" {...props} />,
    h6: (props) => <Typography variant="h6" {...props} />,
    p: (props) => <Typography variant="body" {...props} />,
    Typography,
    Box,
    Grid,
}

export const MuiMdxProvider = (props) => (
    <MDXProvider {...props} components={components} />
)
