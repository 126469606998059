// @flow
const styles = {
    tabbedPanelLabelButton: {
        color: "white",
        textAlign: "center",
        borderRadius: "5px",
        padding: "5px 5px",
        ":hover": {
            backgroundColor: "rgba(20, 20, 20, 0.2)",
        },
        ":active": {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
        },
    },
    tabbledPanelMenuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
}

export default styles
