// @flow
import React, { useEffect, useMemo, useRef, useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TextField from "@material-ui/core/TextField"
import { useTable, usePagination, useSortBy } from "react-table"
import Theme from "@owsi/catena/er2_styles"
import ColumnMenu from "./column_menu"
import EditManager from "./edit_manager"
import type { AttributeTableType } from "../../reducers/attribute_tables"

type PropsType = {
    attributeTable: AttributeTableType,
    editManager: EditManager,
    editable: boolean,
    filter: string,
    height: number,
    onDeleteColumn: Function,
    onEdit: Function,
    onRename: Function,
    onShowCalculator: Function,
    onShowStatistics: Function,
    theme: Theme,
}

function isSelected(cellSelection, colIndex, rowIndex) {
    let selected
    const col0 = cellSelection[0][0]
    const row0 = cellSelection[0][1]
    if (cellSelection.length === 1) {
        selected = colIndex === col0 && rowIndex === row0
    } else {
        const col1 = cellSelection[1][0]
        const row1 = cellSelection[1][1]
        const lowCol = col0 < col1 ? col0 : col1
        const highCol = col0 > col1 ? col0 : col1
        const lowRow = row0 < row1 ? row0 : row1
        const highRow = row0 > row1 ? row0 : row1
        selected =
            colIndex >= lowCol &&
            colIndex <= highCol &&
            rowIndex >= lowRow &&
            rowIndex <= highRow
    }
    return selected
}

function AttributeTableComponent(props: PropsType) {
    const [attributeTableHeight, setAttributeTableHeight] = useState(0)
    const [cellSelection, setCellSelection] = useState([[-1, -1]])
    const [nrows, setNrows] = React.useState(1)

    const containerRef = React.createRef()
    React.useEffect(() => {
        const containerEl = containerRef.current
        if (containerEl) {
            let newNrows = 1
            const wrapperEl = containerEl.getElementsByClassName(
                "table-wrapper",
            )[0]
            const scrollbarHeight =
                wrapperEl.offsetHeight - wrapperEl.clientHeight
            const thead = containerEl.getElementsByTagName("THEAD")[0]
            const tbody = containerEl.getElementsByTagName("TBODY")[0]
            const headerHeight = thead.getBoundingClientRect().height
            const pagination = containerEl.getElementsByClassName("pagination")
            const paginationHeight = pagination[0].getBoundingClientRect()
                .height
            const containerRows = tbody.getElementsByTagName("TR")
            if (containerRows.length) {
                const rowHeight = containerRows[0].getBoundingClientRect()
                    .height
                if (rowHeight) {
                    newNrows = Math.floor(
                        (props.height -
                            headerHeight -
                            paginationHeight -
                            scrollbarHeight) /
                            rowHeight,
                    )
                    newNrows = Math.max(newNrows, 1)
                    setNrows(newNrows)
                    setPageSize(newNrows)
                    setAttributeTableHeight(
                        headerHeight + scrollbarHeight + newNrows * rowHeight,
                    )
                }
            }
        }
    }, [props.height])

    const renderCell = ({ cell }) => {
        const colIndex = props.editManager.header.indexOf(cell.column.id)
        const style = {}
        if (isSelected(cellSelection, colIndex, cell.row.index)) {
            style.backgroundColor = "lightblue"
        }
        return <div style={style}>{JSON.stringify(cell.value)}</div>
    }

    const renderEditableCell = ({ cell }) => {
        const emRows = props.editManager.rows
        let val = emRows[cell.row.index][cell.column.id]
        // Convert nulls and undefined to empty string for the input
        if (val === undefined || val === null) val = ""
        if (typeof val === "object") val = JSON.stringify(val)
        return (
            <TextField
                className={props.theme.attributeTableTextEdit}
                InputProps={{
                    classes: {
                        input: props.theme.attributeTableTextEdit,
                    },
                }}
                onBlur={(e) => {
                    let newValue = e.currentTarget.value
                    const row = emRows[cell.row.index]
                    // If the old value was an object, then keep it in that format
                    const oldval = emRows[cell.row.index][cell.column.id]
                    if (oldval === "object") newValue = JSON.parse(newValue)
                    props.editManager.addCellEdit(
                        row.id,
                        cell.column.id,
                        newValue,
                    )
                }}
                defaultValue={val}
                key={val}
            />
        )
    }

    const handleCellClick = (event, rowIndex, colIndex) => {
        if (!event.shiftKey) {
            setCellSelection([[colIndex, rowIndex]])
        } else {
            event.preventDefault()
            const firstColIndex = cellSelection[0][0]
            const firstRowIndex = cellSelection[0][1]
            setCellSelection([
                [firstColIndex, firstRowIndex],
                [colIndex, rowIndex],
            ])
        }
    }

    const selectionString = () => {
        if (cellSelection.length === 1) {
            const i = cellSelection[0][1]
            const j = cellSelection[0][0]
            return props.editManager.rows[i][props.editManager.header[j]]
        }
        const col0 = cellSelection[0][0]
        const col1 = cellSelection[1][0]
        const row0 = cellSelection[0][1]
        const row1 = cellSelection[1][1]
        const lowCol = col0 < col1 ? col0 : col1
        const highCol = col0 > col1 ? col0 : col1
        const lowRow = row0 < row1 ? row0 : row1
        const highRow = row0 > row1 ? row0 : row1

        let resultString = ""

        for (let i = lowRow; i <= highRow; i += 1) {
            for (let j = lowCol; j <= highCol; j += 1) {
                const value =
                    props.editManager.rows[i][props.editManager.header[j]]
                resultString += `${value}\t`
            }
            if (i !== highRow) resultString += "\n"
        }
        return resultString
    }

    const handleCellCopy = (event) => {
        event.stopPropagation()
    }

    const onCopyCells = () => {
        copyToClipboard(selectedCells)
    }

    const columns = useMemo(
        () =>
            props.editManager.header.map((fname) => ({
                Header: (
                    <ColumnMenu
                        editManager={props.editManager}
                        fieldname={fname}
                        onDelete={() => props.onDeleteColumn(fname)}
                        onEdit={() => props.onEdit(fname)}
                        onRename={() => props.onRename(fname)}
                        onShowCalculator={() => props.onShowCalculator(fname)}
                        onShowStatistics={() => props.onShowStatistics(fname)}
                        theme={props.theme}
                    />
                ),
                Cell: props.editable ? renderEditableCell : renderCell,
                accessor: fname,
                sortType: "basic",
            })),
        [props.editable, props.editManager.header],
    )

    const data = useMemo(
        () =>
            props.filter
                ? props.editManager.rows.filter((row) => {
                      let ok = false
                      Object.entries(row).forEach(([k, v]) => {
                          ok = ok || String(v).includes(props.filter)
                      })
                      return ok
                  })
                : props.editManager.rows,
        [props.filter, props.editManager.rows],
    )

    const instance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: nrows },
        },
        useSortBy,
        usePagination,
    )

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = instance

    return (
        <div ref={containerRef} className={props.theme.attributeTableContainer}>
            <div
                className={"table-wrapper"}
                style={{ overflow: "auto", height: attributeTableHeight }}
            >
                <Table
                    {...getTableProps()}
                    className={props.theme.attributeTable}
                    stickyHeader
                >
                    <TableHead style={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                        )}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </TableHead>
                    <TableBody>
                        {page.map(
                            (row, i) =>
                                prepareRow(row) || (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                {...cell.getCellProps()}
                                                className={
                                                    props.theme.attributeTableTD
                                                }
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                ),
                        )}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                className={"pagination"}
                component="div"
                count={data.length}
                rowsPerPage={nrows}
                page={pageIndex}
                onChangePage={(event, newPage) => {
                    gotoPage(newPage)
                }}
                rowsPerPageOptions={[nrows]}
            />
        </div>
    )
}

export default AttributeTableComponent
