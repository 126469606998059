export const styles = {
    muiFormControl: {
        flexGrow: 1,
    },
    editButton: (props) => {
        const transparent = `rgba(255, 255, 255, ${props.opacity / 100})`
        return {
            backgroundColor: transparent,
            margin: 2,
        }
    },
    featureEditBar: (props) => {
        const transparent = `rgba(255, 255, 255, ${props.opacity / 100})`
        return {
            backgroundColor: transparent,
            position: "relative",
            bottom: 20,
            borderRadius: 20,
            whiteSpace: "nowrap",
            zIndex: 3,
        }
    },
    digIcon: {
        width: "-moz-fit-content",
        fontSize: "18px",
    },
    draggableIcon: {
        width: "39px",
        height: "42px",
        fontSize: "18px",
        position: "relative",
        top: "13px",
        cursor: "grab",
    },
    draggableButton: {
        padding: "0px",
    },
    overlayAddon: {
        textAlign: "center",
    },
    copyUp: {
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        top: "-46px",
        "&:hover": {
            backgroundColor: "rgba(215, 215, 215, 0.5)",
        },
    },
    copyLeft: {
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        "&:hover": {
            backgroundColor: "rgba(215, 215, 215, 0.5)",
        },
    },
    copyRight: {
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        "&:hover": {
            backgroundColor: "rgba(215, 215, 215, 0.5)",
        },
    },
    copyDown: {
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        left: "118px",
        "&:hover": {
            backgroundColor: "rgba(215, 215, 215, 0.5)",
        },
    },
    modifyFeature: {
        textAlign: "center",
        margin: "2px",
        display: "block",
    },
    iconBar: {
        width: "fit-content",
        fontSize: 24,
        borderRadius: "0",
    },
    faIconStacked: {
        marginLeft: -22,
        marginTop: -4,
    },
    msIconStacked: {
        marginLeft: -16,
        transform: "rotate(223deg) translateX(-4px) translateY(-1px)",
    },
}

export default styles
