// @flow
import * as React from "react"

type Props = {
    children: object,
    theme?: object,
}

// Displays the top-level header bar
function Header(props: Props) {
    const renderedTheme = props.theme
    return <header className={renderedTheme.header}>{props.children}</header>
}

Header.defaultProps = {
    token: { value: "" },
    theme: {},
}

export default Header
