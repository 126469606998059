// @flow
import * as types from "../constants/action_types"

export const onChangeInput = (
    value,
    id: string,
    isValid: Function = undefined,
) => (dispatch, getState) => {
    if (isValid) {
        if (isValid(value, dispatch, getState)) {
            dispatch({ type: types.CHANGE_INPUT, id, value, state: getState() })
        }
    } else {
        dispatch({ type: types.CHANGE_INPUT, id, value, state: getState() })
    }
}

export const onClickInlineHelp = (id: string) => ({
    type: types.CLICK_HELP_INLINE,
    id,
})

export const validationError = (error: {
    type: string,
    id: string,
    message: string,
}) => ({ type: types.VALIDATION_ERROR, error })
