// @flow
import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import OlMap from "ol/Map"
import Theme from "@owsi/catena/er2_styles"
import AddColumn from "@owsi/catena/er2_map_userlayers/js/components/attribute_table/add_column"
import Calculator from "@owsi/catena/er2_map_userlayers/js/components/attribute_table/calculator"
import CreateLayer from "@owsi/catena/er2_map_userlayers/js/components/attribute_table/create_layer"
import JoinTable from "@owsi/catena/er2_map_userlayers/js/components/attribute_table/join_table"
import type { AttributeTableType } from "@owsi/catena/er2_map_userlayers/js/reducers/attribute_tables"
import type { MapType } from "@owsi/catena/er2_map_userlayers/js/reducers/map"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import QueryBuilder from "@owsi/catena/er2_ui/components/query_builder"
import AttributeTableComponent from "./attribute_table_component"
import EditManager from "./edit_manager"
import ErrorBoundary from "../error_boundary"
import OptionsBar from "./options_bar"
import { copyToClipboard } from "../../utils"

type PropsType = {
    active?: boolean,
    appendToLogger: Function,
    attributeTable: AttributeTableType,
    canClose: boolean,
    createLayerFromTable: Function,
    editManager: EditManager,
    height: number,
    joinTable: Function,
    map: MapType,
    mapsources: MapsourcesType,
    olMap: OlMap,
    onExportTable: Function,
    onShowTool: Function,
    onUserLayersZoom: Function,
    saveEdits: Function,
    token: {
        value: string,
    },
    theme: Theme,
    userLayerSelectFeatures: Function,
}

export function getAttributes(props, finished) {
    let hasError = false
    fetch(`/er2_map/get_layer_attributes/?token=${props.token.value}`, {
        body: JSON.stringify({
            layer: props.attributeTable.name,
            keepGeom: true,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((res) => {
            hasError = res.status !== 200
            if (hasError) return res.text()
            return res.json()
        })
        .then((json) => {
            if (hasError) {
                props.appendToLogger([
                    `Got failure to get layer attributes: ${json}`,
                ])
                finished([])
            } else {
                finished(json.attributes)
            }
        })
}

function AttributeTable(props: PropsType) {
    const [attributeTableHeight, setAttributeTableHeight] = useState(100)
    const [editColumn, setEditColumn] = useState()
    const [editManager, setEditManager] = useState(null)
    const [editable, setEditable] = useState(false)
    const [filter, setFilter] = useState("")
    const [selectedCells, setSelectedCells] = useState("")
    const [showAddColumn, setShowAddColumn] = useState(false)
    const [showCalculator, setShowCalculator] = useState(false)
    const [showCreateLayer, setShowCreateLayer] = useState(false)
    const [showEditColumn, setShowEditColumn] = useState(false)
    const [showJoinTable, setShowJoinTable] = useState(false)
    const [showRenameColumn, setShowRenameColumn] = useState(false)
    const [showQueryBuilder, setShowQueryBuilder] = useState(false)
    const [showStatistics, setShowStatistics] = useState(false)

    useEffect(() => {
        function buildRows(attributes) {
            // row data is nested in the properties object
            const newRows = attributes.map((attrs) => ({
                ...attrs.properties,
                id: parseInt(attrs.id),
            }))
            setEditManager(
                new EditManager(
                    newRows,
                    props.attributeTable.schema,
                    props.attributeTable.header,
                ),
            )
        }
        getAttributes(props, (attributes) => buildRows(attributes))
    }, [])

    const optionsBarRef = React.createRef()
    React.useEffect(() => {
        // add row height to state after height updates
        if (optionsBarRef.current && props.active) {
            const controlbarHeight = optionsBarRef.current.getBoundingClientRect()
                .height
            setAttributeTableHeight(
                Math.max(props.height - controlbarHeight, 0),
            )
        }
    }, [props.height, props.active, optionsBarRef])

    if (!editManager) {
        return (
            <div
                style={{
                    fontSize: 24,
                    marginTop: 40,
                    textAlign: "center",
                }}
            >
                Loading&nbsp;
                <FontAwesomeIcon icon={"spinner"} spin />
            </div>
        )
    }

    const onAddColumn = (name, type) => {
        editManager.addColumn(name, type)
        setShowAddColumn(false)
    }

    const onCalculateColumn = (formula) => {
        editManager.fieldCalculate(editColumn, formula)
        setShowCalculator(false)
    }

    const onCancelEdits = () => {
        editManager.cancelEdits()
        setEditManager(editManager)
    }

    const onCreateLayer = (mode, state) => {
        props.createLayerFromTable(
            props.attributeTable.mapsource,
            props.attributeTable.name,
            mode,
            state,
        )
        setShowCreateLayer(false)
    }

    const onCopyCells = () => {
        copyToClipboard(selectedCells)
    }

    const onDeleteColumn = (name) => {
        editManager.deleteColumn(name)
        setEditColumn("")
    }

    const onEditColumn = (name, type) => {
        editManager.editColumn(editColumn, name, type)
        setShowEditColumn(false)
    }

    const onJoinTable = (
        joinField,
        sourceLayer,
        sourceField,
        joinLayerName,
    ) => {
        props.joinTable(
            props.attributeTable.mapsource,
            props.attributeTable.name,
            joinField,
            sourceLayer,
            sourceField,
            joinLayerName,
        )
        setShowJoinTable(false)
    }

    const onExportTable = () => {
        props.onExportTable(
            props.attributeTable.mapsource,
            props.attributeTable.name,
        )
    }

    const onShowCalculator = (fname) => {
        setEditColumn(fname)
        setShowCalculator(true)
    }

    const onShowEditColumn = (fname) => {
        setEditColumn(fname)
        setShowEditColumn(true)
    }

    const onShowStatistics = (fname) => {
        setEditColumn(fname)
        setShowStatistics(true)
    }

    const onShowRename = (fname) => {
        setEditColumn(fname)
        setShowRenameColumn(true)
    }

    const onSaveEdits = () => {
        const editsToSave = editManager.saveEdits()
        if (editsToSave.length) {
            props.saveEdits(
                props.attributeTable.name,
                props.attributeTable.mapsource,
                editsToSave,
            )
            setEditManager(editManager)
            setEditable(false)
        }
    }

    const onSetEditable = (canEdit) => {
        setEditable(canEdit)
    }

    const onUndoEdit = () => {
        editManager.undoLastEdit()
        setEditManager(editManager)
    }

    if (props.active) {
        return (
            <ErrorBoundary>
                <OptionsBar
                    editManager={editManager}
                    hasSelectedCells={selectedCells.length > 0}
                    onCancelEdits={onCancelEdits}
                    onCopyCells={onCopyCells}
                    onExportTable={onExportTable}
                    onShowAddColumn={() => setShowAddColumn(true)}
                    onShowCreateLayer={() => setShowCreateLayer(true)}
                    onShowJoinTable={() => setShowJoinTable(true)}
                    onJoinTable={onJoinTable}
                    onSaveEdits={onSaveEdits}
                    onSetEditable={onSetEditable}
                    onSetFilter={(f) => setFilter(f)}
                    onShowQueryBuilder={() => setShowQueryBuilder(true)}
                    onToggleEditing={() => setEditable(!editable)}
                    onUndoEdit={onUndoEdit}
                    ref={optionsBarRef}
                    theme={props.theme}
                />
                <AttributeTableComponent
                    attributeTable={props.attributeTable}
                    editable={editable}
                    editManager={editManager}
                    filter={filter}
                    height={attributeTableHeight}
                    onDeleteColumn={onDeleteColumn}
                    onEdit={onShowEditColumn}
                    onRename={onShowRename}
                    onShowCalculator={onShowCalculator}
                    onShowStatistics={onShowStatistics}
                    theme={props.theme}
                />
                <AddColumn
                    onClose={() => setShowAddColumn(false)}
                    onAccept={onAddColumn}
                    open={showAddColumn}
                />
                <AddColumn
                    columnName={editColumn}
                    columnType={editManager.schema.properties[editColumn]}
                    onClose={() => setShowEditColumn(false)}
                    onAccept={onEditColumn}
                    open={showEditColumn}
                />
                <Calculator
                    column={editColumn}
                    onClose={() => setShowCalculator(false)}
                    onCalculate={onCalculateColumn}
                    open={showCalculator}
                    schema={editManager.schema}
                    theme={props.theme}
                />
                <CreateLayer
                    rows={editManager.rows}
                    onClose={() => setShowCreateLayer(false)}
                    onAccept={onCreateLayer}
                    schema={editManager.schema}
                    open={showCreateLayer}
                />
                <JoinTable
                    layer={props.attributeTable}
                    mapsources={props.mapsources}
                    onClose={() => setShowJoinTable(false)}
                    onAccept={onJoinTable}
                    open={showJoinTable}
                />
                <Dialog open={showQueryBuilder}>
                    <DialogTitle id="query-builder-dialog-title">
                        Query Builder
                    </DialogTitle>
                    <QueryBuilder
                        actions={{
                            appendToLogger: props.appendToLogger,
                            userLayerSelectFeatures:
                                props.userLayerSelectFeatures,
                            onUserLayersZoom: props.onUserLayersZoom,
                        }}
                        map={props.map}
                        mapsources={props.mapsources}
                        olMap={props.olMap}
                        theme={props.theme}
                        token={props.token.value}
                    />
                    <DialogActions>
                        <Button
                            onClick={() => setShowQueryBuilder(false)}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </ErrorBoundary>
        )
    }
    return null
}

AttributeTable.defaultProps = {
    active: true,
}

export default AttributeTable
