import * as actionTypes from "constants/action_types"

const initialState = {
    step: 0,
    hc_step: 0,
    observations: {
        ploverTernMetrics: [],
        eggChickInundation: [],
    },
    hydrology: {
        poolElevationGarrison: [],
        poolElevationOahe: [],
        annualRunoffs: [],
        flowReleaseGavins: [],
        flowReleasePeck: [],
        flowReleaseGarrison: [],
        annualStorage: [],
        hydroScraperDischarge: [],
        hydroScraperQuality: [],
        northernUpperWqData: [],
        lowerData: [],
        northernUpperData: [],
        southernLowerData: [],
    },
    psTargets: {
        psTargets: [],
        psCatchRateUpper: [],
        psCatchRateLower: [],
        psCatchRateLarval: [],
        psSubobjectives: [],
        psMetrics: [],
        hftPlus: [],
    },
}

export type OverviewType = typeof initialState

export default function overview(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_ATG_ACTIVESTEP:
            return {
                ...state,
                step: action.step,
            }
        case actionTypes.SET_ATG_OBSERVATIONS_DATA:
            return {
                ...state,
                observations: {
                    ...state.observations,
                    ...action.output,
                },
            }

        case actionTypes.SET_ATG_PS_TARGETS_DATA:
            return {
                ...state,
                psTargets: {
                    ...state.psTargets,
                    ...action.output,
                },
            }

        case actionTypes.SET_ATG_HYDROLOGIC_CONDITIONS_DATA:
            return {
                ...state,
                hydrology: {
                    ...state.hydrology,
                    ...action.output,
                },
            }

        case actionTypes.SET_HC_ACTIVESTEP:
            return {
                ...state,
                hc_step: action.step,
            }

        default:
            return state
    }
}
