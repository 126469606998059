// @flow
// Maptoolbar sidebar
import * as React from "react"
import clsx from "clsx"
import Fade from "@material-ui/core/Fade"
import GeoBarPanel from "../components/geo_bar_panel"
import type { GeoBarType } from "../reducers/geo_bar"
import { isGeoBarActive } from "../reducers/geo_bar"

type Props = {
    actions: {},
    children: {},
    geoBar?: GeoBarType,
    olMap: {},
    mapToolbar: {
        // eslint-disable-line react/no-unused-prop-types
        isContracted: boolean,
    },
    theme: {
        // eslint-disable-line react/no-unused-prop-types
        mapToolbarPanel: string,
        selectedStation: string,
        titleBar: string,
    },
    mapsources: {},
    show?: Boolean,
    token: {
        value: string,
        expires: number,
    },
}

function generateClassNames(props) {
    return clsx({
        [props.theme.mapToolbarPanel]: true,
        [props.theme.contracted]: props.mapToolbar.isContracted,
    })
}

class MapToolbarPanel extends React.Component<Props> {
    static defaultProps = {
        geoBar: null,
        show: true,
    }
    renderGeoBar() {
        const geoBarActive = this.props.geoBar && this.props.geoBar.active
        const active = isGeoBarActive(
            { mapToolbar: this.props.mapToolbar },
            geoBarActive,
        )
        if (active) {
            return (
                <GeoBarPanel
                    actions={this.props.actions}
                    geoBar={this.props.geoBar}
                    olMap={this.props.olMap}
                    mapsources={this.props.mapsources}
                    theme={this.props.theme}
                    token={this.props.token}
                />
            )
        }
        return null
    }

    render() {
        const classNames = generateClassNames(this.props)
        return (
            <Fade
                in={this.props.show}
                style={{ transformOrigin: "0 0 0" }}
                {...(this.props.show ? { timeout: 1000 } : {})}
            >
                <div className={classNames}>
                    {this.props.show && this.props.children}
                    {this.renderGeoBar()}
                </div>
            </Fade>
        )
    }
}

export default MapToolbarPanel
