// @flow
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import {
    getPolygonVectorLayers,
    getRasterLayers,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import * as ids from "../constants/ids"
import { getActiveTool } from "../reducers/geo_bar"
import RenderNotifications from "./tool_notifications"

type Props = {
    actions: {
        onClickInlineHelp: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitZonalStats: Function,
        onGeoBarSelectLayer: Function,
    },
    mapsources: object,
    theme: object,
}

function isValid(tool) {
    return tool.targetLayer.length > 0 && tool.boundaryLayer.length > 0
}

function GeoBarZonalStat(props: Props) {
    const tool = getActiveTool(props)
    const rasterLayers = getRasterLayers(props.mapsources)
    const targetLayers = getPolygonVectorLayers(props.mapsources)
    const { theme } = props

    const setTargetLayer = (e) => {
        const layer = targetLayers.find((l) => l.name === e.target.value)
        props.actions.onGeoBarSelectLayer(true, layer.name, tool, {
            kind: "targetLayer",
        })
    }

    const setRasterLayer = (e) => {
        const layer = rasterLayers.find((l) => l.name === e.target.value)
        props.actions.onGeoBarSelectLayer(true, layer.name, tool, {
            kind: "boundaryLayer",
        })
    }

    const zonalStat = () => {
        props.actions.onGeoBarSubmitZonalStats(
            tool.targetLayer,
            tool.boundaryLayer,
        )
    }

    function renderTool() {
        return (
            <React.Fragment>
                <div className={theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={ids.GEO_BAR_TARGET_LAYER}>
                            Target Layer
                        </InputLabel>
                        <Select
                            value={tool.targetLayer}
                            inputProps={{
                                id: ids.GEO_BAR_TARGET_LAYER,
                            }}
                            onChange={setTargetLayer}
                        >
                            {targetLayers.map((layer) => (
                                <MenuItem key={layer.name} value={layer.name}>
                                    {layer.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={ids.GEO_BAR_BOUNDARY_LAYER}>
                            Zonal Layer
                        </InputLabel>
                        <Select
                            value={tool.boundaryLayer}
                            inputProps={{
                                id: ids.GEO_BAR_BOUNDARY_LAYER,
                            }}
                            onChange={setRasterLayer}
                        >
                            {rasterLayers.map((layer) => (
                                <MenuItem key={layer.name} value={layer.name}>
                                    {layer.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={theme.toolRow}>
                    <Button
                        color={"primary"}
                        disabled={!isValid(tool) || tool.isFetching}
                        onClick={zonalStat}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Calculate Zonal Stats</span>}
                        {tool.isFetching && (
                            <span>
                                Calculating&nbsp;
                                <FontAwesomeIcon icon="spinner" spin />
                            </span>
                        )}
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={theme.geoBarCtr}>
            {renderTool(theme)}
            <RenderNotifications {...props} />
        </div>
    )
}

export default GeoBarZonalStat
