// @flow
import * as React from "react"

type Props = {
    header: {
        active?: boolean,
        getTextContent?: string,
        id: string,
        name?: string,
    },
    items?: {
        active?: boolean,
        getTextContent?: string,
        id: string,
        length: number,
        map: Function,
        name?: string,
    }[],
    theme: {
        active: string,
        banner: string,
        bannerAngle: string,
        bannerAngleCtr: string,
        bannerAngleFill: string,
        bannerText: string,
        breadcrumbTriangle: string,
        breadcrumbTriangleCtr: string,
        breadcrumbTriangleFill: string,
        breadcrumb: string,
        breadcrumbHeader: string,
        breadcrumbsWithHeader: string,
        item: string,
        secondary: string,
    },
}

/**
 * Displays a banner-style header with a series of breadcrumbs afterwards.
 * This component is useful for sections that require multiple substeps within them.
 */
function Breadcrumbs(props: Props) {
    /**
     * Returns the text content of an item, if a function has been
     * defined to provide it.  Otherwise, return a predefined template.
     */
    const getTextContent = (
        item: { getTextContent?: Function } /* , className: string */,
    ) => {
        if (item.getTextContent) {
            return item.getTextContent(props)
        }
        return <h2 className={props.theme.headerTextContent}>{item.name}</h2>
    }
    // Returns all applicable CSS classes for a breadcrumb item
    const itemClass = (item) =>
        `${props.theme.breadcrumb} ${props.theme.item} ${
            item.active ? props.theme.active : ""
        }`

    /**
     * If there are no breadcrumbs, render a pointed polygon for the header banner. Else,
     * polygon should transition smoothly into the breadcrumbs.
     */
    const coords = props.items.length ? "0,0 12.1,0 0,10" : "0,0 10,0 0,10"
    return (
        <div className={props.theme.breadcrumbs}>
            <div className={props.theme.breadcrumbHeader}>
                {getTextContent(props.header, props.theme.headerTextContent)}
            </div>
            <div className={props.theme.breadcrumbTriangleCtr}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 10 10"
                    preserveAspectRatio="none"
                    className={props.theme.breadcrumbTriangle}
                >
                    <polygon
                        className={props.theme.breadcrumbTriangleFill}
                        points={coords}
                    />
                </svg>
            </div>
            {props.items.map((item, idx) => (
                <div
                    key={`${item.name}`}
                    className={itemClass(item)}
                    onClick={() => props.onClick(item, idx)}
                    role="button"
                    tabIndex={idx}
                >
                    {getTextContent(item, props.theme.itemTextContent)}
                </div>
            ))}
        </div>
    )
}

Breadcrumbs.defaultProps = {
    className: "",
    items: [],
}

export default Breadcrumbs
