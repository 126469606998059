// @flow
import * as actionTypes from "../constants/action_types"
import { windowStates } from "../components/window_state"
import type { WindowStateType } from "../components/window_state"

export type TabbedPanelType = {
    tabkey: string,
    windowState: WindowStateType,
}

type ActionType = {
    tabkey: string,
    windowState?: WindowStateType,
}

const initialState = {
    tabkey: "",
    windowState: windowStates.minimized,
}

export default function tabbedPanel(
    state: TabbedPanelType = initialState,
    action: ActionType,
) {
    let ret = state
    if (
        action.type === actionTypes.LOG_SET ||
        action.type === actionTypes.LOG_APPEND
    ) {
        ret = {
            ...state,
            windowState:
                state.windowState === windowStates.minimized
                    ? windowStates.opened
                    : state.windowState,
        }
    } else if (action.type === actionTypes.TABBED_PANEL_KEY) {
        if (action.key !== state.tabkey) {
            ret = { ...state, tabkey: action.key }
        }
    } else if (action.type === actionTypes.TABBED_PANEL_WINDOW_STATE) {
        ret = { ...state, windowState: action.windowState }
    }

    return ret
}
