// @flow
import React from "react"
import { Fab, Fade, Menu, MenuItem } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import type { UserType } from "@owsi/catena//er2_ui/reducers/user"
import { parseLocation } from "@owsi/catena//er2_map_userlayers/js/utils"

type PropsType = {
    actions: {
        logout: Function,
    },
    defaultRoute: string,
    history: any,
    routes: {
        USER: string,
    },
    showProfile?: Boolean,
    user: UserType,
    theme: {},
}

function UserSettings(props: PropsType) {
    const parsedLoc = parseLocation(location)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const toggleMenu = (e) => {
        if (parsedLoc.path === props.routes.USER) {
            props.history.goBack()
        } else {
            setAnchorEl(e.currentTarget)
        }
    }

    const onCloseMenu = () => {
        setAnchorEl(null)
    }

    const onGotoProfile = () => {
        setAnchorEl(null)
        props.history.push(props.routes.USER)
    }
    const open = Boolean(anchorEl)

    return [
        <Fab
            key={"button"}
            onClick={toggleMenu}
            color={
                parsedLoc.path === props.routes.USER ? "primary" : "secondary"
            }
            size={"small"}
            style={{
                padding: 10,
            }}
        >
            <span>
                {props.user && props.user.id
                    ? props.user.id.toString().substring(0, 4)
                    : "anon"}
            </span>
        </Fab>,
        <Menu
            key={"menu"}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={open}
            onClose={onCloseMenu}
            TransitionComponent={Fade}
            style={{
                borderRadius: 10,
                marginTop: 10,
            }}
        >
            {parsedLoc.path !== props.routes.USER && props.showProfile && (
                <MenuItem onClick={onGotoProfile}>Profile</MenuItem>
            )}
            <MenuItem onClick={props.actions.logout}>Logout</MenuItem>
        </Menu>,
    ]
}

UserSettings.defaultProps = {
    showProfile: true,
}

export default withRouter(UserSettings)
