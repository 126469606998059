import * as actionTypes from "constants/action_types"

export type BirdModelJob = {
    id: number
    running: boolean
    inputs: {}
    outputs: {}
    files: []
}

export type BirdModelJobMap = {
    [id: string]: BirdModelJob
}

export type BirdModelState = {
    running: boolean
    runningJobs: BirdModelJob[]
    finishedJobs: BirdModelJob[]
    storedJobs: BirdModelJobMap
}

const defaultBirdModelState: BirdModelState = {
    running: false,
    runningJobs: [],
    finishedJobs: [],
    storedJobs: {},
}

function storedJobsReducer(
    state: BirdModelJobMap = {},
    action
): BirdModelJobMap {
    switch (action.type) {
        case actionTypes.STORE_BIRD_MODEL_WEB_RUNS:
            const next = { ...state }

            for (const model of action.payload) {
                next[model.id] = model
            }

            return next
        default:
            return state
    }
}

function runningJobsReducer(
    state: BirdModelJob[] = [],
    action
): BirdModelJob[] {
    switch (action.type) {
        case actionTypes.REQUEST_BIRD_MODEL_WEB_RUN:
            return [...state, action.payload]
        case actionTypes.ACK_BIRD_MODEL_WEB_RUN:
            return state.filter((it) => it !== action.payload)
        case actionTypes.STORE_BIRD_MODEL_WEB_RUNS:
            const running = new Set(state)

            for (const run of action.payload) {
                if (run.runStarted && !run.runFinished) {
                    running.add(run.id)
                }
            }

            return Array.from(running)
        default:
            return state
    }
}

function finishedJobsReducer(
    state: BirdModelJob[] = [],
    action
): BirdModelJob[] {
    switch (action.type) {
        case actionTypes.ACK_BIRD_MODEL_WEB_RUN:
            return [...state, action.payload]
        case actionTypes.REQUEST_BIRD_MODEL_WEB_RUN:
            return state.filter((it) => it !== action.payload)
        case actionTypes.STORE_BIRD_MODEL_WEB_RUNS:
            const finished = new Set(state)

            for (const run of action.payload) {
                if (run.runStarted && run.runFinished) {
                    finished.add(run.id)
                }
            }

            return Array.from(finished)
        default:
            return state
    }
}

function birdModelReducer(
    state = defaultBirdModelState,
    action
): BirdModelState {
    const storedJobs = storedJobsReducer(state.storedJobs, action)
    const runningJobs = runningJobsReducer(state.runningJobs, action)
    const finishedJobs = finishedJobsReducer(state.finishedJobs, action)

    return {
        ...state,
        storedJobs,
        runningJobs,
        finishedJobs,
        running: runningJobs.length > 1,
    }
}

export default birdModelReducer
