// @flow
import Proj4 from "proj4"
import Geojson from "ol/format/GeoJSON"
import OlGeometryType from "ol/geom/GeometryType"
import OlInteractionDraw, {
    createBox,
    createRegularPolygon,
} from "ol/interaction/Draw"
import Circle from "ol/style/Circle"
import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"
import Style from "ol/style/Style"
import Text from "ol/style/Text"
import { colors } from "@owsi/catena/er2_styles"

export const PROJ_3857 = "EPSG:3857"
export const PROJ_4326 = "EPSG:4326"

export function reprojectCoord(pt: [number, number]) {
    const ret = Proj4(PROJ_4326, PROJ_3857, pt)
    return ret
}

export const styles = {
    fill: {
        color: "rgba(11, 78, 41, 0.7)",
        opacity: 0.6,
    },
    image: {
        fill: {
            color: "rgba(255, 255, 255, 0.3)",
        },
        stroke: {
            width: 1,
            color: "blue",
        },
        radius: 7,
    },
    stroke: {
        width: 2,
        color: "rgba(11, 78, 41, 0.9)",
        radius: 1,
    },
    text: {
        font: '14px "Roboto Condensed", sans-serif',
        fill: { color: colors.primaryOne },
        offsetX: "0",
        offsetY: "0",
        stroke: { color: colors.white, width: 2 },
    },
}

/**
 * Export default OpenLayers style objects for use with maps
 */
export function getOlStyles(childStyles) {
    return new Style(
        Object.assign(
            {},
            {
                fill: new Fill(styles.fill),
                image: new Circle(
                    Object.assign({}, styles.image, {
                        fill: new Fill(styles.image.fill),
                        stroke: new Stroke(styles.image.stroke),
                    }),
                ),
                stroke: new Stroke(styles.stroke),
                text: new Text(
                    Object.assign({}, styles.text, {
                        fill: new Fill(styles.text.fill),
                        stroke: new Stroke(styles.text.stroke),
                    }),
                ),
            },
            childStyles,
        ),
    )
}

export function makeDrawPointInteraction(source, opts) {
    return new OlInteractionDraw(
        Object.assign(
            {},
            {
                source,
                type: OlGeometryType.POINT,
                style: getOlStyles(),
            },
            opts,
        ),
    )
}

export function makeDrawLineInteraction(source, opts) {
    return new OlInteractionDraw(
        Object.assign(
            {},
            {
                source,
                type: OlGeometryType.LINE_STRING,
                style: getOlStyles(),
            },
            opts,
        ),
    )
}

export function makeDrawPolygonInteraction(source, opts) {
    return new OlInteractionDraw(
        Object.assign(
            {},
            {
                source,
                type: OlGeometryType.POLYGON,
                style: getOlStyles(),
            },
            opts,
        ),
    )
}

export function makeDrawBoxInteraction(source, opts) {
    return new OlInteractionDraw(
        Object.assign(
            {},
            {
                source,
                geometryFunction: createBox(),
                type: OlGeometryType.CIRCLE,
                style: getOlStyles(),
            },
            opts,
        ),
    )
}

export function makeDrawRectangleInteraction(source, opts) {
    return new OlInteractionDraw(
        Object.assign(
            {},
            {
                source,
                geometryFunction: createRegularPolygon(4),
                type: OlGeometryType.CIRCLE,
                style: getOlStyles(),
            },
            opts,
        ),
    )
}

export function makeDrawCircleInteraction(source, opts) {
    return new OlInteractionDraw(
        Object.assign(
            {},
            {
                source,
                geometryFunction: createRegularPolygon(40),
                type: OlGeometryType.CIRCLE,
                style: getOlStyles(),
            },
            opts,
        ),
    )
}

export function writeFeature(feat, opts = {}) {
    const geojson = new Geojson()
    const featureProjection = opts.featureProjection || PROJ_3857
    const dataProjection = opts.dataProjection || PROJ_4326
    const decimals = opts.decimals || undefined
    const ret = geojson.writeFeatureObject(feat, {
        featureProjection,
        dataProjection,
        decimals,
    })
    ret.properties = {}
    return ret
}

// Create geojson objects from OL features
export function writeFeatures(feats, opts = {}) {
    const geojson = new Geojson()
    const featureProjection = opts.featureProjection || PROJ_3857
    const dataProjection = opts.dataProjection || PROJ_4326

    return geojson.writeFeaturesObject(feats, {
        featureProjection,
        dataProjection,
    })
}

// Create OL features
export function readFeatures(features, opts = {}) {
    const geojson = new Geojson()
    const featureProjection = opts.featureProjection || PROJ_3857
    const dataProjection = opts.dataProjection || PROJ_4326

    let ret = []
    let featsInput = features
    if (Array.isArray(features)) {
        featsInput = { type: "FeatureCollection", features }
    }
    try {
        ret = geojson.readFeatures(featsInput, {
            featureProjection,
            dataProjection,
        })
    } catch (err) {
        console.error(err)
    }
    return ret
}
