// @flow
export const rawStyles = {
    mapToolbarActive: {
        "&:active": {
            backgroundColor: (props) => props.secondaryTwo,
        },
        "&:hover": {
            backgroundColor: (props) => props.secondaryTwo,
        },
        color: (props) => props.white,
        backgroundColor: (props) => props.secondaryLightColor,
    },
    mapToolbarItem: {
        "&:active": {
            backgroundColor: (props) => props.secondaryTwo,
            color: (props) => props.white,
        },
        "&:hover": {
            backgroundColor: (props) => props.secondaryTwo,
            color: (props) => props.white,
        },
        "&:focus": {
            outline: "none",
        },
        borderBottom: `1px solid ${(props) => props.gray}`,
        color: (props) => props.textColor,
        cursor: "pointer",
        display: "block",
        fontSize: "20px",
        height: "24px",
        padding: "10px",
        textAlign: "center",
        textDecoration: "none",
    },
}

export default rawStyles
