import React from "react"

import { Box, makeStyles } from "@material-ui/core"
import clsx from "clsx"

type BoxProps = React.ComponentProps<typeof Box>

const useLayerStyles = makeStyles({
    root: {
        position: "absolute",
    },
    guiding: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stretch: {
        width: "100%",
        height: "100%",
    },
    nonInteractive: {
        pointerEvents: "none",
    },

    fill: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    center: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    left: {
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
    },
    right: {
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
    },
    top: {
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
    },
    bottom: {
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
    },
    "top-left": {
        top: 0,
        left: 0,
    },
    "bottom-left": {
        bottom: 0,
        left: 0,
    },
    "top-right": {
        top: 0,
        right: 0,
    },
    "bottom-right": {
        bottom: 0,
        right: 0,
    },
})

const useStackStyles = makeStyles({
    root: {
        position: "relative",
        flexGrow: 1,
        display: "flex",
    },
    horizontal: {
        width: "100%",
        maxWidth: "none",
    },
    vertical: {
        height: "100%",
    },
})

type StackProps = {
    anchor?:
        | "center"
        | "left"
        | "right"
        | "top"
        | "bottom"
        | "top-left"
        | "bottom-left"
        | "top-right"
        | "bottom-right"
    guidingChild?: string
    fillContainer?: string | boolean
    interactive?: boolean
} & BoxProps

export const Stack: React.FC<StackProps> = ({
    children,
    className,
    guidingChild = "last",
    anchor = "fill",
    interactive = true,
    fillContainer = false,
    ...rest
}) => {
    const stackStyles = useStackStyles()
    const layerStyles = useLayerStyles()

    const guidingIndex =
        typeof guidingChild === "string"
            ? guidingChild === "last"
                ? React.Children.count(children) - 1
                : 0
            : guidingChild

    const styledChildren = React.Children.map(children, (child, idx) => (
        <div
            key={idx}
            className={clsx(
                layerStyles.root,
                idx === guidingIndex && layerStyles.guiding,
                idx !== guidingIndex && layerStyles[anchor],
                fillContainer && layerStyles.stretch,
                !interactive && layerStyles.nonInteractive
            )}
        >
            {child}
        </div>
    ))

    return (
        <Box
            className={clsx(
                className,
                stackStyles.root,
                (fillContainer === true || fillContainer === "vertical") &&
                    stackStyles.vertical,
                (fillContainer === true || fillContainer === "horizontal") &&
                    stackStyles.horizontal
            )}
            {...rest}
        >
            {styledChildren}
        </Box>
    )
}
