const styles = {
    helpChildrenContainer: {
        width: "100%",
    },
    helpContainer: {
        display: "flex",
        flexFlow: "row nowrap",
    },
    helpDescription: {
        background: "rgba(0, 0, 0, 0.03)",
        border: "1px solid lightblue",
        fontSize: 13,
        fontStyle: "italic",
        margin: "6px 2px 2px 2px",
        padding: "8px",
    },
    helpIcon: {
        color: "#999999",
        cursor: "pointer",
        marginLeft: "auto",
        padding: "2px",
        verticalAlign: "middle",
        "&:focus": {
            outline: 0,
        },
    },
}

export default styles
