// @flow
import type { ExtentType } from "@owsi/catena/er2_map/src/map"
import * as actionTypes from "@owsi/catena/er2_map_userlayers/js/constants/action_types"

export type IdentifyFeatureType = {
    geometry: string,
}

export type IdentifyLayersType = {
    layer_name: string,
    features: IdentifyFeatureType[],
}

export type IdentifyType = {
    extent: ExtentType,
    layers: IdentifyLayersType[],
    identifyHandled: boolean,
    isIdentifying: boolean,
}

type ActionType = {
    identifyState: IdentifyType,
    layers: { layer_name: string, features: [] },
    type: string,
}

const initialState = {
    extent: [0, 0, 0, 0],
    layers: [],
    identifyHandled: true,
    isIdentifying: false,
}

export default function identify(
    state: IdentifyType = initialState,
    action: ActionType,
) {
    let ret = state
    if (action.type === actionTypes.IDENTIFY_FEATURES_START) {
        ret = {
            ...state,
            extent: action.extent,
            layers: action.layers.map((l) => ({
                layer_name: l.name,
                features: null,
            })),
            identifyHandled: false,
        }
    } else if (action.type === actionTypes.IDENTIFY_FEATURES_FETCHED) {
        // Replace or update layer that was just identified
        const identifyLayers = [...state.layers]
        action.identifyState.layers.forEach(({ layer_name, features }) => {
            // Look for the identified layer in the return
            const ilayer = identifyLayers.findIndex(
                (l) => l.layer_name === layer_name,
            )
            identifyLayers[ilayer].features = features
        })
        ret = {
            ...state,
            extent: action.identifyState.extent,
            layers: identifyLayers,
            identifyHandled: false,
        }
    } else if (action.type === actionTypes.IDENTIFY_FEATURES_HANDLED) {
        ret = {
            ...state,
            identifyHandled: true,
        }
    } else if (action.type === actionTypes.SET_IS_IDENTIFYING) {
        ret = {
            ...state,
            isIdentifying: action.isIdentifying,
        }
    }

    return ret
}
