// @flow
// Styles for the application root
import globals from "./globals"

function getCloserBackgroundColor(props) {
    const color =
        props.colors?.closerBackgroundColor || props.closerBackgroundColor
    if (color) {
        const r = parseInt(color.slice(1, 3), 16)
        const g = parseInt(color.slice(3, 5), 16)
        const b = parseInt(color.slice(5, 7), 16)
        return `rgba(${r}, ${g}, ${b}, ${props.mapToolbar.opacity / 100.0})`
    }
}

const parentStyles = {
    // Root styles are at the top, all other styles are organized alphabetically
    root: {
        background: "#fff",
        bottom: "0",
        color: (props) => props.textColor,
        display: "grid",
        fontFamily: "'Roboto Condensed', sans-serif",
        fontSize: 14,
        gridTemplateColumns: (props) => {
            const offset = props.theme?.contextPanelOffset || 0
            return `[left] 50px [controlBar] 150px [tocBar] ${
                175 + offset
            }px [main] 18px [breadcrumbOverflow]
            3fr [toolbarPanel] 225px [wideToolbar] 100px [toolbar] 50px [right]`
        },
        gridTemplateRows: (props) => {
            if (props.theme) {
                return `[top] 52px [subHeader] 44px [main] 2fr [analysisPanel] calc(30% - ${props.theme.analysisPanelOffset}px) [bottom]`
            }
            return "[top] 52px [subHeader] 44px [main] 2fr [analysisPanel] calc(30%) [bottom]"
        },
        left: "0",
        overflow: "hidden",
        margin: "0",
        position: "absolute",
        right: "0",
        top: "0",
    },
    analysisPanel: {
        boxShadow: "0 -2px 1px -1px rgba(0,0,0,0.2)",
        background: "#fff",
        display: "flex",
        flexFlow: "column",
        gridColumnStart: "main",
        gridColumnEnd: "right",
        gridRowStart: "analysisPanel",
        gridRowEnd: "bottom",
        maxWidth: "100%",
        overflow: "auto",
        zIndex: 2,
        "-webkitTransform": "translate3d(0,0,50px)",
    },
    closerButton: {
        backgroundColor: (props) => getCloserBackgroundColor(props),
        height: 20,
        minHeight: 20,
        minWidth: 20,
        width: 20,
    },
    closerHorizontal: {
        position: "absolute",
        top: -12,
        left: 10,
        textAlign: "center",
        zIndex: 500,
    },
    closerVertical: {
        backgroundColor: (props) => getCloserBackgroundColor(props),
        height: "30px",
        position: "absolute",
        top: "0px",
        left: "10px",
        textAlign: "center",
        verticalAlign: "middle",
        width: "20px",
        zIndex: 200,
    },
    controlBar: {
        background: "#fff",
        boxShadow: "2px 0 1px -1px rgba(0,0,0,0.2)",
        display: "flex",
        flexDirection: "column",
        gridColumnEnd: "main",
        gridColumnStart: "controlBar",
        gridRowEnd: "bottom",
        gridRowStart: "main",
        overflow: "auto",
        zIndex: 2,
    },
    controlBarBottom: {
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
    },
    controlBarTop: {
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
    },
    formGroup: {
        alignItems: "baseline",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
    },
    formGroupNoSpaceBetween: {
        alignItems: "baseline",
        display: "flex",
        flexGrow: 1,
        padding: 2,
    },
    headerTitle: {
        fontSize: 16,
        marginLeft: 20,
        marginTop: 20,
    },
    helpIcon: {
        ...globals.removeFocus,
        "&:active": {
            color: (props) =>
                props.colors?.warningDarken5 || props.warningDarken5,
        },
        "&:hover": {
            color: (props) =>
                props.colors?.warningLighten5 || props.warningLighten5,
        },
        paddingLeft: "10px",
        color: (props) => props.gray,
        cursor: "pointer",
    },
    helpIconActive: {
        color: (props) =>
            `${props.colors?.warning || props.warning} !important`,
    },
    helpInlineCtr: {
        border: (props) =>
            `1px solid ${props.colors?.warning || props.warning}`,
        borderRadius: "2px",
        margin: "10px",
        padding: "10px",
    },
    helpInline: {
        fontSize: "12px",
    },
    instructions: {
        backgroundColor: "white",
        gridColumnEnd: "right",
        gridColumnStart: "main",
        gridRowEnd: "bottom",
        gridRowStart: "subHeader",
        overflow: "auto",
        padding: "10px",
        zIndex: 10,
    },
    link: {
        "&:hover": {
            color: (props) => props.colors?.primaryTwo || props.primaryTwo,
            textDecoration: "underline",
        },
        color: (props) =>
            props.colors?.primaryOneDarken5 || props.primaryOneDarken5,
        textDecoration: "none",
    },
    loading: {
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
        gridColumnEnd: "right",
        gridColumnStart: "controlBar",
        gridRowEnd: "bottom",
        gridRowStart: "subHeader",
        justifyContent: "center",
    },
    logo: {
        height: "20px",
        padding: "15px 15px 10px 0",
    },
    logoCtr: {
        display: "inline-block",
        fontFamily: '"Roboto Condensed", sans-serif',
        fontSize: "26px",
        marginTop: "9px",
        textDecoration: "none",
    },
    logoVersion: {
        fontSize: "12px",
        padding: "0 5px",
    },
    row: {
        display: "flex",
        alignItems: "center",
        padding: "5px 0",
    },
    map: {
        height: "100%",
    },
    // Grid row and column positions should be set by placementStyles
    mapCtr: {
        gridColumnEnd: "right",
    },
    mapCtrCatena: {
        gridColumnEnd: "toolbar",
    },
    mapToolbarContainer: {
        borderRadius: "6px",
        fontSize: "20px",
        gridColumnStart: "main",
        gridRowStart: "subHeader",
        gridRowEnd: "analysisPanel",
        marginRight: "10px",
        paddingBottom: "6px",
        padding: "1px",
        position: "absolute",
        right: "4px",
        top: "12px",
        zIndex: 1,
    },
    scroll: {
        /* Prevent flex containers from squishing */
        overflowY: "auto",
    },
    tableOfContentsBar: {
        backgroundColor: (props) => props.colors?.white || props.white,
        gridColumnEnd: "main",
        gridColumnStart: "controlBar",
        gridRowEnd: "bottom",
        gridRowStart: "main",
    },
    tableOfContentsBarItem: {
        "&:active": {
            color: (props) =>
                props.colors?.primaryOneDarken5 || props.primaryOneDarken5,
        },
        "&:hover": {
            color: (props) =>
                props.colors?.primaryOneDarken5 || props.primaryOneDarken5,
        },
        borderBottom: `1px solid ${(props) => props.gray}`,
        cursor: "pointer",
        color: (props) =>
            props.colors?.primaryOneDarken5 || props.primaryOneDarken5,
        display: "block",
        fontSize: "16px",
        padding: "10px",
        textAlign: "right",
        textDecoration: "none",
    },
    titleBar: {
        ...globals.truncatedText,
        color: (props) => props.white,
        backgroundColor: (props) =>
            props.colors?.primaryOne || props.primaryOne,
        flex: "1",
        fontSize: "20px",
        height: "44px",
        lineHeight: "44px",
        margin: "0",
        maxWidth: "calc(100% - 50px)",
        overflow: "hidden",
        paddingLeft: "10px",
        textAlign: "left",
    },
    td: {
        display: "flex",
        flexBasis: "0",
        flexFlow: "row nowrap",
        flexGrow: "4",
        padding: "0.5em",
        textOverflow: "ellipsis",
        wordBreak: "break-all",
    },
    tr: {
        display: "flex",
        flexFlow: "row nowrap",
    },
}

// Placement options
const placementStyles = {
    analysisPanelClosed: {
        gridRowEnd: "bottom",
    },
    analysisPanelOpen: {
        gridRowEnd: "analysisPanel",
    },
    contextBarClosed: {
        gridColumnStart: "controlBar",
    },
    contextBarOpen: {
        gridColumnStart: "main",
    },
    headerPanelClosed: {
        gridRowStart: "top",
    },
    headerPanelOpen: {
        gridRowStart: "subHeader",
    },
    mapToolbarPanelClosed: {
        gridColumnEnd: "toolbar",
    },
    mapToolbarPanelHidden: {
        gridColumnEnd: "right",
    },
    mapToolbarPanelOpen: {
        gridColumnEnd: "toolbarPanel",
    },
    closerContextBarClosed: {
        gridColumnEnd: "right",
        gridColumnStart: "controlBar",
    },
    closerContextBarOpen: {
        gridColumnEnd: "right",
        gridColumnStart: "main",
    },
    closerMinimized: {
        gridRowEnd: "bottom",
        gridRowStart: "bottom",
    },
    closerNormal: {
        gridRowEnd: "analysisPanel",
        gridRowStart: "analysisPanel",
    },
    closerMaximized: {
        gridRowEnd: "subHeader",
        gridRowStart: "subHeader",
    },
}

const rawStyles = { ...parentStyles, ...placementStyles }

export default rawStyles
