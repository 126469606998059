// @flow
import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { Fill as OlStyleFill, Stroke as OlStyleStroke } from "ol/style"
import Snackbar from "@material-ui/core/Snackbar"
import AOISelect from "@owsi/catena/er2_map_userlayers/js/components/aoi_select"
import Map from "@owsi/catena/er2_map/src/map"
import Theme from "@owsi/catena/er2_styles"
import type { AOIstate } from "@owsi/catena/er2_map_userlayers/js/components/aoi_select"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import type { ServicesType } from "@owsi/catena/er2_map_services/js/reducers/services"
import TextField from "@material-ui/core/TextField"
import {
    getUniqueLayerName,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"

type PropsType = {
    actions: {
        extractDEM: Function,
    },
    appendToLogger: Function,
    er2Map: Map,
    mapsources: MapsourcesType,
    services: ServicesType,
    theme: Theme,
}

const onFocus = (event) => event.target.select()

const olstyle = {
    fill: new OlStyleFill({
        color: "rgba(0, 200, 0, 0)",
    }),
    stroke: new OlStyleStroke({
        color: "black",
    }),
}

function DEM(props: PropsType) {
    const [drawnFeature, setDrawnFeature] = useState()
    const [outputName, setOutputName] = useState("DEM.tif")
    const [result, setResult] = useState(false)
    const [resultRendered, setResultRendered] = useState(false)
    const [running, setRunning] = useState(false)
    const [userGeojson, setUserGeojson] = useState()

    const execute = () => {
        setRunning(true)
        let boundary = userGeojson
        if (!userGeojson) {
            boundary = drawnFeature
            if (drawnFeature.type !== "FeatureCollection") {
                boundary = {
                    type: "FeatureCollection",
                    features: [
                        {
                            ...drawnFeature,
                            properties: {},
                        },
                    ],
                }
            }
        }

        // Create an output name based on the input
        const newOutputName = getUniqueLayerName(props.mapsources, outputName)
        setOutputName(newOutputName)

        props.actions.extractDEM(boundary, newOutputName, (ret) => {
            setResult(ret.layer)
            setResultRendered(false)
            setRunning(false)
        })
    }

    const handleNewAOI = (aoiState: AOIstate) => {
        setDrawnFeature(aoiState.aoiFeature)
        setUserGeojson(aoiState.userGeojson)
    }

    function renderButtons() {
        return (
            <div className={props.theme.toolRow}>
                <Button
                    color={"primary"}
                    disabled={!drawnFeature || running}
                    onClick={execute}
                    variant={"contained"}
                >
                    {!running && <span>Extract</span>}
                    {running && (
                        <span>
                            Extracting&nbsp;
                            <i className="fas fa-sync-alt fa-spin" />
                        </span>
                    )}
                </Button>
            </div>
        )
    }

    function renderTool() {
        return (
            <div className={props.theme.toolCtr}>
                <div className={props.theme.toolRow}>
                    <AOISelect
                        er2Map={props.er2Map}
                        onSelect={handleNewAOI}
                        olstyle={olstyle}
                        theme={props.theme}
                    />
                </div>
                <div className={props.theme.toolRow} style={{ marginTop: 0 }}>
                    <TextField
                        fullWidth
                        label="Output layer name"
                        value={outputName}
                        onChange={(evt) => setOutputName(evt.target.value)}
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
            </div>
        )
    }

    function renderNotifications() {
        if (result) {
            const layerName = result.name
            const message = `Result saved to ${layerName}`
            return (
                <div>
                    <Snackbar
                        open={resultRendered === false}
                        autoHideDuration={6000}
                        onClose={() => setResultRendered(true)}
                        message={<span id="message-id">{message}</span>}
                    />
                </div>
            )
        }
        return null
    }

    return (
        <div className={props.theme.geoBarCtr}>
            <div className={props.theme.toolCtr}>
                {renderTool()}
                {renderButtons()}
            </div>
            {renderNotifications()}
        </div>
    )
}

export default DEM
