export const rawStyles = {
    suggestionsContainer: {
        flexGrow: 1,
        position: "relative",
        marginLeft: 5,
        marginRight: 5,
    },
    suggestionsContainerOpen: {
        left: 0,
        marginTop: "8px",
        overflowY: "auto",
        position: "absolute",
        right: 0,
    },
    suggestion: {
        border: "1px solid lightgray",
        display: "block",
    },
    suggestionDescription: {
        fontSize: "10px",
        paddingLeft: "22px",
        whiteSpace: "initial",
    },
    suggestionIcon: {
        color: (props) => props.textColor,
    },
    suggestionHeader: {
        boxSizing: "border-box",
        display: "inline-block",
        paddingBottom: 4,
        paddingTop: 4,
        whiteSpace: "nowrap",
        width: "auto",
    },
    suggestionHeaderContainer: {
        alignItems: "center",
        border: "1px solid lightgray",
        display: "flex",
    },
    suggestionTitle: {
        display: "inline-block",
        marginTop: 6,
        width: "100%",
    },
    suggestionTitleText: {
        fontSize: "16px",
        marginLeft: "5px",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
}

export const inputRawStyles = {
    underline: {
        "&:hover:not($disabled):before": {
            backgroundColor: `${(props) => props.primaryOne} !important`,
        },
    },
    inkbar: {
        "&:after": {
            backgroundColor: `${(props) => props.primaryOne} !important`,
        },
    },
}

export const inputStyles = inputRawStyles
export default rawStyles
