import { Box, lighten } from "@material-ui/core"

type myProps = {
    smallText?: string
    text: string
    color: string
    size?: "xs" | "sm" | "md" | "lg"
    active?: boolean
}

export function colorDotSplitText(text: string = "") {
    const split = text.match(/[a-z]+|[^a-z]+/gi) || ["", ""]
    return {
        smallText: (split[0] || "").trim(),
        text: (split[1] || "").trim(),
    } as {
        smallText: string
        text: string
    }
}

export function ColorDot(props: myProps) {
    const { smallText = "", text, color, size = "md", active = false } = props

    const sizes = {
        xs: ["1.7rem", "0.5rem", "0.7rem"],
        sm: ["2.4rem", "0.7rem", "0.9rem"],
        md: ["3.3rem", "0.9rem", "1.1rem"],
        lg: ["5rem", "1.2rem", "1.4rem"],
    }

    const sz = sizes[size]

    return (
        <Box
            borderRadius={"50%"}
            color={lighten(color, 0.7)}
            bgcolor={smallText && smallText === "NEW" ? "red" : color}
            border={active ? 3 : 0}
            boxShadow={1}
            minWidth={sz[0]}
            width={sz[0]}
            height={sz[0]}
            p={1}
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            lineHeight={sz[2]}
            margin={"auto"}
            style={{ userSelect: "none" }}
        >
            {!!smallText.length && <Box fontSize={sz[1]}>{smallText}</Box>}
            <Box fontSize={sz[2]}>
                <strong>{text}</strong>
            </Box>
        </Box>
    )
}
