// @flow
import React from "react"
import jStat from "jstat"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsExporting from "highcharts/modules/exporting"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import ReactToPrint from "react-to-print"
import StatStyles from "@owsi/catena/er2_styles/statistics"
import IconButton from "@material-ui/core/IconButton"

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts)

const countInRange = (nums, min, max) => {
    return nums.reduce((count, num) => {
        return count + (num < max && num >= min ? 1 : 0)
    }, 0)
}

const createBoxplotValues = (nums, bins) => {
    const jObj = jStat(nums)
    const result = []
    let percentage = 0.0
    for (let i = 0; i < bins; i += 1) {
        const lowPerc = percentage
        percentage = lowPerc + 1.0 / bins
        const highPerc = percentage
        let lowNum = (jObj.max() - jObj.min()) * lowPerc + jObj.min()
        let highNum = (jObj.max() - jObj.min()) * highPerc + jObj.min()
        lowNum = Math.round(10 * lowNum) / 10
        highNum = Math.round(10 * highNum) / 10
        let countMax = highNum
        if (highNum === jObj.max()) countMax += 1
        result.push([
            `${lowNum} - ${highNum}`,
            countInRange(nums, lowNum, countMax),
        ])
    }
    return result
}

type PropsType = {
    theme: StatStyles,
}

class Statistics extends React.Component {
    constructor(props: PropsType) {
        super(props)
        this.state = {
            histogramBins: 9,
            histogramBinsInput: 9,
            sorting: [{ columnName: "id", direction: "asc" }],
            sortingStateColumnExtensions: [
                { columnName: "id", sortingEnabled: true },
            ],
            tableMenuActionsAnchor: null,
            showStats: true,
        }
    }
    renderCharts() {
        const nums = this.props.values.filter((n) => n !== 0)

        const boxPlotProps = {
            chart: {
                type: "boxplot",
            },
            title: {
                text: "Box Plot",
                style: {
                    fontSize: "13px",
                    fontFamily: '"Roboto Condensed", sans-serif',
                },
            },
            plotOptions: {
                series: {
                    animation: true,
                },
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                title: {
                    text: "",
                },
            },
            yAxis: {
                title: {
                    text: "Values",
                    style: {
                        fontSize: "13px",
                        fontFamily: '"Roboto Condensed", sans-serif',
                    },
                },
            },
            series: [
                {
                    data: [
                        [
                            this.props.statObj.min(),
                            jStat.percentile(nums, 0.25),
                            this.props.statObj.median(),
                            jStat.percentile(nums, 0.75),
                            this.props.statObj.max(),
                        ],
                    ],
                    tooltip: {
                        headerFormat: "<em> </em>",
                    },
                },
            ],
        }

        const frequencyPlotProps = {
            chart: {
                type: "column",
            },
            title: {
                text: "Frequency Plot",
                style: {
                    fontSize: "13px",
                    fontFamily: '"Roboto Condensed", sans-serif',
                },
            },
            plotOptions: {
                series: {
                    animation: true,
                },
            },
            subtitle: {
                // text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>'
            },
            xAxis: {
                type: "category",
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: "13px",
                        fontFamily: '"Roboto Condensed", sans-serif',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Count",
                },
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                pointFormat: "<b>{point.y:.1f}</b>",
            },
            series: [
                {
                    name: "Population",
                    data: createBoxplotValues(nums, this.state.histogramBins),
                    dataLabels: {
                        enabled: false,
                        rotation: -90,
                        color: "#FFFFFF",
                        align: "right",
                        format: "{point.y:.1f}", // one decimal
                        y: 10, // 10 pixels down from the top
                        style: {
                            fontSize: "13px",
                            fontFamily: '"Roboto Condensed", sans-serif',
                        },
                    },
                },
            ],
        }

        setTimeout(() => {
            Highcharts.chart(
                `highchartBoxplot-${this.props.name}`,
                boxPlotProps,
            )
            Highcharts.chart(
                `highchartFrequencyplot-${this.props.name}`,
                frequencyPlotProps,
            )
        }, 100)
    }

    componentDidMount() {
        this.renderCharts()
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.values !== this.props.values ||
            prevState.histogramBins !== this.state.histogramBins
        ) {
            this.renderCharts()
        }
    }

    handleBinNumberInputChange = (num) => {
        this.setState({
            histogramBinsInput: num,
        })
    }

    handleKeyPress = (event) => {
        const key = event.which || event.keyCode
        if (key === 13) {
            this.updateBinCount()
        }
    }

    updateBinCount = () => {
        this.setState({
            histogramBins: this.state.histogramBinsInput,
        })
    }

    printPDF = () => {
        this.setState({
            openPDFWindow: true,
        })
    }

    closePDFWindow = () => {
        this.setState({
            openPDFWindow: false,
        })
    }

    render() {
        return (
            <div
                className={this.props.theme.statSummary}
                ref={(el) => {
                    this.tableRef = el
                }}
            >
                <div className={this.props.theme.statHeader}>
                    Statistics for {this.props.name}
                    <IconButton
                        className={this.props.theme.xCloseButton}
                        onClick={this.props.onClose}
                        theme={this.props.theme}
                    >
                        <i className="fas fa-times" />
                    </IconButton>
                </div>
                <table className={this.props.theme.statsLayoutTable}>
                    <tbody>
                        <tr>
                            <td className={this.props.theme.leftCell}>
                                <table className={this.props.theme.statTable}>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">
                                                <div
                                                    className={
                                                        this.props.theme
                                                            .statTitle
                                                    }
                                                >
                                                    Summary Statistics
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th
                                                className={
                                                    this.props.theme
                                                        .statHeaderCell
                                                }
                                            >
                                                Statistic
                                            </th>
                                            <th
                                                className={
                                                    this.props.theme
                                                        .statHeaderCell
                                                }
                                            >
                                                Value
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number</td>
                                            <td>
                                                {this.props.values
                                                    ? this.props.values.length
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sum</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              this.props.statObj.sum(),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Min</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              this.props.statObj.min(),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Max</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              this.props.statObj.max(),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Mean</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              this.props.statObj.mean(),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Median</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              this.props.statObj.median(),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                display:
                                                    this.props.statObj.mode() !==
                                                        undefined &&
                                                    this.props.statObj.mode()
                                                        .length === undefined
                                                        ? "default"
                                                        : "none",
                                            }}
                                        >
                                            <td>Mode</td>
                                            <td>
                                                {this.props.values
                                                    ? this.props.statObj.mode()
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5th Pct.</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              jStat.percentile(
                                                                  this.props
                                                                      .values,
                                                                  0.05,
                                                              ),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10th Pct.</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              jStat.percentile(
                                                                  this.props
                                                                      .values,
                                                                  0.1,
                                                              ),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>90th Pct.</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              jStat.percentile(
                                                                  this.props
                                                                      .values,
                                                                  0.9,
                                                              ),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>95th Pct.</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              jStat.percentile(
                                                                  this.props
                                                                      .values,
                                                                  0.95,
                                                              ),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Standard Deviation</td>
                                            <td>
                                                {this.props.values
                                                    ? Math.round(
                                                          100 *
                                                              this.props.statObj.stdev(),
                                                      ) / 100
                                                    : -1}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td className={this.props.theme.rightCell}>
                                <div className={this.props.theme.boxPlotBox}>
                                    <div
                                        className={this.props.theme.boxPlotBox}
                                        id={`highchartBoxplot-${this.props.name}`}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className={this.props.theme.cell}>
                                <div>
                                    <div
                                        className={
                                            this.props.theme.frequencyPlotBox
                                        }
                                        id={`highchartFrequencyplot-${this.props.name}`}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                colSpan="2"
                                className={this.props.theme.bottomRow}
                            >
                                Number of bins:&nbsp;&nbsp;&nbsp;
                                <TextField
                                    type="number"
                                    value={this.state.histogramBinsInput}
                                    onChange={(e) => {
                                        this.handleBinNumberInputChange(
                                            e.target.value,
                                        )
                                    }}
                                    onKeyPress={this.handleKeyPress.bind(this)}
                                    inputProps={{
                                        className: this.props.theme.binsInput,
                                    }}
                                />
                                &nbsp;&nbsp;
                                <Button
                                    className={this.props.theme.updateButton}
                                    onClick={this.updateBinCount}
                                >
                                    Update
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            variant="contained"
                            className={this.props.theme.PDFbutton}
                        >
                            PDF
                        </Button>
                    )}
                    content={() => this.tableRef}
                />
            </div>
        )
    }
}

export default Statistics
