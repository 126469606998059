// @flow
import Cookies from "js-cookie"
import * as types from "../constants/action_types"

export const userError = (exception) => ({
    type: types.USER_ERROR,
    exception,
})
export const userErrorHandled = () => ({ type: types.USER_ERROR_HANDLED })
export const userMessage = (message) => ({
    type: types.USER_MESSAGE,
    message,
})
export const userMessageHandled = () => ({
    type: types.USER_MESSAGE_HANDLED,
})
const remove = (user) => ({ type: types.REMOVE_RECEIVE, user })
const register = (user) => ({ type: types.REGISTRATION_RECEIVE, user })
export const updateUser = (user) => ({ type: types.USER_UPDATE, user })

const fetchWithUserError = (url, args, dispatch, success, method) => {
    let fetchError = false
    dispatch(userErrorHandled())
    fetch(url, {
        method: method || "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
            "X-Requested-With": "XMLHttpRequest",
        },
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(args),
    })
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                dispatch(userError(json.exception))
            } else {
                success(json)
            }
        })
}

export const onRemoveUser = (user) => (dispatch, getState) => {
    fetchWithUserError("/login/api/v1/remove/", user, dispatch, (json) => {
        dispatch(remove(json.user))
    })
}

export const onRegistrationUpdate = (user) => (dispatch, getState) => {
    fetchWithUserError(
        "/login/api/v1/registration_update/",
        user,
        dispatch,
        (json) => {
            dispatch(register(json.user))
            dispatch(userMessage("User successfully updated"))
        },
        "PATCH",
    )
}

export const onLogout = () => (dispatch, getState) => {
    fetch("/login/logout/", {
        method: "POST",
    }).then((response) => {
        window.location = "/login/"
    })
}
