import { AnyObject } from "./types"
import { colorDotSplitText } from "../components/ColorDot"

export interface IYearRecord {
    year: number
}

// TODO: you might want to check the typeof range()
export const getYearRange = <T extends IYearRecord>(arr: T[]) => {
    if (!arr || arr.length === 0) return undefined
    return {
        minYear: arr.reduce(
            (min, item) => (item.year < min ? item.year : min),
            arr[0].year
        ),
        maxYear: arr.reduce(
            (max, item) => (item.year > max ? item.year : max),
            arr[0].year
        ),
    }
}

export function buildSeqArr(count: number) {
    if (!count) return []
    return Array.from(Array(count).keys())
}

export function buildArrLeadingTo(target: number, length: number) {
    const lenArr = buildSeqArr(length)
    return lenArr.map((i) => target - length + i + 1)
}

export function uniqueArray(arr: any[]) {
    return arr.filter((value, index, self) => self.indexOf(value) === index)
}

export function buildArrayBetween(low: number, high: number) {
    const l = low < high ? Math.round(low) : Math.round(high)
    const h = (low < high ? Math.round(high) : Math.round(low)) + 1

    const seq = buildSeqArr(h - l)
    return seq.map((val) => val + l)
}

export function alphaSort(arr: number[]) {
    return arr.sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
    })
}

export function superSortObj(arr: AnyObject[], key: string) {
    if (!arr) return []
    if (arr.length < 2) return arr
    return arr.sort((a, b) => {
        const c = isNaN(Number(a[key])) ? a[key] : Number(a[key])
        const d = isNaN(Number(b[key])) ? b[key] : Number(b[key])

        if (typeof c === "number" && typeof d === "string") return -1
        if (typeof d === "number" && typeof c === "string") return 1

        if (c < d) return -1
        if (c > d) return 1
        return 0
    })
}

// function sortableLabel(label: string) {
//     if (isNaN(Number(label))) {
//         const split = colorDotSplitText(label)
//         const t = Number(split.smallText)
//         const n = Number(split.text)
//         if (isNaN(n)) {
//             return isNaN(t) ? label : t
//         }
//         return n
//     }
//     return Number(label)
// }
//
// export function superSortLabel(arr: AnyObject[]) {
//     if (!arr) return []
//     if (arr.length < 2) return arr
//     return arr.sort((a, b) => {
//         const c = sortableLabel(a.label)
//         const d = sortableLabel(b.label)
//
//         if (typeof c === "number" && typeof d === "string") return -1
//         if (typeof d === "number" && typeof c === "string") return 1
//
//         if (c < d) return -1
//         if (c > d) return 1
//         return 0
//     })
// }

function splitCast(label: string) {
    const split = colorDotSplitText(label)
    const a = Number(split.smallText)
    const b = Number(split.text)

    const result = [a, b] as [number | string, number | string]
    if (isNaN(a)) result[0] = split.smallText
    if (isNaN(b)) result[1] = split.text

    return result
}

function isNumAndString(a: any, b: any) {
    return typeof a === "number" && typeof b === "string"
}

export function superSortLabel(arr: { label: string; [x: string]: any }[]) {
    if (!arr) return []
    if (arr.length < 2) return arr

    return arr.sort((a, b) => {
        const c = splitCast(a.label)
        const d = splitCast(b.label)

        if (isNumAndString(c[0], d[0])) return -1
        if (isNumAndString(d[0], c[0])) return 1

        if (c[0] < d[0]) return -1
        if (c[0] > d[0]) return 1

        if (isNumAndString(c[1], d[1])) return -1
        if (isNumAndString(d[1], c[1])) return 1

        if (c[1] < d[1]) return -1
        if (c[1] > d[1]) return 1

        return 0
    })
}

export function toArray(item?: any[] | string | number | AnyObject) {
    if (!item) return []
    if (Array.isArray(item)) return item
    return [item]
}
