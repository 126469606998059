import * as types from "constants/action_types"
import * as ids from "constants/ids"
import { onLogout } from "app/store/actions/server_state"

export const onClickContextBarItem =
    (id: string, active: boolean) => (dispatch, getState) => {
        dispatch({ type: types.CLICK_CONTEXT_BAR_ITEM, id, active })

        if (id === ids.LOGOUT) {
            dispatch(onLogout())
        }
        // if (id === ids.MAIL) {
        //     window.open("mailto:mrrp.ims@usace.army.mil")
        // }
    }

export const setContextBarVisibility =
    (payload: { context?: boolean; toc?: boolean }) => (dispatch, getState) => {
        dispatch({ type: types.SET_CONTEXT_BAR_VISIBILITY, payload })
    }

export const toggleContextBarVisibility =
    (payload: { context?: boolean; toc?: boolean }) => (dispatch, getState) => {
        dispatch({ type: types.TOGGLE_CONTEXT_BAR_VISIBILITY, payload })
    }

export const filterContextBarItems = () => (dispatch, getState) => {
    const { contextBar, project, meta } = getState()
    const site = project.IMS_SITE
    const user = meta.user

    if (!site) {
        // Loading
        return
    }

    const filteredItems = contextBar.items.filter((item) => {
        let conditional = false
        if (item.devOnly) {
            conditional = true
            if (site === "Dev") return true
        }

        if (item.betaOnly) {
            conditional = true
            if (site === "Beta") return true
        }

        if (item.userOnly) {
            conditional = true
            if (user !== undefined) return true
        }

        return conditional ? false : true
    })
    dispatch({ type: types.CHANGE_CONTEXT_BAR_ITEMS, payload: filteredItems })
}
