// @flow
import stringify from "json-stringify-safe"
import {
    appendToLogger,
    onUpdateUserLayers,
} from "@owsi/catena/er2_map_userlayers/js/actions"
import * as ids from "../constants/ids"
import * as types from "../constants/action_types"
import { getActiveTool } from "../reducers/geo_bar"
import { onChangeInput } from "./form"

export const onPostClickGeoBarItem = (id: string, state) => ({
    type: types.POST_CLICK_GEO_BAR_ITEM,
    id,
    state,
})

export const onClickGeoBarItem = (id: string) => (dispatch, getState) => {
    dispatch({ type: types.CLICK_GEO_BAR_ITEM, id })
    dispatch(onPostClickGeoBarItem(id, getState()))
}

export const onGeoBarChangeSearchValue = (value: string) => ({
    type: types.GEO_BAR_CHANGE_SEARCH_VALUE,
    value,
})

export const onGeoBarSuggestionsFetchRequested = (value: string) => ({
    type: types.GEO_BAR_SUGGESTIONS_FETCH_REQUESTED,
    value,
})

export const onGeoBarSelectTool = (tool: {}) => ({
    type: types.GEO_BAR_SELECT_TOOL,
    tool,
})

export const onGeoBarSuggestionsClearRequested = () => ({
    type: types.GEO_BAR_SUGGESTIONS_CLEAR_REQUESTED,
})

export const onGeoBarSelectLayer = (
    isSelected: boolean,
    layer,
    tool,
    opts = {},
) => ({ type: types.GEO_BAR_SELECT_LAYER, isSelected, layer, tool, opts })

export const setGeoBarFetchingOperation = (
    isFetching: boolean,
    id: string,
) => ({ type: types.GEO_BAR_FETCHING_OPERATION, isFetching, id })

export const setGeoBarFetchedOperationError = (id: string, error) => (
    dispatch,
) => {
    dispatch(appendToLogger(error))
    dispatch({ type: types.FETCH_ERROR, id, error })
}

export const setGeoBarFetchedOperation = (result, id: string) => (dispatch) => {
    dispatch({
        type: types.GEO_BAR_FETCHED_OPERATION,
        kind: result.kind,
        result: result.result,
        id,
    })
    dispatch(onUpdateUserLayers())
}

export const onGeoBarSubmit = (operation, toolId, payload) => (
    dispatch,
    getState,
) => {
    let fetchError = false
    const tool = getActiveTool(getState())
    if (!tool.isFetching) {
        dispatch(setGeoBarFetchingOperation(true, toolId))
        const token = getState().token.value
        fetch(`/er2_geoprocessing/api/v1/${operation}/?token=${token}`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: stringify(payload),
        })
            .then((response) => {
                fetchError = !response.ok
                return response.json()
            })
            .then((json) => {
                dispatch(setGeoBarFetchingOperation(false, toolId))
                if (fetchError) {
                    dispatch(setGeoBarFetchedOperationError(toolId, json))
                } else {
                    dispatch(setGeoBarFetchedOperation(json, toolId))
                }
            })
            .catch((error) =>
                dispatch(
                    setGeoBarFetchedOperationError(toolId, {
                        exception: error,
                    }),
                ),
            )
    }
}

export const onGeoBarSubmitIntersect = (layers, outputName) =>
    onGeoBarSubmit("vector/intersect", ids.GEO_BAR_INTERSECT, {
        layers,
        outputName,
    })

export const onGeoBarSubmitUnion = (layers, outputName) =>
    onGeoBarSubmit("vector/union", ids.GEO_BAR_UNION, {
        layers,
        outputName,
    })

export const onGeoBarSubmitBuffer = (layer, distance, outputName) =>
    onGeoBarSubmit("vector/buffer", ids.GEO_BAR_BUFFER, {
        distance,
        layer,
        outputName,
    })

export const onGeoBarSubmitCreateLayer = (layerType, outputName) =>
    onGeoBarSubmit("vector/create", ids.GEO_BAR_DIGITIZE_CREATE, {
        outputName,
        type: layerType,
    })

export const onGeoBarSubmitDissolve = (target, key, outputName) =>
    onGeoBarSubmit("vector/dissolve", ids.GEO_BAR_DISSOLVE_KEY, {
        target,
        key,
        outputName,
    })

export const onGeoBarSubmitClip = (target, boundary, outputName) =>
    onGeoBarSubmit("vector/clip", ids.GEO_BAR_CLIP, {
        boundary,
        outputName,
        target,
    })

export const onGeoBarSubmitZonalStats = (target, raster) =>
    onGeoBarSubmit("raster/stats", ids.GEO_BAR_ZONAL_STATS, {
        raster,
        target,
    })

export const onGeoBarFetchClassifications = (
    targetLayer,
    classificationType,
    classificationCount,
) =>
    onGeoBarSubmit("raster/classifications", ids.GEO_BAR_CLASSIFICATIONS, {
        raster: targetLayer,
        classificationType,
        classificationCount,
    })

export const onGeoBarSubmitReclassify = (raster, classifications, outputName) =>
    onGeoBarSubmit("raster/reclass", ids.GEO_BAR_RECLASS, {
        raster,
        remap: classifications,
        outputName,
    })

export const onGeoBarSubmitCalculate = (layers, expression, outputRaster) =>
    onGeoBarSubmit("raster/calc", ids.GEO_BAR_CALCULATOR, {
        rasters: layers,
        expression,
        outputRaster,
    })

export const onGeoBarSubmitDigitize = (geojson, layerName, path) =>
    onGeoBarSubmit("geojson", ids.GEO_BAR_DIGITIZE_CREATE, {
        geojson,
        layerName,
        path,
    })

export const onGeoBarSubmitDigitizeSave = (layerName, geojson) =>
    onGeoBarSubmit("geojson-save", ids.GEO_BAR_DIGITIZE_CREATE, {
        geojson,
        layerName,
    })

export const onGeoBarFetchKnownBoundaries = (boundary, boundaryType) =>
    onGeoBarSubmit("known-boundaries", ids.GEO_BAR_FETCH_KNOWN_BOUNDARIES, {
        extent: boundary,
        boundaryType,
    })

export const onGeoBarFetchKnownBoundary = (boundaryId, boundaryType) => (
    dispatch,
    getState,
) => {
    let fetchError = false
    const tool = getActiveTool(getState())
    dispatch(onChangeInput(boundaryId, ids.GEO_BAR_DIG_KNOWN_BOUNDARY))
    if (!tool.isFetchingKnownBoundary) {
        dispatch(
            setGeoBarFetchingOperation(true, ids.GEO_BAR_FETCH_KNOWN_BOUNDARY),
        )
        fetch(`/er2_map/api/v1/known-boundaries/${boundaryType}/${boundaryId}`)
            .then((response) => {
                fetchError = !response.ok
                return response.json()
            })
            .then((json) => {
                dispatch(
                    setGeoBarFetchingOperation(
                        false,
                        ids.GEO_BAR_FETCH_KNOWN_BOUNDARY,
                    ),
                )
                if (fetchError) {
                    dispatch(
                        setGeoBarFetchedOperationError(
                            ids.GEO_BAR_FETCH_KNOWN_BOUNDARY,
                            json,
                        ),
                    )
                } else {
                    dispatch(
                        setGeoBarFetchedOperation(
                            json.geojson,
                            ids.GEO_BAR_FETCH_KNOWN_BOUNDARY,
                        ),
                    )
                }
            })
            .catch((error) =>
                dispatch(
                    setGeoBarFetchedOperationError(
                        ids.GEO_BAR_CALCULATOR,
                        error,
                    ),
                ),
            )
    }
}

export const onGeoBarResultRendered = (id: string) => (dispatch) => {
    dispatch({ type: types.GEO_BAR_RESULT_RENDERED, id })
}

export const onGeoBarFetchErrorRendered = (id: string) => ({
    type: types.GEO_BAR_FETCH_ERROR_RENDERED,
    id,
})
