// @flow
import * as actionTypes from "@owsi/catena/er2_map_userlayers/js/constants/action_types"
import { getCurrentTimestamp } from "@owsi/catena/er2_map_userlayers/js/utils"
import * as wsTypes from "../constants/action_types"

export const onGetSharingState = () => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        timestamp: getCurrentTimestamp(),
        owner: getState().user.id,
    }
    emit(wsTypes.WS_ROOMS_STATE, payload)
}

export const onBootUser = (room, user) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        owner: getState().user.id,
        timestamp: getCurrentTimestamp(),
        room: room.room,
        user,
    }
    emit(wsTypes.WS_BOOT_USER, payload)
}

export const onCloseRoom = (name) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        timestamp: getCurrentTimestamp(),
        room: name,
    }
    emit(wsTypes.WS_CLOSE_ROOM, payload)
}

export const onCreateRoom = (name, users) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        timestamp: getCurrentTimestamp(),
        owner: getState().user.id,
        room: name,
        invitedUsers: users,
    }
    emit(wsTypes.WS_CREATE_ROOM, payload)
}

export const onJoinRoom = (room: string) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        owner: getState().user.id,
        timestamp: getCurrentTimestamp(),
        room,
    }
    emit(wsTypes.WS_JOIN_ROOM, payload)
}

export const onLeaveRoom = (room: string) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        owner: getState().user.id,
        timestamp: getCurrentTimestamp(),
        room,
    }
    emit(wsTypes.WS_LEAVE_ROOM, payload)
}

export const onSendChat = (room, message) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        timestamp: getCurrentTimestamp(),
        owner: getState().user.id,
        room,
        message,
    }
    emit(wsTypes.WS_SEND_CHAT, payload)
}

export const onSharingSetPushMapView = (pushable) => ({
    type: wsTypes.SET_SHARING_SET_PUSH_MAP_VIEW,
    pushable,
})
export const onMapView = (mapView) => (dispatch, getState, { emit }) => {
    const sharing = getState().sharing
    if (sharing.canPushMapView) {
        // Broadcast new extent to all members of the room.
        const payload = {
            applicationId: getState().meta.name,
            timestamp: getCurrentTimestamp(),
            owner: getState().user.id,
            mapView,
        }
        emit(wsTypes.WS_MAP_VIEW, payload)
    }
}

export const onSharingSetLayersSharable = (sharable) => ({
    type: wsTypes.SET_SHARING_LAYERS_SHARABLE,
    sharable,
})
export const onShareLayers = (layers) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        timestamp: getCurrentTimestamp(),
        owner: getState().user.id,
        layers,
    }
    emit(wsTypes.WS_SHARE_LAYERS, payload)
}

export const onUnshareLayers = (layers) => (dispatch, getState, { emit }) => {
    const payload = {
        applicationId: getState().meta.name,
        timestamp: getCurrentTimestamp(),
        owner: getState().user.id,
        layers,
    }
    emit(wsTypes.WS_UNSHARE_LAYERS, payload)
}
