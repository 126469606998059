// @flow
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import {
    getUniqueLayerName,
    getVectorLayers,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import * as ids from "../constants/ids"
import * as actionTypes from "../constants/action_types"
import { getActiveTool } from "../reducers/geo_bar"
import { validationError } from "../actions/form"

type Props = {
    actions: {
        onChangeInput: Function,
        onClickInlineHelp: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitBuffer: Function,
        onGeoBarSelectLayer: Function,
        onUpdateUserLayers: Function,
    },
    mapsources: {},
    theme: {},
}

function isValid(tool) {
    return tool.targetLayer.length > 0 && tool.distance !== 0
}

function isValidDistance(distance, dispatch, getState) {
    const castDistance = parseInt(distance)
    if (castDistance === "NaN" || castDistance < 0) {
        const error = {
            id: ids.GEO_BAR_BUFFER,
            type: actionTypes.VALIDATION_ERROR_INVALID_FIELD,
            message: "Please provide a valid distance",
        }
        dispatch(validationError(error))
        return false
    }
    return true
}

function GeoBarBuffer(props: Props) {
    const [outputName, setOutputName] = React.useState("")

    const onFocus = (event) => event.target.select()

    const setLayer = (e) => {
        const tool = getActiveTool(props)
        const layers = getVectorLayers(props.mapsources)
        const layer = layers.find((l) => l.name === e.target.value)
        // Create an output name based on the input
        const [basename, ext] = splitExt(layer.name)
        const addExt = ext || ".json"
        setOutputName(
            getUniqueLayerName(props.mapsources, `${basename} (buffered)`) +
                addExt,
        )
        props.actions.onGeoBarSelectLayer(true, layer.name, tool, {
            kind: "targetLayer",
        })
    }

    const buffer = () => {
        const tool = getActiveTool(props)
        // Create an output name based on the input
        const [basename, ext] = splitExt(outputName)
        const newOutputName = getUniqueLayerName(props.mapsources, basename)
        setOutputName(newOutputName)
        props.actions.onGeoBarSubmitBuffer(
            tool.targetLayer,
            tool.distance,
            newOutputName + ext,
        )
    }

    function renderTool() {
        const tool = getActiveTool(props)
        const layers = getVectorLayers(props.mapsources).filter(
            (l) => l.row_count > 0,
        )
        return (
            <div className={props.theme.toolCtr}>
                <div className={props.theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="layer">Layer</InputLabel>
                        <Select
                            onChange={setLayer}
                            inputProps={{
                                name: "layer",
                            }}
                            value={tool.targetLayer}
                        >
                            {layers.map((l) => (
                                <MenuItem key={l.name} value={l.name}>
                                    {l.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        id={ids.GEO_BAR_BUFFER_DISTANCE}
                        label="Distance (m)"
                        value={tool.distance}
                        onChange={(evt) =>
                            props.actions.onChangeInput(
                                evt.target.value,
                                ids.GEO_BAR_BUFFER_DISTANCE,
                            )
                        }
                        onFocus={onFocus}
                        type="number"
                        margin="normal"
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Output layer name"
                        value={outputName}
                        onChange={(evt) => setOutputName(evt.target.value)}
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <Button
                        color="primary"
                        disabled={!isValid(tool) || tool.isFetching}
                        onClick={buffer}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Buffer</span>}
                        {tool.isFetching && (
                            <span>
                                Buffering&nbsp;{" "}
                                <FontAwesomeIcon icon={"spinner"} spin />
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        )
    }

    return <div className={props.theme.geoBarCtr}>{renderTool()}</div>
}

export default GeoBarBuffer
