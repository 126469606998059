import { colors } from "@owsi/catena/er2_styles"

export const rawStyles = {
    container: {
        flexGrow: 1,
        position: "relative",
        marginLeft: "5px",
        marginRight: "5px",
    },
    suggestionsContainerOpen: {
        left: 0,
        overflowY: "auto",
        marginTop: "8px",
        maxHeight: "200px",
        position: "absolute",
        right: 0,
    },
    suggestion: {
        display: "block",
    },
    suggestionDescription: {
        fontSize: "10px",
        paddingLeft: "22px",
    },
    suggestionIcon: {
        color: colors.textColor,
    },
    suggestionTitleText: {
        fontSize: "16px",
        marginLeft: "5px",
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
}

export const inputRawStyles = {
    underline: {
        "&:hover:not($disabled):before": {
            backgroundColor: `${colors.primaryOne} !important`,
        },
    },
    inkbar: {
        "&:after": {
            backgroundColor: `${colors.primaryOne} !important`,
        },
    },
}

export const inputStyles = inputRawStyles
export default rawStyles
