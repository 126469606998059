// @flow
const styles = {
    statsLayoutTable: {
        width: "100%",
        fontSize: "13px",
        borderCollapse: "collapse",
    },
    statTable: {
        borderCollapse: "collapse",
    },
    statsBoxPlotBox: {
        width: "100%",
        height: "250px",
    },
    frequencyPlotBox: {
        height: "200px",
    },
    statSummary: {
        position: "absolute",
        backgroundColor: "white",
        fontFamily: '"Roboto Condensed", sans-serif',
        left: "50px",
        top: "96px",
        padding: "10px 10px 25px 10px",
        border: "1px solid #e3eaed",
        zIndex: "1100",
    },
    statTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
        paddingBottom: "10px",
    },
    cell: {
        border: "1px solid #ccc",
        padding: "5px",
    },
    leftCell: {
        width: "150px",
        border: "1px solid #ccc",
        padding: "5px",
    },
    rightCell: {
        width: "200px",
        border: "1px solid #ccc",
    },
    bottomRow: {
        paddingTop: "10px",
    },
    statHeader: {
        textTransform: "uppercase",
        margin: "-10px -10px 10px -10px",
        backgroundColor: "#12A4B6",
        color: "#FFF",
        textAlign: "center",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "15px",
    },
    binsInput: {
        fontSize: "14px",
        width: "40px",
    },
    PDFbutton: {
        position: "absolute",
        fontSize: "14px",
        bottom: "0",
        left: "0",
    },
    updateButton: {
        fontSize: "13px",
    },
    closeStatsButton: {
        position: "absolute",
        fontSize: "14px",
        bottom: "0",
        right: "0",
    },
}

export default styles
