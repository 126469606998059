// @flow
import * as React from "react"
import { compare } from "@owsi/catena/er2_map_userlayers/js/utils"
import * as ids from "../constants/ids"
import GeoSearch from "../components/geo_bar_search"
import type { GeoBarType } from "../reducers/geo_bar"

type Props = {
    actions: {
        onClickInlineHelp: Function,
        onGeoBarChangeSearchValue: Function,
        onGeoBarSelectTool: Function,
        onGeoBarSuggestionsFetchRequested: Function,
        onGeoBarSuggestionsClearRequested: Function,
    },
    geoBar: GeoBarType,
    onClickInlineHelp: Function,
    onGeoBarChangeSearchValue: Function,
    onGeoBarSelectTool: Function,
    onGeoBarSuggestionsFetchRequested: Function,
    onGeoBarSuggestionsClearRequested: Function,
    mapsources: object,
    mapToolbar: MapToolbarType,
    theme?: {
        mapToolbarPanel: string,
        selectedStation: string,
        titleBar: string,
    },
    token: {
        value: string,
        expires: number,
    },
}

export function getActiveTool(props) {
    return props.geoBar.tools.find((tool) => tool.id === props.geoBar.tool)
}

/*
 * Returns true if the given layer has the same geometry type as the other selected layers.
 *
 * For instance, if the first selected layer is of type "Polygon", the function will return true
 * only if the given layer is either of type "Polygon" or "MultiPolygon".
 */
export function matchesSelectedGeometryType(
    layerToCheck,
    selectedLayerIds,
    layers,
) {
    if (selectedLayerIds.length === 0) {
        // No layers have been selected, so all geometry types are valid
        return true
    }
    const selLayer = layers.find((lyr) => lyr.name === selectedLayerIds[0])

    return (
        (layerToCheck.layer_type || "").toLowerCase().replace("multi", "") ===
        (selLayer.layer_type || "").toLowerCase().replace("multi", "")
    )
}

class GeoBarPanel extends React.Component<Props> {
    renderSearch(theme) {
        const helpActive = this.props.geoBar.searchComponent.helpActive
        if (this.props.geoBar.search.active) {
            return (
                <div className={theme.geoBarCtr}>
                    <div className={theme.formGroup}>
                        <label className={theme.label}>Search for a Tool</label>
                        <i
                            className={`far fa-question-circle ${
                                theme.helpIcon
                            } ${helpActive ? theme.helpIconActive : ""}`}
                            onClick={() =>
                                this.props.actions.onClickInlineHelp(
                                    ids.HELP_GEO_BAR_SEARCH_TOOL,
                                )
                            }
                            role="button"
                            tabIndex={0}
                        />
                    </div>
                    {helpActive && (
                        <div className={theme.helpInlineCtr}>
                            <div className={theme.helpInline}>
                                <p>
                                    Enter the name of a geoprocessing tool to
                                    use.
                                </p>
                                <p>
                                    All geoprocessing tools in Catena can be
                                    found within the search box above. They are
                                    indexed by a number of keywords, so that you
                                    don&#39;t have to remember the exact name of
                                    the tool beforehand.
                                </p>
                                <p>
                                    For instance, try typing &quot;vector&quot;.
                                    Geoprocessing tools that use vector data
                                    will be shown below.
                                </p>
                                <p>
                                    Once the tool you wish to use is visible in
                                    the search results, select the tool with
                                    your mouse or use the keyboard to navigate
                                    to the result and type Enter.
                                </p>
                                <p>
                                    The following geoprocessing tools are
                                    currently available with Catena:
                                </p>
                                <ul>
                                    {this.props.geoBar.tools
                                        .sort((a, b) =>
                                            compare(a.fullLabel, b.fullLabel),
                                        )
                                        .map((tool) => (
                                            <li key={tool.id}>
                                                {tool.fullLabel}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className={theme.formGroup}>
                        <GeoSearch
                            geoBar={this.props.geoBar}
                            onSuggestionsFetchRequested={
                                this.props.actions
                                    .onGeoBarSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                                this.props.actions
                                    .onGeoBarSuggestionsClearRequested
                            }
                            onSelect={this.props.actions.onGeoBarSelectTool}
                            onChange={
                                this.props.actions.onGeoBarChangeSearchValue
                            }
                            theme={this.props.theme}
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    renderTool(theme) {
        if (this.props.geoBar.tool && !this.props.geoBar.search.active) {
            const curTool = getActiveTool(this.props)
            return (
                <div className={theme.geoBarCtr}>
                    {curTool.render(this.props)}
                </div>
            )
        }
        return null
    }

    render() {
        const theme = this.props.theme
        return (
            <div className={theme.geoBarPanel}>
                <h3 className={theme.geoBarTitleBar}>Geo Toolbox</h3>
                <div className={theme.geoBarPanelScroll}>
                    {this.renderSearch(theme)}
                    {this.renderTool(theme)}
                </div>
            </div>
        )
    }
}

export default GeoBarPanel
