import React, { useContext, useEffect } from "react"
import * as actions from "app/store/actions"

import { Provider, useSelector } from "react-redux"
import { ApolloProvider } from "@apollo/client"
import { Box, Slide } from "@material-ui/core"
import { ContextBar } from "@owsi/catena/er2_ui"
import { useAppStyles, usePopout } from "@owsi/catena-vellum"

import { useActions } from "app/hooks"
import { useSessionState } from "app/hooks/session"
import { useAnalyticsPageview } from "app/hooks/analytics"

import store from "app/store"
import { client } from "app/apollo"
import { Loading } from "app/components/Loading"
import { UserContext } from "./UserContext"
import { SnackContext } from "./SnackContext"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import keycloak from "app/keycloak/keycloak"
import { createBrowserHistory } from 'history';
import {useHistory} from 'react-router-dom';

const history = createBrowserHistory();
let app = document.getElementById('root');

const AppVersion: React.FC = () => {
    const versionNumber = useSelector<any, string>((it) => it.meta.version)

    return (
        <Box
            position="absolute"
            bottom={0}
            zIndex={500}
            py={2}
            width={58}
            textAlign="center"
        >
            {versionNumber}
        </Box>
    )
}

const AppContextBar: React.FC = () => {
    const theme = useAppStyles()
    const { items, visible, token } = useSelector((it: any) => ({
        items: it.contextBar.items,
        visible: it.contextBar.contextVisible,
        token: it.token,
    }))
    const onClickContextBarItem = useActions(actions.onClickContextBarItem)

    return (
        <Slide direction="right" in={visible}>
            <ContextBar
                items={items}
                token={token}
                onClickContextBarItem={onClickContextBarItem}
                theme={theme}
            />
        </Slide>
    )
}

const AppNavGuard: React.FC<{ fallback: React.ReactNode }> = ({
    children,
    fallback,
}) => {
    const session = useSessionState()

    return <>{session !== undefined ? children : fallback}</>
}

const ShallowGARender = () => {
    const enabled = process.env.NODE_ENV === "production"

    useAnalyticsPageview(enabled)

    return null
}

export const App: React.FC<{}> = ({ children }) => {
    const history = useHistory();
    const classes = useAppStyles()
    const { isPopout } = usePopout()

    useEffect(() => {
        if (history.location.pathname === "/") {
            history.push("/docs");
        }
    }), [history]

    if (app) {
        const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
        if (path) {
            history.replace(path);
        }
        return (
            <ReactKeycloakProvider authClient={keycloak}>
                <ApolloProvider client={client}>
                    <Provider store={store}>
                        <UserContext>
                            <SnackContext>
                                <div className={`${classes.root} app-root`}>
                                    {isPopout ? null : <AppVersion />}
                                    {isPopout ? null : <AppContextBar />}
                                    <AppNavGuard fallback={<Loading />}>
                                        <ShallowGARender />
                                        {children}
                                    </AppNavGuard>
                                </div>
                            </SnackContext>
                        </UserContext>
                    </Provider>
                </ApolloProvider>
            </ReactKeycloakProvider>
        )
    }
}