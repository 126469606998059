// @flow
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose } from "@fortawesome/free-regular-svg-icons"
import DEM from "../components/dem"
import FieldToWEPP from "../components/field_to_wepp"
import Lamps from "../components/lamps"
import LampsOutput from "../components/lamps_output"
import * as actionTypes from "../constants/action_types"
import * as ids from "../constants/ids"

export type ServiceType = {
    description: string,
    icon: string,
    id: string,
    name: string,
    render: Function,
}

export type ServicesType = {
    items: ServiceType[],
    result?: {},
    running: boolean,
    // Service outputs that should go in the south panel
    serviceComponents: [
        {
            id: string,
            props: {},
        },
    ],
}

export type ActionType = {
    actionId: string,
    result?: {},
}

export function getServiceResults(props) {
    // For each service components, get the service item
    const results = []
    props.services.serviceComponents.forEach((compdata) => {
        const item = props.services.items.find(
            (serviceItem) => serviceItem.id === compdata.id,
        )
        const buttons = [
            {
                className: faWindowClose,
                event: () => props.actions.closeServiceResults(compdata.id),
            },
        ]
        results.push(item.renderResult({ ...props, ...compdata, buttons }))
    })
    return results
}

const initialState = {
    items: [
        {
            description: "Digitial Elevation Model (DEM) extraction",
            help:
                "Create an area of interest by either drawing the extent on the map or picking a boundary type.",
            icon: <FontAwesomeIcon icon="mountain" />,
            id: ids.DATA_DEM,
            label: "DEM",
            render: (props) => <DEM {...props} />,
            toolType: "Service",
        },
        {
            description: "Land use data extraction (LAMPS)",
            help: "Add LAMPS land use data to a polygon layer.",
            icon: <FontAwesomeIcon icon="tag" />,
            id: ids.DATA_LAMPS,
            label: "LAMPS",
            render: (props) => <Lamps {...props} />,
            renderResult: (props) => <LampsOutput key={"LAMPS"} {...props} />,
            toolType: "Service",
        },
        {
            description: "Generate watershed layers for a WEPP field",
            help:
                "Trace a rectangle on the map to extract the watershed layers.",
            icon: <FontAwesomeIcon icon="tint" />,
            id: ids.DATA_WATERSHED,
            label: "Field to WEPP",
            render: (props) => <FieldToWEPP {...props} />,
            toolType: "Service",
        },
    ],
    result: undefined,
    running: false,
    serviceComponents: [],
}

export const reducer = (
    state: ServicesType = initialState,
    action: ActionType,
) => {
    let ret = state
    if (action.type === actionTypes.LAMPS_START) {
        ret = {
            ...state,
            result: "",
            running: true,
        }
    } else if (action.type === actionTypes.LAMPS_END) {
        ret = {
            ...state,
            result: action.state,
            running: false,
        }
    } else if (action.type === actionTypes.SHOW_SERVICE_RESULTS) {
        // Close existing
        const serviceComponents = state.serviceComponents.filter(
            (s) => s.id !== action.id,
        )
        ret = {
            ...state,
            serviceComponents: [
                ...serviceComponents,
                { id: action.id, layer: action.layer },
            ],
        }
    } else if (action.type === actionTypes.CLOSE_SERVICE_RESULTS) {
        ret = {
            ...state,
            serviceComponents: state.serviceComponents.filter(
                (s) => s.id !== action.id,
            ),
        }
    }
    return ret
}

export default reducer
