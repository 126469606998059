// @flow
import React from "react"
import Image from "ol/layer/Image"
import ImageArcGISrest from "ol/source/ImageArcGISRest"
import { unByKey } from "ol/Observable"
import { getMapserverHost, getMapserverName } from "../utils"
import { getMapsourceLayerName } from "../reducers/mapsources"
import type { MapsourcesType } from "../reducers/mapsources"

type Props = {
    er2Map: Map,
    mapsources: MapsourcesType,
}

/**
 * Interfaces with olmap to add, delete, and update mapserver and argcgisrest layers
 *
 */
export function useMapsources({ er2Map, mapsources }: Props) {
    const [loadingTileCounts, setLoadingTileCounts] = React.useState({})
    const [mapLayers, setMapLayers] = React.useState([])
    // Key is mapsource name
    const [layers, setLayers] = React.useState({})

    const olmap = er2Map?.olmap

    function getLayerByName(layerLabel: string) {
        const flayers = olmap
            .getLayers()
            .getArray()
            .filter((l) => l.get("label") === layerLabel)
        if (flayers.length) return flayers[0]
        return null
    }

    function mapsourceIsLoading(mapsource, isLoading) {
        const tileCounts = { ...loadingTileCounts }
        if (tileCounts[mapsource.name] === undefined) {
            tileCounts[mapsource.name] = 0
        }
        tileCounts[mapsource.name] += isLoading ? 1 : -1
        if (tileCounts[mapsource.name] < 0) tileCounts[mapsource.name] = 0
        setLoadingTileCounts(tileCounts)
    }

    const addLayerListeners = React.useCallback((arcgisrestSource, layer) => {
        const source = layer.getSource()
        if (!source.get("imageloadstartkey")) {
            const imageloadstartkey = source.on("imageloadstart", () => {
                mapsourceIsLoading(arcgisrestSource, true)
            })
            const imageloadendkey = source.on("imageloadend", () => {
                mapsourceIsLoading(arcgisrestSource, false)
            })
            source.set("imageloadstartkey", imageloadstartkey)
            source.set("imageloadendkey", imageloadendkey)
        } else {
            // Shouldn't be an error?
            console.warn("tried to add duplicate listener")
        }
    }, [])

    function removeLayerListeners(layer) {
        const source = layer.getSource()
        unByKey(source.get("imageloadstartkey"))
        unByKey(source.get("imageloadendkey"))
        source.unset("imageloadstartkey")
        source.unset("imageloadendkey")
    }

    function addLayer(mapsource, name) {
        const url = `${mapsource.url}${mapsource.service_name}/MapServer/`
        const source = new ImageArcGISrest({
            crossOrigin: "Anonymous",
            ratio: 1,
            url,
        })
        const layer = new Image({
            source,
            label: name,
            mapsource_type: "arcgisrest",
            zIndex: 0,
        })
        addLayerListeners(mapsource, layer)
        olmap.addLayer(layer)
        setMapLayers([...mapLayers, layer])
        return layer
    }

    function refreshLayer(layer, mapsource, params) {
        layer.getSource().updateParams({
            layers: `show:${mapsource.layers
                .filter((arcLayer) => arcLayer.visible)
                .map((arcLayer) => arcLayer.id)
                .join(",")}`,
        })
        layer.setVisible(mapsource.visible)
        if (params) {
            layer.setOpacity(params.opacity / 100.0)
        }
    }

    function updateLayer(mapsource, params) {
        const name = getMapsourceLayerName(mapsource)
        let layer = getLayerByName(name)
        if (!layer) {
            layer = addLayer(mapsource, name)
        }
        refreshLayer(layer, mapsource, params)
        return layer
    }

    // Update olmap with new layers
    React.useEffect(() => {
        const sources = mapsources?.mapsources || mapsources || undefined
        if (olmap && sources) {
            sources.forEach((m) => {
                // Update public services
                if (m.type === "arcgisrest") {
                    updateLayer(m, {
                        opacity: m.opacity,
                    })
                } else if (m.type.includes("mapserver")) {
                    // Update mapserver layer selection
                    er2Map.setUserMapfile(m.mapfile, {
                        visibleLayerNames: m.layers
                            .slice()
                            .reverse()
                            .filter((l) => l.visible)
                            .map((l) => getMapserverName(l)),
                    })
                }
            })
            // Check for layers that do not have a mapsource
            // Check for deleted services
            const arcgislayers = olmap
                .getLayers()
                .getArray()
                .filter((l) => l.get("mapsource_type") === "arcgisrest")
            arcgislayers.forEach((l) => {
                const f = sources.find((m) => m.name === l.get("label"))
                if (!f) {
                    olmap.removeLayer(l)
                }
            })
        }
    }, [mapsources, olmap])

    return { loadingTileCounts }
}
