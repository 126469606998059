// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import {
    getPolygonVectorLayers,
    getUniqueLayerName,
    getVectorLayers,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import Theme from "@owsi/catena/er2_styles"
import * as ids from "../constants/ids"
import * as actionTypes from "../constants/action_types"
import { getActiveTool } from "../reducers/geo_bar"
import { validationError } from "../actions/form"
import RenderNotifications from "./tool_notifications"

type Props = {
    actions: {
        onChangeInput: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitDissolve: Function,
        onGeoBarSelectLayer: Function,
        onUpdateUserLayers: Function,
    },
    mapsources: {},
    theme: Theme,
}

function isValid(tool) {
    return tool.targetLayer.length > 0 && tool.key.length > 0
}

function isValidKey(key: number, dispatch, getState) {
    if (!key.length) {
        const error = {
            id: ids.GEO_BAR_DISSOLVE,
            type: actionTypes.VALIDATION_ERROR_INVALID_FIELD,
            message: "Please provide a valid key",
        }
        dispatch(validationError(error))
        return false
    }
    return true
}

function GeoBarDissolve(props: Props) {
    const tool = getActiveTool(props)

    const [availableAttributes, setAvailableAttributes] = React.useState([])
    const [outputName, setOutputName] = React.useState("")

    React.useEffect(() => {
        if (tool.targetLayer) {
            const layer = getPolygonVectorLayers(props.mapsources).find(
                (l) => l.name === tool.targetLayer,
            )
            const layerAttrs = Object.keys(layer.schema.properties)
            setAvailableAttributes(layerAttrs)
        } else {
            setAvailableAttributes([])
        }
    }, [tool.targetLayer, props.mapsources])

    const dissolve = () => {
        props.actions.onGeoBarSubmitDissolve(
            tool.targetLayer,
            tool.key,
            outputName,
        )
    }

    const setLayer = (e) => {
        const layers = getVectorLayers(props.mapsources)
        const layer = layers.find((l) => l.name === e.target.value)
        props.actions.onGeoBarSelectLayer(true, layer.name, tool, {
            kind: "targetLayer",
        })

        if (!outputName) {
            // Create an output name based on the input
            const [basename, ext] = splitExt(layer.name)
            const addExt = ext || ".json"
            const newOutputName =
                getUniqueLayerName(
                    props.mapsources,
                    `${basename} (dissolved)`,
                ) + addExt
            setOutputName(newOutputName)
        }
    }

    const onFocus = (event) => event.target.select()

    function renderTool() {
        const layers = getPolygonVectorLayers(props.mapsources)
        return (
            <React.Fragment>
                <div className={props.theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="layer">Layer</InputLabel>
                        <Select
                            onChange={setLayer}
                            inputProps={{
                                name: "layer",
                            }}
                            value={tool.targetLayer}
                        >
                            {layers.map((l) => (
                                <MenuItem key={l.name} value={l.name}>
                                    {l.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={props.theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="attribute">
                            Dissolve attribute
                        </InputLabel>
                        <Select
                            onChange={(evt) =>
                                props.actions.onChangeInput(
                                    evt.target.value,
                                    ids.GEO_BAR_DISSOLVE_KEY,
                                )
                            }
                            inputProps={{
                                name: "attribute",
                            }}
                            value={tool.key}
                        >
                            {availableAttributes.map((a) => (
                                <MenuItem key={a} value={a}>
                                    {a}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Output layer name"
                        value={outputName}
                        onChange={(evt) => setOutputName(evt.target.value)}
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <Button
                        color={"primary"}
                        disabled={!isValid(tool) || tool.isFetching}
                        onClick={dissolve}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Dissolve</span>}
                        {tool.isFetching && (
                            <span>
                                Dissolving&nbsp;
                                <i className="fas fa-spinner fa-spin" />
                            </span>
                        )}
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={props.theme.geoBarCtr}>
            <div className={props.theme.toolCtr}>{renderTool()}</div>
            <RenderNotifications {...props} />
        </div>
    )
}

export default GeoBarDissolve
