// @flow
import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"
import { parseLocation } from "@owsi/catena/er2_map_userlayers/js/utils"
import ContextBarItem from "./context_bar_item"
import clsx from "clsx"
import { IconButton } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
    className: String,
    items: Array<{
        active: boolean,
        id: string,
        isVisible?: Function,
        name: string,
        position?: "bottom" | "spacer",
    }>,
    location: { pathname: string },
    onClickContextBarItem: Function,
    showActiveIcon: boolean,
    state: {},
    theme: {
        contextBar: string,
        contextBarBottom: string,
        contextBarTop: string,
        iconCtr: string,
        spacspacer: string,
    },
    token?: { value: string },
}

// Displays the menu bar on the left, indicating the current step (or context) of the application workflow
function ContextBar(props: Props) {
    const parsedLoc = parseLocation(props.location)
    const activeIcon = props.items.find(
        (item) => item.isActive && item.isActive(parsedLoc, item),
    )
    // Returns true if the provided string is a Font Awesome or Mapskin icon
    const isIconClass = (str) =>
        str &&
        typeof str === "string" &&
        ["fa", "ms"].includes(str.substr(0, 2))
    const style = {}

    return (
        <div
            className={clsx(props.theme.contextBar, props.className)}
            style={style}
            ref={props.innerRef}
        >
            <div className={props.theme.contextBarTop}>
                {props.showActiveIcon && (
                    <div className={props.theme.spacer}>
                        {activeIcon && (
                            <div className={props.theme.iconCtr}>
                                {activeIcon.content ? (
                                    activeIcon.content
                                ) : isIconClass(activeIcon.icon.default) ? (
                                    <i
                                        className={activeIcon.icon.default}
                                        aria-hidden="true"
                                        title={activeIcon.name}
                                    />
                                ) : (
                                    activeIcon.icon.default
                                )}
                            </div>
                        )}
                    </div>
                )}
                {props.items
                    .filter((item) => item.position !== "bottom")
                    .map((item, idx) => (
                        <Fragment key={item.id || `spacer=${idx}`}>
                            {(!item.isVisible ||
                                item.isVisible(
                                    parseLocation(props.location),
                                    item,
                                    props.state,
                                )) && (
                                <ContextBarItem
                                    key={item.id}
                                    enabled={
                                        item.isEnabled === undefined ||
                                        item.isEnabled
                                    }
                                    idx={idx}
                                    item={item}
                                    // Inject the theme to child components if provided
                                    theme={props.theme}
                                    token={props.token}
                                    onClick={props.onClickContextBarItem}
                                />
                            )}
                        </Fragment>
                    ))}
            </div>
            <div className={props.theme.contextBarBottom}>
                {props.items
                    .filter((item) => item.position === "bottom")
                    .map((item, idx) => (
                        <Fragment key={item.id + idx.toString()}>
                            {(!item.isVisible ||
                                item.isVisible(
                                    parseLocation(props.location),
                                    item,
                                    props,
                                )) && (
                                <ContextBarItem
                                    key={item.id}
                                    enabled={
                                        item.isEnabled === undefined ||
                                        item.isEnabled
                                    }
                                    idx={idx}
                                    item={item}
                                    location={props.location}
                                    theme={props.theme}
                                    token={props.token}
                                    onClick={props.onClickContextBarItem}
                                />
                            )}
                        </Fragment>
                    ))}
            </div>
        </div>
    )
}

ContextBar.defaultProps = {
    render: undefined,
    showActiveIcon: false,
    token: undefined,
}

const RoutedContextBar = withRouter(ContextBar)

export default React.forwardRef((props, ref) => (
    <RoutedContextBar innerRef={ref} {...props} />
))
