import mapToolbarStyles from "./map_toolbar"

const rawStyles = {
    geoBarPanel: {
        backgroundColor: (props) => props.white,
        display: "flex",
        flexBasis: "0",
        flexDirection: "column",
        flexGrow: "1",
        flexShrink: "0",
        overflowY: "hidden",
    },
    geoBarPanelScroll: {
        height: "calc(100% - 44px)",
        overflowY: "auto",
    },
    geoBarTitleBar: {
        ...mapToolbarStyles.mapToolbarTitleBar,
        flex: "initial",
        overflow: "initial",
    },
}

export default rawStyles
