import {
    mrrpLayerMetadata,
    MrrpMapLayerMetadataType,
} from "app/components/MapUtils"
import { getUserSchemeName } from "app/services/userTools"

export type InMemoryLayerType = {
    bbox_3857: number[]
    bbox_4326: number[]
    crs: {
        init: string
    }
    current_view_name: string
    date_modified: number
    expanded: boolean
    filepath: string
    header: string[]
    labelsVisible: boolean
    layer_type: string
    mapsource: string
    metapath: string
    name: string
    opacity: number
    query: {
        symbol: any
        expression: any
        visible: boolean
    }
    schema: any
    userMetadata: {
        "Source Layer": string
    }
    view_list: any
    visible: boolean
}

export function getLayerMetadata(inMemoryLayer: InMemoryLayerType) {
    const match = mrrpLayerMetadata.find(
        (meta) =>
            meta.shapeFileName.includes(inMemoryLayer.name) ||
            meta.alternateShapeName === inMemoryLayer.name ||
            (inMemoryLayer.userMetadata &&
                meta.shapeFileName ===
                    inMemoryLayer.userMetadata["Source Layer"])
    ) as MrrpMapLayerMetadataType

    return match
}

export function hydrateFeatureLayers(
    layers: { features: any[]; layer_name: string }[]
) {
    return layers.map((layer) => {
        const match = mrrpLayerMetadata.find(
            (meta) =>
                meta.shapeFileName.includes(layer.layer_name) ||
                meta.alternateShapeName === layer.layer_name
        ) as MrrpMapLayerMetadataType
        return { ...layer, ...match }
    })
}

export function getInMemoryLayers(userSchemeArr: any[]) {
    const userScheme = userSchemeArr.find(
        (scheme) => scheme.name === getUserSchemeName()
    )
    if (!userScheme) {
        console.warn(
            `Scheme ${getUserSchemeName()} not found in: ${JSON.stringify(
                userSchemeArr
            )}`
        )
        return []
    }
    return userScheme.layers as InMemoryLayerType[]
}
