// @flow
import * as types from "../constants/action_types"

export const onResizeAnalysisPanel = (offset) => ({
    type: types.RESIZE_ANALYSIS_PANEL,
    offset,
})

export const onResizeContextPanel = (offset) => ({
    type: types.RESIZE_CONTEXT_PANEL,
    offset,
})
