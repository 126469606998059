// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import {
    compare,
    getLayers,
    getRasterLayers,
} from "@owsi/catena/er2_map_userlayers/js/utils"

import * as ids from "../constants/ids"
import { getActiveTool } from "../reducers/geo_bar"

type Props = {
    actions: {
        onChangeInput: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitCalculate: Function,
        onGeoBarSelectLayer: Function,
    },
    mapsources: object,
    theme: object,
}

function GeoBarCalculator(props: Props) {
    const [outputRaster, setOutputRaster] = React.useState("")

    function isValid(tool) {
        return tool.expression.length > 0 && outputRaster
    }

    const calculate = () => {
        const tool = getActiveTool(props)
        const layers = getLayers(props.mapsources)
            .filter(
                (lyr) =>
                    lyr.layer_type && lyr.layer_type.toLowerCase() === "raster",
            )
            .sort((a, b) => compare(a.name, b.name))
            .map((lyr, idx) => ({
                ...lyr,
                variable: String.fromCharCode(65 + idx),
            }))
        props.actions.onGeoBarSubmitCalculate(
            layers,
            tool.expression,
            outputRaster,
        )
    }

    const onOutputLayerKeyDown = (ev) => {
        if (ev.key === "Enter") {
            calculate()
            ev.preventDefault()
        }
    }

    function renderTool() {
        const tool = getActiveTool(props)
        const layers = getRasterLayers(props.mapsources)
        return (
            <React.Fragment>
                <div className={props.theme.toolRow}>
                    <span>
                        Variables
                        <div>
                            {layers.map((layer, idx) => (
                                <div
                                    className={props.theme.layerGroup}
                                    key={layer.filepath}
                                >
                                    <span className={props.theme.label}>
                                        {String.fromCharCode(idx + 65)}:{" "}
                                        {layer.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </span>
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        id={ids.GEO_BAR_CALCULATOR_EXPRESSION}
                        label="Expression"
                        helperText="Use any variables, algebraic operators (+-/*) or scientific functions"
                        value={tool.expression}
                        onChange={(evt) =>
                            props.actions.onChangeInput(
                                evt.target.value,
                                ids.GEO_BAR_CALCULATOR_EXPRESSION,
                            )
                        }
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Output raster"
                        value={outputRaster}
                        onChange={(evt) => setOutputRaster(evt.target.value)}
                        onKeyDown={onOutputLayerKeyDown}
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <Button
                        color={"primary"}
                        disabled={!isValid(tool)}
                        onClick={calculate}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Calculate</span>}
                        {tool.isFetching && (
                            <span>
                                Calculating&nbsp;
                                <i className="fas fa-spinner fa-spin" />
                            </span>
                        )}
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={props.theme.geoBarCtr} style={{ overflow: "visible" }}>
            <div className={props.theme.toolCtr}>{renderTool()}</div>
        </div>
    )
}

export default GeoBarCalculator
